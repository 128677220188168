import React, { useContext, useState } from 'react';
import MetaTags from 'react-meta-tags';
import { useQuery } from '@apollo/client';
import { useParams, useHistory } from 'react-router-dom';
import { t } from 'i18next';
import {
  Card,
  CardHeader,
  Col,
  Container,
  Row,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Dropdown,
  CardBody,
} from 'reactstrap';

import { GET_DELIVERY_REQUEST } from 'src/helpers/graphql';
import { DeliveryRequestDirection, DeliveryRequestStatus } from 'src/types/types';
import { canBeApplied, getActionsForDeliveryRequest } from 'src/helpers/deliveryRequests';
import { formatToLocaleDate, getDisplayName } from 'src/helpers/ui';
import Progress from './Progress';
import Address from 'src/components/Address';
import Breadcrumbs from 'src/components/Breadcrumbs';
import { AuthContext } from 'src/components/AuthContextSetter';
import Timeline from 'src/components/Timeline';
import ResponseHandler from 'src/components/ResponseHandler';
import DeleteTaskDialog from './DeleteTaskDialog';
import EditDeliveryRequestStatus from '../EditDeliveryRequestStatus/EditDeliveryRequestStatus';
import DateRangePicker from './DateRangePicker';

const TaskDetails: React.FC<{}> = () => {
  const authContext = useContext(AuthContext);
  const [showTaskDeleteDialog, setShowTaskDeleteDialog] = useState<boolean>(false);
  const [showSetStatusDialog, setShowSetStatusDialog] = useState(false);
  const [showDeliveryCalendar, setShowDeliveryCalendar] = useState(false);
  const [statusDialogNewState, setStatusDialogNewState] = useState<
    DeliveryRequestStatus | 'CHANGE_STORAGE' | undefined
  >(undefined);
  const history = useHistory();

  const { taskId } = useParams<{ taskId: string }>();

  const {
    loading: deliveryRequestLoading,
    error: deliveryRequestError,
    data: deliveryRequestData,
  } = useQuery(GET_DELIVERY_REQUEST, { variables: { id: taskId } });
  const changeDeliveryRequestStatus = (newStatus: DeliveryRequestStatus | 'CHANGE_STORAGE') => {
    setStatusDialogNewState(newStatus);
    setShowSetStatusDialog(true);
  };

  const generateDropdownActions = (task: DeliveryRequestDetails) => {
    const actionsForCurrentStatus = getActionsForDeliveryRequest(task);

    const beforeSeparatorActions = [];
    const afterSeparatorActions = [];

    if (canBeApplied(DeliveryRequestStatus.CREATED, actionsForCurrentStatus, authContext.currentContext!)) {
      beforeSeparatorActions.push(
        <DropdownItem onClick={() => changeDeliveryRequestStatus(DeliveryRequestStatus.CREATED)} key="CREATED">
          {t(`DeliveryRequest.action.${DeliveryRequestStatus.CREATED}`)}
        </DropdownItem>,
      );
    }

    if (canBeApplied(DeliveryRequestStatus.SCHEDULED, actionsForCurrentStatus, authContext.currentContext!)) {
      beforeSeparatorActions.push(
        <DropdownItem onClick={() => changeDeliveryRequestStatus(DeliveryRequestStatus.SCHEDULED)} key="SCHEDULED">
          {t(`DeliveryRequest.action.${DeliveryRequestStatus.SCHEDULED}`)}
        </DropdownItem>,
      );
    }

    if (canBeApplied(DeliveryRequestStatus.AT_TRANSPORTER, actionsForCurrentStatus, authContext.currentContext!)) {
      beforeSeparatorActions.push(
        <DropdownItem
          onClick={() => changeDeliveryRequestStatus(DeliveryRequestStatus.AT_TRANSPORTER)}
          key="AT_TRANSPORTER"
        >
          {t(`DeliveryRequest.action.${DeliveryRequestStatus.AT_TRANSPORTER}.${task.direction}`)}
        </DropdownItem>,
      );
    }

    if (canBeApplied(DeliveryRequestStatus.DELIVERED, actionsForCurrentStatus, authContext.currentContext!)) {
      beforeSeparatorActions.push(
        <DropdownItem onClick={() => changeDeliveryRequestStatus(DeliveryRequestStatus.DELIVERED)} key="DELIVERED">
          {t(`DeliveryRequest.action.${DeliveryRequestStatus.DELIVERED}`)}
        </DropdownItem>,
      );
    }

    // ---------------

    if (canBeApplied(DeliveryRequestStatus.MISSED, actionsForCurrentStatus, authContext.currentContext!)) {
      afterSeparatorActions.push(
        <DropdownItem onClick={() => changeDeliveryRequestStatus(DeliveryRequestStatus.MISSED)} key="MISSED">
          {t(`DeliveryRequest.action.${DeliveryRequestStatus.MISSED}`)}
        </DropdownItem>,
      );
    }

    if (canBeApplied(DeliveryRequestStatus.CANCELLED, actionsForCurrentStatus, authContext.currentContext!)) {
      afterSeparatorActions.push(
        <DropdownItem onClick={() => changeDeliveryRequestStatus(DeliveryRequestStatus.CANCELLED)} key="CANCELLED">
          {t(`DeliveryRequest.action.${DeliveryRequestStatus.CANCELLED}`)}
        </DropdownItem>,
      );
    }

    if (authContext.auth.isAdmin()) {
      afterSeparatorActions.push(
        <DropdownItem onClick={() => setShowTaskDeleteDialog(true)} key="DELETE" className="text-danger">
          Delete task
        </DropdownItem>,
      );
    }

    if (beforeSeparatorActions.length === 0 && afterSeparatorActions.length === 0) {
      return null;
    }

    return (
      <Dropdown isOpen={statusDropDown} toggle={() => setStatusDropDown(!statusDropDown)}>
        <DropdownToggle tag="button" className="btn btn-soft-light">
          {t(`DeliveryRequest.status.${task.status}`)} <i className="mdi mdi-chevron-down" />
        </DropdownToggle>
        <DropdownMenu>
          {beforeSeparatorActions}

          {beforeSeparatorActions.length > 0 && afterSeparatorActions.length > 0 ? (
            <div className="dropdown-divider"></div>
          ) : null}

          {afterSeparatorActions}
        </DropdownMenu>
      </Dropdown>
    );
  };

  const renderButtonToGoogleMaps = (address?: FullAddress) => {
    if (!address?.googlePlaceId) {
      return null;
    }

    return (
      <a
        href={`https://www.google.com/maps/place/?q=place_id:${address.googlePlaceId}`}
        rel="noreferrer"
        className="btn btn-soft-light btn-sm me-1"
        target="_blank"
      >
        <i className="mdi mdi-map-marker label-icon"></i>
      </a>
    );
  };

  const getFromInfo = (task: DeliveryRequestDetails) => {
    if (task.direction === DeliveryRequestDirection.USER_TO_STORAGE) {
      return (
        <h5>
          <a href={`/users/${task.userId}`}>{getDisplayName(task.user)}</a>
        </h5>
      );
    }

    // <a href={`/storages/${task.toVirtualStorageId}`}>Storage {task.toVirtualStorageId}</a> <br />
    return <h5>Storage</h5>;
  };

  const getToInfo = (task: DeliveryRequestDetails) => {
    if (task.direction === DeliveryRequestDirection.STORAGE_TO_USER) {
      return (
        <h5>
          <a href={`/users/${task.userId}`}>{getDisplayName(task.user)}</a>
        </h5>
      );
    }

    // <a href={`/storages/${task.toVirtualStorageId}`}>Storage {task.toVirtualStorageId}</a> <br />
    return <h5>Storage</h5>;
  };

  const [statusDropDown, setStatusDropDown] = useState(false);
  const renderPageInternals = (task: DeliveryRequestDetails) => {
    if (!task) {
      if (!deliveryRequestLoading) {
        history.push('/tasks');
      }
      return null;
    }
    const isScheduledDeliveryEditable = task.status === 'CREATED' && authContext.auth.isAdmin();

    return (
      <>
        <Breadcrumbs
          items={[
            { label: 'Tasks', path: authContext.auth.isAdmin() ? '/tasks' : '/my-tasks' },
            { label: 'Task', path: `/tasks/${task.id}` },
          ]}
        />

        <Row>
          <Col>
            <div className="d-flex flex-row gap-2">
              <div>
                <h1>{t(`DeliveryRequest.direction.${task.direction}`)}</h1>
              </div>

              <div>{generateDropdownActions(task)}</div>
            </div>
          </Col>
        </Row>
        <Progress task={task} />
        <Row>
          <Col lg="3">
            <Card>
              <CardHeader>
                <h4 className="card-title mb-0 flex-grow-1">
                  <i className="mdi mdi-qrcode me-2"></i>
                  {t('DeliveryRequest.details.details')}
                </h4>
              </CardHeader>
              <CardBody>
                <p>
                  <span className="text-muted cursor-pointer">Box ID</span>
                  <br />
                  {task.package?.uniqueId}
                </p>
                <p></p>
                <p>
                  <span className="text-muted ">
                    {t(`DeliveryRequest.scheduleType.${task.scheduleType}`)} {t('DeliveryRequest.details.delivery')}
                  </span>
                  <br />
                  {isScheduledDeliveryEditable ? (
                    <div className="d-flex align-items-center gap-2">
                      {!showDeliveryCalendar &&
                        formatToLocaleDate(task.pickupRequestFrom, '5.23.2021 - 11:06 AM', 'America/New_York')}
                      <DateRangePicker
                        id={task.id}
                        setShowDeliveryCalendar={setShowDeliveryCalendar}
                        showDeliveryCalendar={showDeliveryCalendar}
                        pickupRequestFrom={task?.pickupRequestFrom}
                        pickupRequestTo={task?.pickupRequestTo}
                      />
                    </div>
                  ) : (
                    formatToLocaleDate(task.pickupRequestFrom, '5.23.2021 - 11:06 AM', 'America/New_York')
                  )}
                </p>
                <p>
                  <span className="text-muted">{t('DeliveryRequest.details.task_updated')}</span>
                  <br />
                  {formatToLocaleDate(task.updatedAt, '5.23.2021 - 11:06 AM', 'America/New_York')}
                </p>
              </CardBody>
            </Card>
          </Col>
          <Col lg="3">
            <Card>
              <CardHeader>
                <h4 className="card-title mb-0 flex-grow-1">
                  <i className="mdi mdi-clipboard-account me-2"></i>
                  {t('DeliveryRequest.details.assigned_to')}
                </h4>
              </CardHeader>
              <CardBody>
                {task.transporterUser ? (
                  <>
                    <h5>
                      <a href={`/users/${task.transporterUser.id}`}>{getDisplayName(task.transporterUser)}</a>
                    </h5>
                    {task.pickupAt ||
                    task.status === DeliveryRequestStatus.MISSED ? null : authContext.auth.isAdmin() ? (
                      <button
                        onClick={() => changeDeliveryRequestStatus(DeliveryRequestStatus.SCHEDULED)}
                        className="btn btn-soft-light"
                      >
                        {t('DeliveryRequest.details.change')}
                      </button>
                    ) : null}
                  </>
                ) : (
                  <>
                    {task.status === DeliveryRequestStatus.CANCELLED ? (
                      <p>{t('DeliveryRequest.details.task_cancelled')}</p>
                    ) : (
                      <>
                        {(task.status === DeliveryRequestStatus.SCHEDULED ||
                          task.status === DeliveryRequestStatus.CREATED) &&
                        authContext.auth.isAdmin() ? (
                          <p>
                            <button
                              onClick={() => changeDeliveryRequestStatus(DeliveryRequestStatus.SCHEDULED)}
                              className="btn btn-soft-light"
                            >
                              {task.status === DeliveryRequestStatus.CREATED
                                ? t('DeliveryRequest.details.please_assign')
                                : t('DeliveryRequest.details.change')}
                            </button>
                          </p>
                        ) : null}
                      </>
                    )}
                  </>
                )}
              </CardBody>
            </Card>
          </Col>
          <Col lg="3">
            <Card>
              <CardHeader className="align-items-center d-flex">
                <h4 className="card-title mb-0 flex-grow-1">
                  <i className="mdi mdi-hand me-2"></i>
                  {t('DeliveryRequest.details.pick_up_at')}
                </h4>
                {renderButtonToGoogleMaps(task.fromAddress)}
              </CardHeader>
              <CardBody>
                {getFromInfo(task)}

                <Address address={task.fromAddress} />

                {task.pickupAt ? (
                  <>
                    <p>@ {formatToLocaleDate(task.pickupAt, '5.23.2021 - 11:06 AM', 'America/New_York')}</p>
                  </>
                ) : null}

                {task.status === DeliveryRequestStatus.CANCELLED ? (
                  <p>{t('DeliveryRequest.details.pick_up_cancelled')}</p>
                ) : task.status === DeliveryRequestStatus.MISSED ? (
                  <p>{t('DeliveryRequest.details.pick_up_missed')}</p>
                ) : null}
              </CardBody>
            </Card>
          </Col>
          <Col lg="3">
            <Card>
              <CardHeader className="align-items-center d-flex">
                <h4 className="card-title mb-0 flex-grow-1">
                  <i className="mdi mdi-truck-delivery me-2"></i> {t('DeliveryRequest.details.deliver_to')}
                </h4>
                {renderButtonToGoogleMaps(task.toAddress)}
              </CardHeader>
              <CardBody>
                {getToInfo(task)}
                <Address address={task.toAddress} />
                {task.dropOffAt ? (
                  <p>@ {formatToLocaleDate(task.dropOffAt, '5.23.2021 - 11:06 AM', 'America/New_York')}</p>
                ) : null}

                {task.status === DeliveryRequestStatus.CREATED ? (
                  !task.toAddress && task.direction !== DeliveryRequestDirection.STORAGE_TO_USER ? (
                    <>{t('DeliveryRequest.details.deliver_cant_edit')}</>
                  ) : null
                ) : task.status === DeliveryRequestStatus.AT_TRANSPORTER ||
                  task.status === DeliveryRequestStatus.SCHEDULED ? (
                  <>
                    {task.direction !== DeliveryRequestDirection.STORAGE_TO_USER ? (
                      <button
                        className="btn btn-soft-light"
                        onClick={() => changeDeliveryRequestStatus('CHANGE_STORAGE')}
                      >
                        {t('DeliveryRequest.details.change')}
                      </button>
                    ) : null}
                  </>
                ) : task.status === DeliveryRequestStatus.CANCELLED ? (
                  <p>{t('DeliveryRequest.details.deliver_to_cancelled')}</p>
                ) : task.status === DeliveryRequestStatus.MISSED ? (
                  <p>{t('DeliveryRequest.details.deliver_to_missed')}</p>
                ) : null}
              </CardBody>
            </Card>
          </Col>
        </Row>
        {/* task.packageInfo ? (
          <PackageInfoList title="1 package" packageInfos={[task.packageInfo]} package={task.package} />
        ) : null */}
        <Timeline title="Status history" />
        <EditDeliveryRequestStatus
          show={showSetStatusDialog}
          setShow={setShowSetStatusDialog}
          newStatus={statusDialogNewState!}
          tasks={[task]}
        />

        <DeleteTaskDialog task={task} show={showTaskDeleteDialog} setShow={setShowTaskDeleteDialog} />
      </>
    );
  };

  return (
    <ResponseHandler loading={deliveryRequestLoading} error={deliveryRequestError}>
      <div className="page-content">
        <MetaTags>
          <title>Task details | Add + Space Admin</title>
        </MetaTags>
        <Container fluid>
          {renderPageInternals(deliveryRequestData?.deliveryRequest as DeliveryRequestDetails)}
        </Container>
      </div>
    </ResponseHandler>
  );
};

export default TaskDetails;
