import React from 'react';
import { Label as BootstrapLabel } from 'reactstrap';

export interface LabelProps {
  label?: string;
  [key: string]: any;
}

const Label: React.FC<LabelProps> = ({ label, ...rest }) => {
  if (!label) {
    return null;
  }

  return (
    <BootstrapLabel className="form-label" {...rest}>
      {label}
    </BootstrapLabel>
  );
};

export default Label;
