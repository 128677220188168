import React, { useState, useEffect } from 'react';
import { useMutation, ApolloError } from '@apollo/client';
import { useForm, SubmitHandler } from 'react-hook-form';

import { ROLLBACK_DELIVERY_STATUS } from 'src/helpers/graphql';

import ModalForm, { setFieldErrors } from 'src/components/Forms/ModalForm';

type Inputs = {
  scheduleType: string;
  storage: string;
  transporter: string;
};

type StatusToCreateProps = {
  show: boolean;
  setShow: Function;
  handleSingleOrBulkSubmit: Function;
  tasks: (DeliveryRequestListItem | DeliveryRequestDetails)[];
};

const StatusToCreate: React.FC<StatusToCreateProps> = ({ show, setShow, tasks, handleSingleOrBulkSubmit }) => {
  const { handleSubmit, setError } = useForm<Inputs>();
  const [globalError, setGlobalError] = useState<string | ApolloError | undefined>('');

  const [rollbackDeliveryStatus, { loading, error }] = useMutation(ROLLBACK_DELIVERY_STATUS);

  const onSubmit: SubmitHandler<Inputs> = async () => {
    handleSingleOrBulkSubmit({
      data: tasks.map((task) => ({
        id: task.id,
        status: 'CREATED',
      })),
      submitFn: rollbackDeliveryStatus,
      setFieldErrors: (err: any) => setFieldErrors(err, setError, setGlobalError),
    });
  };

  useEffect(() => {
    setFieldErrors(error, setError, setGlobalError);
  }, [error]);

  return (
    <ModalForm
      title="Mark as Created"
      show={show}
      setShow={setShow}
      onSubmit={handleSubmit(onSubmit)}
      loading={loading}
      globalError={globalError}
    >
      <div className="d-flex">
        <p>Do you really want to mark this task as created ?</p>
      </div>
    </ModalForm>
  );
};
export default StatusToCreate;
