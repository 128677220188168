import React, { useState } from 'react';
import MetaTags from 'react-meta-tags';
import {
  Card,
  CardBody,
  CardHeader,
  CardText,
  CardTitle,
  Col,
  Collapse,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  UncontrolledDropdown,
} from 'reactstrap';

import { Link } from 'react-router-dom';

//Import Breadcrumb
import Breadcrumbs from 'src/ux/components/Breadcrumb';

import classnames from 'classnames';

const UiTabsAccordions = () => {
  const [activeTab, setactiveTab] = useState('1');
  const [activeTab1, setactiveTab1] = useState('5');
  const [verticalActiveTab, setverticalActiveTab] = useState('1');
  const [customActiveTab, setcustomActiveTab] = useState('1');
  const [headerTab, setHeaderTab] = useState('1');
  const [headerPillsTab, setHeaderPillsTab] = useState('1');
  const [col1, setcol1] = useState(true);
  const [col2, setcol2] = useState(false);
  const [col3, setcol3] = useState(false);

  const [col5, setcol5] = useState(true);
  const [col6, setcol6] = useState(true);
  const [col7, setcol7] = useState(true);

  const [col9, setcol9] = useState(true);
  const [col10, setcol10] = useState(false);
  const [col11, setcol11] = useState(false);

  const toggleHeader = (tab: any) => {
    if (headerTab !== tab) setHeaderTab(tab);
  };
  const toggleHeaderPills = (tab: any) => {
    if (headerPillsTab !== tab) setHeaderPillsTab(tab);
  };

  const t_col1 = () => {
    setcol1(!col1);
    setcol2(false);
    setcol3(false);
  };

  const t_col2 = () => {
    setcol2(!col2);
    setcol1(false);
    setcol3(false);
  };

  const t_col3 = () => {
    setcol3(!col3);
    setcol1(false);
    setcol2(false);
  };

  const t_col5 = () => {
    setcol5(!col5);
  };

  const t_col6 = () => {
    setcol6(!col6);
  };

  const t_col7 = () => {
    setcol7(!col7);
  };

  const t_col8 = () => {
    setcol6(!col6);
    setcol7(!col7);
  };

  const t_col9 = () => {
    setcol9(!col9);
    setcol10(false);
    setcol11(false);
  };

  const t_col10 = () => {
    setcol10(!col10);
    setcol9(false);
    setcol11(false);
  };

  const t_col11 = () => {
    setcol11(!col11);
    setcol10(false);
    setcol9(false);
  };

  const toggle = (tab: any) => {
    if (activeTab !== tab) {
      setactiveTab(tab);
    }
  };

  const toggle1 = (tab: any) => {
    if (activeTab1 !== tab) {
      setactiveTab1(tab);
    }
  };

  const toggleVertical = (tab: any) => {
    if (verticalActiveTab !== tab) {
      setverticalActiveTab(tab);
    }
  };
  const toggleCustom = (tab: any) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Tabs & Accordions | Add + Space Admin</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Pages" breadcrumbItem="Tabs & Accordions" />
          <Row>
            <Col xl={6}>
              <Card>
                <CardHeader>
                  <CardTitle className="h4">Default Tabs</CardTitle>
                  <p className="card-title-desc">
                    Use the tab JavaScript plugin—include it individually or through the compiled{' '}
                    <code className="highlighter-rouge">bootstrap.js</code> file—to extend our navigational tabs and
                    pills to create tabbable panes of local content, even via dropdown menus.
                  </p>
                </CardHeader>
                <CardBody>
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        style={{ cursor: 'pointer' }}
                        className={classnames({
                          active: activeTab === '1',
                        })}
                        onClick={() => {
                          toggle('1');
                        }}
                      >
                        Home
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: 'pointer' }}
                        className={classnames({
                          active: activeTab === '2',
                        })}
                        onClick={() => {
                          toggle('2');
                        }}
                      >
                        Profile
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: 'pointer' }}
                        className={classnames({
                          active: activeTab === '3',
                        })}
                        onClick={() => {
                          toggle('3');
                        }}
                      >
                        Messages
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: 'pointer' }}
                        className={classnames({
                          active: activeTab === '4',
                        })}
                        onClick={() => {
                          toggle('4');
                        }}
                      >
                        Settings
                      </NavLink>
                    </NavItem>
                  </Nav>

                  <TabContent activeTab={activeTab} className="p-3 text-muted">
                    <TabPane tabId="1">
                      <Row>
                        <Col sm="12">
                          <CardText className="mb-0">
                            Raw denim you probably haven't heard of them jean shorts Austin. Nesciunt tofu stumptown
                            aliqua, retro synth master cleanse. Mustache cliche tempor, williamsburg carles vegan
                            helvetica. Reprehenderit butcher retro keffiyeh dreamcatcher synth. Cosby sweater eu banh
                            mi, qui irure terry richardson ex squid. Aliquip placeat salvia cillum iphone. Seitan
                            aliquip quis cardigan american apparel, butcher voluptate nisi qui.
                          </CardText>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="2">
                      <Row>
                        <Col sm="12">
                          <CardText className="mb-0">
                            Food truck fixie locavore, accusamus mcsweeney's marfa nulla single-origin coffee squid.
                            Exercitation +1 labore velit, blog sartorial PBR leggings next level wes anderson artisan
                            four loko farm-to-table craft beer twee. Qui photo booth letterpress, commodo enim craft
                            beer mlkshk aliquip jean shorts ullamco ad vinyl cillum PBR. Homo nostrud organic, assumenda
                            labore aesthetic magna delectus mollit. Keytar helvetica VHS salvia yr, vero magna velit
                            sapiente labore stumptown. Vegan fanny pack odio cillum wes anderson 8-bit.
                          </CardText>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="3">
                      <Row>
                        <Col sm="12">
                          <CardText className="mb-0">
                            Etsy mixtape wayfarers, ethical wes anderson tofu before they sold out mcsweeney's organic
                            lomo retro fanny pack lo-fi farm-to-table readymade. Messenger bag gentrify pitchfork
                            tattooed craft beer, iphone skateboard locavore carles etsy salvia banksy hoodie helvetica.
                            DIY synth PBR banksy irony. Leggings gentrify squid 8-bit cred pitchfork. Williamsburg banh
                            mi whatever gluten-free, carles pitchfork biodiesel fixie etsy retro mlkshk vice blog.
                            Scenester cred you probably haven't heard of them, vinyl craft beer blog stumptown.
                            Pitchfork sustainable tofu synth chambray yr.
                          </CardText>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="4">
                      <Row>
                        <Col sm="12">
                          <CardText className="mb-0">
                            Trust fund seitan letterpress, keytar raw denim keffiyeh etsy art party before they sold out
                            master cleanse gluten-free squid scenester freegan cosby sweater. Fanny pack portland seitan
                            DIY, art party locavore wolf cliche high life echo park Austin. Cred vinyl keffiyeh DIY
                            salvia PBR, banh mi before they sold out farm-to-table VHS viral locavore cosby sweater.
                            Lomo wolf viral, mustache readymade thundercats keffiyeh craft beer marfa ethical. Wolf
                            salvia freegan, sartorial keffiyeh echo park vegan.
                          </CardText>
                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>

            <Col lg={6}>
              <Card>
                <CardHeader>
                  <CardTitle className="h4">Justify Tabs</CardTitle>
                  <p className="card-title-desc">
                    Use the tab JavaScript plugin—include it individually or through the compiled{' '}
                    <code className="highlighter-rouge">bootstrap.js</code> file—to extend our navigational tabs and
                    pills to create tabbable panes of local content, even via dropdown menus.
                  </p>
                </CardHeader>
                <CardBody>
                  <Nav pills className="navtab-bg nav-justified">
                    <NavItem>
                      <NavLink
                        style={{ cursor: 'pointer' }}
                        className={classnames({
                          active: activeTab1 === '5',
                        })}
                        onClick={() => {
                          toggle1('5');
                        }}
                      >
                        Home
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: 'pointer' }}
                        className={classnames({
                          active: activeTab1 === '6',
                        })}
                        onClick={() => {
                          toggle1('6');
                        }}
                      >
                        Profile
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: 'pointer' }}
                        className={classnames({
                          active: activeTab1 === '7',
                        })}
                        onClick={() => {
                          toggle1('7');
                        }}
                      >
                        Messages
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: 'pointer' }}
                        className={classnames({
                          active: activeTab1 === '8',
                        })}
                        onClick={() => {
                          toggle1('8');
                        }}
                      >
                        Settings
                      </NavLink>
                    </NavItem>
                  </Nav>

                  <TabContent activeTab={activeTab1} className="p-3 text-muted">
                    <TabPane tabId="5">
                      <Row>
                        <Col sm="12">
                          <CardText className="mb-0">
                            Raw denim you probably haven't heard of them jean shorts Austin. Nesciunt tofu stumptown
                            aliqua, retro synth master cleanse. Mustache cliche tempor, williamsburg carles vegan
                            helvetica. Reprehenderit butcher retro keffiyeh dreamcatcher synth. Cosby sweater eu banh
                            mi, qui irure terry richardson ex squid. Aliquip placeat salvia cillum iphone. Seitan
                            aliquip quis cardigan american apparel, butcher voluptate nisi qui.
                          </CardText>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="6">
                      <Row>
                        <Col sm="12">
                          <CardText className="mb-0">
                            Food truck fixie locavore, accusamus mcsweeney's marfa nulla single-origin coffee squid.
                            Exercitation +1 labore velit, blog sartorial PBR leggings next level wes anderson artisan
                            four loko farm-to-table craft beer twee. Qui photo booth letterpress, commodo enim craft
                            beer mlkshk aliquip jean shorts ullamco ad vinyl cillum PBR. Homo nostrud organic, assumenda
                            labore aesthetic magna delectus mollit. Keytar helvetica VHS salvia yr, vero magna velit
                            sapiente labore stumptown. Vegan fanny pack odio cillum wes anderson 8-bit.
                          </CardText>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="7">
                      <Row>
                        <Col sm="12">
                          <CardText className="mb-0">
                            Etsy mixtape wayfarers, ethical wes anderson tofu before they sold out mcsweeney's organic
                            lomo retro fanny pack lo-fi farm-to-table readymade. Messenger bag gentrify pitchfork
                            tattooed craft beer, iphone skateboard locavore carles etsy salvia banksy hoodie helvetica.
                            DIY synth PBR banksy irony. Leggings gentrify squid 8-bit cred pitchfork. Williamsburg banh
                            mi whatever gluten-free, carles pitchfork biodiesel fixie etsy retro mlkshk vice blog.
                            Scenester cred you probably haven't heard of them, vinyl craft beer blog stumptown.
                            Pitchfork sustainable tofu synth chambray yr.
                          </CardText>
                        </Col>
                      </Row>
                    </TabPane>

                    <TabPane tabId="8">
                      <Row>
                        <Col sm="12">
                          <CardText className="mb-0">
                            Trust fund seitan letterpress, keytar raw denim keffiyeh etsy art party before they sold out
                            master cleanse gluten-free squid scenester freegan cosby sweater. Fanny pack portland seitan
                            DIY, art party locavore wolf cliche high life echo park Austin. Cred vinyl keffiyeh DIY
                            salvia PBR, banh mi before they sold out farm-to-table VHS viral locavore cosby sweater.
                            Lomo wolf viral, mustache readymade thundercats keffiyeh craft beer marfa ethical. Wolf
                            salvia freegan, sartorial keffiyeh echo park vegan.
                          </CardText>
                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg={6}>
              <Card>
                <CardHeader>
                  <CardTitle className="h4">Vertical Nav Tabs</CardTitle>
                  <p className="card-title-desc">Example of Vertical nav tabs</p>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md="3">
                      <Nav pills className="flex-column">
                        <NavItem>
                          <NavLink
                            style={{ cursor: 'pointer' }}
                            className={classnames({
                              'mb-2': true,
                              active: verticalActiveTab === '1',
                            })}
                            onClick={() => {
                              toggleVertical('1');
                            }}
                          >
                            Home
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{ cursor: 'pointer' }}
                            className={classnames({
                              'mb-2': true,
                              active: verticalActiveTab === '2',
                            })}
                            onClick={() => {
                              toggleVertical('2');
                            }}
                          >
                            Profile
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{ cursor: 'pointer' }}
                            className={classnames({
                              'mb-2': true,
                              active: verticalActiveTab === '3',
                            })}
                            onClick={() => {
                              toggleVertical('3');
                            }}
                          >
                            Messages
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{ cursor: 'pointer' }}
                            className={classnames({
                              active: verticalActiveTab === '4',
                            })}
                            onClick={() => {
                              toggleVertical('4');
                            }}
                          >
                            Settings
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </Col>
                    <Col md="9">
                      <TabContent activeTab={verticalActiveTab} className="text-muted mt-4 mt-md-0">
                        <TabPane tabId="1">
                          <p>
                            Raw denim you probably haven't heard of them jean shorts Austin. Nesciunt tofu stumptown
                            aliqua, retro synth master cleanse. Mustache cliche tempor, williamsburg carles vegan
                            helvetica. Reprehenderit butcher retro keffiyeh dreamcatcher synth. Cosby sweater eu banh
                            mi, qui irure terry richardson ex squid. Aliquip placeat salvia cillum iphone. Seitan
                            aliquip quis cardigan.
                          </p>
                          <p>
                            Reprehenderit butcher retro keffiyeh dreamcatcher synth. Cosby sweater eu banh mi, qui irure
                            terry richardson ex squid.
                          </p>
                        </TabPane>
                        <TabPane tabId="2">
                          <p>
                            Food truck fixie locavore, accusamus mcsweeney's marfa nulla single-origin coffee squid.
                            Exercitation +1 labore velit, blog sartorial PBR leggings next level wes anderson artisan
                            four loko farm-to-table craft beer twee. Qui photo booth letterpress, commodo enim craft
                            beer mlkshk.
                          </p>
                          <p className="mb-0">
                            {' '}
                            Qui photo booth letterpress, commodo enim craft beer mlkshk aliquip jean shorts ullamco ad
                            vinyl cillum PBR. Homo nostrud organic, assumenda labore aesthetic magna 8-bit
                          </p>
                        </TabPane>
                        <TabPane tabId="3">
                          <p>
                            Etsy mixtape wayfarers, ethical wes anderson tofu before they sold out mcsweeney's organic
                            lomo retro fanny pack lo-fi farm-to-table readymade. Messenger bag gentrify pitchfork
                            tattooed craft beer, iphone skateboard locavore carles etsy salvia banksy hoodie helvetica.
                            DIY synth PBR banksy irony. Leggings gentrify squid 8-bit cred.
                          </p>
                          <p className="mb-0">
                            DIY synth PBR banksy irony. Leggings gentrify squid 8-bit cred pitchfork. Williamsburg banh
                            mi whatever gluten-free.
                          </p>
                        </TabPane>

                        <TabPane tabId="4">
                          <p>
                            Trust fund seitan letterpress, keytar raw denim keffiyeh etsy art party before they sold out
                            master cleanse gluten-free squid scenester freegan cosby sweater. Fanny pack portland seitan
                            DIY, art party locavore wolf cliche high life echo park Austin. Cred vinyl keffiyeh DIY
                            salvia PBR, banh mi before they sold out farm-to-table.
                          </p>
                          <p className="mb-0">
                            Fanny pack portland seitan DIY, art party locavore wolf cliche high life echo park Austin.
                            Cred vinyl keffiyeh DIY salvia PBR, banh mi before they sold out farm-to-table.
                          </p>
                        </TabPane>
                      </TabContent>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col lg={6}>
              <Card>
                <CardHeader>
                  <CardTitle className="h4">Custom Tabs</CardTitle>
                  <p className="card-title-desc">Example of custom tabs</p>
                </CardHeader>
                <CardBody>
                  <Nav tabs className="nav-tabs-custom nav-justified">
                    <NavItem>
                      <NavLink
                        style={{ cursor: 'pointer' }}
                        className={classnames({
                          active: customActiveTab === '1',
                        })}
                        onClick={() => {
                          toggleCustom('1');
                        }}
                      >
                        <span className="d-block d-sm-none">
                          <i className="fas fa-home"></i>
                        </span>
                        <span className="d-none d-sm-block">Home</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: 'pointer' }}
                        className={classnames({
                          active: customActiveTab === '2',
                        })}
                        onClick={() => {
                          toggleCustom('2');
                        }}
                      >
                        <span className="d-block d-sm-none">
                          <i className="far fa-user"></i>
                        </span>
                        <span className="d-none d-sm-block">Profile</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: 'pointer' }}
                        className={classnames({
                          active: customActiveTab === '3',
                        })}
                        onClick={() => {
                          toggleCustom('3');
                        }}
                      >
                        <span className="d-block d-sm-none">
                          <i className="far fa-envelope"></i>
                        </span>
                        <span className="d-none d-sm-block">Messages</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: 'pointer' }}
                        className={classnames({
                          active: customActiveTab === '4',
                        })}
                        onClick={() => {
                          toggleCustom('4');
                        }}
                      >
                        <span className="d-block d-sm-none">
                          <i className="fas fa-cog"></i>
                        </span>
                        <span className="d-none d-sm-block">Settings</span>
                      </NavLink>
                    </NavItem>
                  </Nav>

                  <TabContent activeTab={customActiveTab} className="p-3 text-muted">
                    <TabPane tabId="1">
                      <Row>
                        <Col sm="12">
                          <CardText className="mb-0">
                            Raw denim you probably haven't heard of them jean shorts Austin. Nesciunt tofu stumptown
                            aliqua, retro synth master cleanse. Mustache cliche tempor, williamsburg carles vegan
                            helvetica. Reprehenderit butcher retro keffiyeh dreamcatcher synth. Cosby sweater eu banh
                            mi, qui irure terry richardson ex squid. Aliquip placeat salvia cillum iphone. Seitan
                            aliquip quis cardigan american apparel, butcher voluptate nisi qui.
                          </CardText>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="2">
                      <Row>
                        <Col sm="12">
                          <CardText className="mb-0">
                            Food truck fixie locavore, accusamus mcsweeney's marfa nulla single-origin coffee squid.
                            Exercitation +1 labore velit, blog sartorial PBR leggings next level wes anderson artisan
                            four loko farm-to-table craft beer twee. Qui photo booth letterpress, commodo enim craft
                            beer mlkshk aliquip jean shorts ullamco ad vinyl cillum PBR. Homo nostrud organic, assumenda
                            labore aesthetic magna delectus mollit. Keytar helvetica VHS salvia yr, vero magna velit
                            sapiente labore stumptown. Vegan fanny pack odio cillum wes anderson 8-bit.
                          </CardText>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="3">
                      <Row>
                        <Col sm="12">
                          <CardText className="mb-0">
                            Etsy mixtape wayfarers, ethical wes anderson tofu before they sold out mcsweeney's organic
                            lomo retro fanny pack lo-fi farm-to-table readymade. Messenger bag gentrify pitchfork
                            tattooed craft beer, iphone skateboard locavore carles etsy salvia banksy hoodie helvetica.
                            DIY synth PBR banksy irony. Leggings gentrify squid 8-bit cred pitchfork. Williamsburg banh
                            mi whatever gluten-free, carles pitchfork biodiesel fixie etsy retro mlkshk vice blog.
                            Scenester cred you probably haven't heard of them, vinyl craft beer blog stumptown.
                            Pitchfork sustainable tofu synth chambray yr.
                          </CardText>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="4">
                      <Row>
                        <Col sm="12">
                          <CardText className="mb-0">
                            Trust fund seitan letterpress, keytar raw denim keffiyeh etsy art party before they sold out
                            master cleanse gluten-free squid scenester freegan cosby sweater. Fanny pack portland seitan
                            DIY, art party locavore wolf cliche high life echo park Austin. Cred vinyl keffiyeh DIY
                            salvia PBR, banh mi before they sold out farm-to-table VHS viral locavore cosby sweater.
                            Lomo wolf viral, mustache readymade thundercats keffiyeh craft beer marfa ethical. Wolf
                            salvia freegan, sartorial keffiyeh echo park vegan.
                          </CardText>
                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xl={6}>
              <Card>
                <CardHeader className="align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">Card Header Tabs</h4>
                  <div className="flex-shrink-0">
                    <Nav className="justify-content-end nav-tabs-custom rounded card-header-tabs">
                      <NavItem>
                        <NavLink
                          to="#"
                          className={classnames({
                            active: headerTab === '1',
                          })}
                          onClick={() => {
                            toggleHeader('1');
                          }}
                        >
                          <span className="d-block d-sm-none">
                            <i className="fas fa-home"></i>
                          </span>
                          <span className="d-none d-sm-block">Home</span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          to="#"
                          className={classnames({
                            active: headerTab === '2',
                          })}
                          onClick={() => {
                            toggleHeader('2');
                          }}
                        >
                          <span className="d-block d-sm-none">
                            <i className="far fa-user"></i>
                          </span>
                          <span className="d-none d-sm-block">Profile</span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          to="#"
                          className={classnames({
                            active: headerTab === '3',
                          })}
                          onClick={() => {
                            toggleHeader('3');
                          }}
                        >
                          <span className="d-block d-sm-none">
                            <i className="far fa-envelope"></i>
                          </span>
                          <span className="d-none d-sm-block">Message</span>
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </div>
                </CardHeader>

                <div className="card-body">
                  <TabContent className="text-muted" activeTab={headerTab}>
                    <TabPane tabId="1">
                      <p className="mb-0">
                        Raw denim you probably haven't heard of them jean shorts Austin. Nesciunt tofu stumptown aliqua,
                        retro synth master cleanse. Mustache cliche tempor, williamsburg carles vegan helvetica.
                        Reprehenderit butcher retro keffiyeh dreamcatcher synth. Cosby sweater eu banh mi, qui irure
                        terry richardson ex squid. Aliquip placeat salvia cillum iphone. Seitan aliquip quis cardigan
                        american apparel, butcher voluptate nisi qui.
                      </p>
                    </TabPane>
                    <TabPane tabId="2">
                      <p className="mb-0">
                        Food truck fixie locavore, accusamus mcsweeney's marfa nulla single-origin coffee squid.
                        Exercitation +1 labore velit, blog sartorial PBR leggings next level wes anderson artisan four
                        loko farm-to-table craft beer twee. Qui photo booth letterpress, commodo enim craft beer mlkshk
                        aliquip jean shorts ullamco ad vinyl cillum PBR. Homo nostrud organic, assumenda labore
                        aesthetic magna delectus.
                      </p>
                    </TabPane>
                    <TabPane tabId="3">
                      <p className="mb-0">
                        Etsy mixtape wayfarers, ethical wes anderson tofu before they sold out mcsweeney's organic lomo
                        retro fanny pack lo-fi farm-to-table readymade. Messenger bag gentrify pitchfork tattooed craft
                        beer, iphone skateboard locavore carles etsy salvia banksy hoodie helvetica. DIY synth PBR
                        banksy irony. Leggings gentrify squid 8-bit cred pitchfork. Williamsburg banh mi whatever
                        gluten-free carles.
                      </p>
                    </TabPane>
                  </TabContent>
                </div>
              </Card>
            </Col>

            <div className="col-xl-6">
              <div className="card">
                <div className="card-header align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">Card Header Pills</h4>
                  <div className="flex-shrink-0">
                    <ul className="nav justify-content-end nav-pills card-header-pills" role="tablist">
                      <NavItem>
                        <NavLink
                          to="#"
                          className={classnames({
                            active: headerPillsTab === '1',
                          })}
                          onClick={() => {
                            toggleHeaderPills('1');
                          }}
                        >
                          <span className="d-block d-sm-none">
                            <i className="fas fa-home"></i>
                          </span>
                          <span className="d-none d-sm-block">Home</span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          to="#"
                          className={classnames({
                            active: headerPillsTab === '2',
                          })}
                          onClick={() => {
                            toggleHeaderPills('2');
                          }}
                        >
                          <span className="d-block d-sm-none">
                            <i className="far fa-user"></i>
                          </span>
                          <span className="d-none d-sm-block">Profile</span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          to="#"
                          className={classnames({
                            active: headerPillsTab === '3',
                          })}
                          onClick={() => {
                            toggleHeaderPills('3');
                          }}
                        >
                          <span className="d-block d-sm-none">
                            <i className="far fa-envelope"></i>
                          </span>
                          <span className="d-none d-sm-block">Message</span>
                        </NavLink>
                      </NavItem>
                    </ul>
                  </div>
                </div>

                <div className="card-body">
                  <TabContent className="text-muted" activeTab={headerPillsTab}>
                    <TabPane tabId="1">
                      <p className="mb-0">
                        Raw denim you probably haven't heard of them jean shorts Austin. Nesciunt tofu stumptown aliqua,
                        retro synth master cleanse. Mustache cliche tempor, williamsburg carles vegan helvetica.
                        Reprehenderit butcher retro keffiyeh dreamcatcher synth. Cosby sweater eu banh mi, qui irure
                        terry richardson ex squid. Aliquip placeat salvia cillum iphone. Seitan aliquip quis cardigan
                        american apparel, butcher voluptate nisi qui.
                      </p>
                    </TabPane>
                    <TabPane tabId="2">
                      <p className="mb-0">
                        Food truck fixie locavore, accusamus mcsweeney's marfa nulla single-origin coffee squid.
                        Exercitation +1 labore velit, blog sartorial PBR leggings next level wes anderson artisan four
                        loko farm-to-table craft beer twee. Qui photo booth letterpress, commodo enim craft beer mlkshk
                        aliquip jean shorts ullamco ad vinyl cillum PBR. Homo nostrud organic, assumenda labore
                        aesthetic magna delectus.
                      </p>
                    </TabPane>
                    <TabPane tabId="3">
                      <p className="mb-0">
                        Etsy mixtape wayfarers, ethical wes anderson tofu before they sold out mcsweeney's organic lomo
                        retro fanny pack lo-fi farm-to-table readymade. Messenger bag gentrify pitchfork tattooed craft
                        beer, iphone skateboard locavore carles etsy salvia banksy hoodie helvetica. DIY synth PBR
                        banksy irony. Leggings gentrify squid 8-bit cred pitchfork. Williamsburg banh mi whatever
                        gluten-free carles.
                      </p>
                    </TabPane>
                  </TabContent>
                </div>
              </div>
            </div>
          </Row>
          <Row>
            <Col xl={6}>
              <Card>
                <CardHeader className="align-items-center d-flex">
                  <CardTitle className="mb-0 flex-grow-1">Card Header Form Select</CardTitle>
                  <div className="flex-shrink-0">
                    <select className="form-select form-select-sm mb-0 my-n1">
                      <option defaultValue="MAY">May</option>
                      <option value="AP">April</option>
                      <option value="MA">March</option>
                      <option value="FE">February</option>
                      <option value="JA">January</option>
                      <option value="DE">December</option>
                    </select>
                  </div>
                </CardHeader>

                <CardBody className="text-muted">
                  <p className="mb-0">
                    Etsy mixtape wayfarers, ethical wes anderson tofu before they sold out mcsweeney's organic lomo
                    retro fanny pack lo-fi farm-to-table readymade. Messenger bag gentrify pitchfork tattooed craft
                    beer, iphone skateboard locavore carles etsy salvia banksy hoodie helvetica. DIY synth PBR banksy
                    irony. Leggings gentrify squid 8-bit cred pitchfork. Williamsburg banh mi whatever gluten-free
                    carles.
                  </p>
                </CardBody>
              </Card>
            </Col>

            <Col xl={6}>
              <Card>
                <CardHeader className="align-items-center d-flex">
                  <CardTitle className="mb-0 flex-grow-1">Card Header Button</CardTitle>
                  <div className="flex-shrink-0">
                    <div className="d-flex flex-wrap gap-2 mb-0 my-n1">
                      <UncontrolledDropdown>
                        <DropdownToggle type="button" className="btn btn-primary btn-sm">
                          Dropdown <i className="mdi mdi-chevron-down"></i>
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-end">
                          <DropdownItem to="#">Action</DropdownItem>
                          <DropdownItem to="#">Another action</DropdownItem>
                          <DropdownItem to="#">Something else here</DropdownItem>
                          <div className="dropdown-divider"></div>
                          <DropdownItem to="#">Separated link</DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                      <button type="button" className="btn btn-soft-secondary btn-sm">
                        Button
                      </button>
                    </div>
                  </div>
                </CardHeader>

                <CardBody className="text-muted">
                  <p className="mb-0">
                    Raw denim you probably haven't heard of them jean shorts Austin. Nesciunt tofu stumptown aliqua,
                    retro synth master cleanse. Mustache cliche tempor, williamsburg carles vegan helvetica.
                    Reprehenderit butcher retro keffiyeh dreamcatcher synth. Cosby sweater eu banh mi, qui irure terry
                    richardson ex squid. Aliquip placeat salvia cillum iphone. Seitan aliquip quis cardigan american
                    apparel, butcher voluptate nisi qui.
                  </p>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xl={6}>
              <Card>
                <CardHeader>
                  <CardTitle className="h4 mb-0">Collapse Example</CardTitle>
                  <p className="card-title-desc">
                    You can use a link with the <code>href</code> attribute, or a button with the{' '}
                    <code>data-bs-target</code> attribute. In both cases, the <code>data-bs-toggle="collapse"</code> is
                    required.
                  </p>
                </CardHeader>
                <CardBody>
                  <div className="d-flex gap-2 flex-wrap mb-3">
                    <Link to="#" onClick={t_col5} style={{ cursor: 'pointer' }} className="btn btn-primary mo-mb-2">
                      Link with href{' '}
                    </Link>
                    <button
                      onClick={t_col5}
                      className="btn btn-primary mo-mb-2"
                      type="button"
                      style={{ cursor: 'pointer' }}
                    >
                      Button with data-target
                    </button>
                  </div>
                  <Collapse isOpen={col5}>
                    <Card>
                      <CardBody>
                        Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid.
                        Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea
                        proident.Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson
                        ad squid. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente
                        ea proident.Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry
                        richardson ad squid.
                      </CardBody>
                    </Card>
                  </Collapse>
                </CardBody>
              </Card>
            </Col>

            <Col xl="6">
              <Card>
                <CardHeader>
                  <CardTitle>Multiple Targets Collapse</CardTitle>
                  <p className="card-title-desc">
                    A <code>&lt;button&gt;</code> or <code>&lt;a&gt;</code> can show and hide multiple elements by
                    referencing them with a selector in its <code>href</code> or <code>data-bs-target</code> attribute.
                  </p>
                </CardHeader>
                <CardBody>
                  <div className="d-flex gap-2 flex-wrap mb-3">
                    <Link to="#" onClick={t_col6} style={{ cursor: 'pointer' }} className="btn btn-primary">
                      Toggle first element
                    </Link>
                    <button onClick={t_col7} className="btn btn-primary" type="button" style={{ cursor: 'pointer' }}>
                      Toggle second element
                    </button>

                    <button onClick={t_col8} className="btn btn-primary" type="button" style={{ cursor: 'pointer' }}>
                      Toggle both element
                    </button>
                  </div>
                  <div className="row">
                    <div className="col">
                      <Collapse isOpen={col6}>
                        <Card>
                          <CardBody className="border shadow-none text-muted mb-0">
                            Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad
                            squid. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente
                            ea proident.
                          </CardBody>
                        </Card>
                      </Collapse>
                    </div>
                    <div className="col">
                      <Collapse isOpen={col7}>
                        <Card>
                          <CardBody className="border shadow-none text-muted mb-0">
                            Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad
                            squid. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente
                            ea proident.
                          </CardBody>
                        </Card>
                      </Collapse>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xl={6}>
              <Card>
                <CardHeader>
                  <CardTitle className="h4">Accordion Example</CardTitle>
                  <p className="card-title-desc">
                    Click the accordions below to expand/collapse the accordion content.
                  </p>
                </CardHeader>
                <CardBody>
                  <div className="accordion" id="accordion">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingOne">
                        <button
                          className={classnames('accordion-button', 'fw-medium', { collapsed: !col1 })}
                          type="button"
                          onClick={t_col1}
                          style={{ cursor: 'pointer' }}
                        >
                          Accordion Item #1
                        </button>
                      </h2>

                      <Collapse isOpen={col1} className="accordion-collapse">
                        <div className="accordion-body">
                          <div className="text-muted">
                            <strong className="text-dark">This is the first item's accordion body.</strong> It is hidden
                            by default, until the collapse plugin adds the appropriate classes that we use to style each
                            element. These classes control the overall appearance, as well as the showing and hiding via
                            CSS transitions. You can modify any of this with custom CSS or overriding our default
                            variables. It's also worth noting that just about any HTML can go within the{' '}
                            <code>.accordion-body</code>, though the transition does limit overflow.
                          </div>
                        </div>
                      </Collapse>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingTwo">
                        <button
                          className={classnames('accordion-button', 'fw-medium', { collapsed: !col2 })}
                          type="button"
                          onClick={t_col2}
                          style={{ cursor: 'pointer' }}
                        >
                          Accordion Item #2
                        </button>
                      </h2>

                      <Collapse isOpen={col2} className="accordion-collapse">
                        <div className="accordion-body">
                          <div className="text-muted">
                            <strong className="text-dark">This is the second item's accordion body.</strong> It is
                            hidden by default, until the collapse plugin adds the appropriate classes that we use to
                            style each element. These classes control the overall appearance, as well as the showing and
                            hiding via CSS transitions. You can modify any of this with custom CSS or overriding our
                            default variables. It's also worth noting that just about any HTML can go within the{' '}
                            <code>.accordion-body</code>, though the transition does limit overflow.
                          </div>
                        </div>
                      </Collapse>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingThree">
                        <button
                          className={classnames('accordion-button', 'fw-medium', { collapsed: !col3 })}
                          type="button"
                          onClick={t_col3}
                          style={{ cursor: 'pointer' }}
                        >
                          Accordion Item #3
                        </button>
                      </h2>
                      <Collapse isOpen={col3} className="accordion-collapse">
                        <div className="accordion-body">
                          <div className="text-muted">
                            <strong className="text-dark">This is the third item's accordion body.</strong> It is hidden
                            by default, until the collapse plugin adds the appropriate classes that we use to style each
                            element. These classes control the overall appearance, as well as the showing and hiding via
                            CSS transitions. You can modify any of this with custom CSS or overriding our default
                            variables. It's also worth noting that just about any HTML can go within the{' '}
                            <code>.accordion-body</code>, though the transition does limit overflow.
                          </div>
                        </div>
                      </Collapse>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl={6}>
              <Card>
                <CardHeader>
                  <CardTitle className="h4">Flush Accordion</CardTitle>
                  <p className="card-title-desc">
                    Add <code>.accordion-flush</code> to remove the default <code>background-color</code>, some borders,
                    and some rounded corners to render accordions edge-to-edge with their parent container.
                  </p>
                </CardHeader>
                <CardBody>
                  <div className="accordion accordion-flush" id="accordionFlushExample">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingFlushOne">
                        <button
                          className={classnames('accordion-button', 'fw-medium', { collapsed: !col9 })}
                          type="button"
                          onClick={t_col9}
                          style={{ cursor: 'pointer' }}
                        >
                          Accordion Item #1
                        </button>
                      </h2>

                      <Collapse isOpen={col9} className="accordion-collapse">
                        <div className="accordion-body">
                          <div className="text-muted">
                            <strong className="text-dark">This is the first item's accordion body.</strong> It is hidden
                            by default, until the collapse plugin adds the appropriate classes that we use to style each
                            element. These classes control the overall appearance, as well as the showing and hiding via
                            CSS transitions. You can modify any of this with custom CSS or overriding our default
                            variables. It's also worth noting that just about any HTML can go within the{' '}
                            <code>.accordion-body</code>, though the transition does limit overflow.
                          </div>
                        </div>
                      </Collapse>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingFlushTwo">
                        <button
                          className={classnames('accordion-button', 'fw-medium', { collapsed: !col10 })}
                          type="button"
                          onClick={t_col10}
                          style={{ cursor: 'pointer' }}
                        >
                          Accordion Item #2
                        </button>
                      </h2>

                      <Collapse isOpen={col10} className="accordion-collapse">
                        <div className="accordion-body">
                          <div className="text-muted">
                            <strong className="text-dark">This is the second item's accordion body.</strong> It is
                            hidden by default, until the collapse plugin adds the appropriate classes that we use to
                            style each element. These classes control the overall appearance, as well as the showing and
                            hiding via CSS transitions. You can modify any of this with custom CSS or overriding our
                            default variables. It's also worth noting that just about any HTML can go within the{' '}
                            <code>.accordion-body</code>, though the transition does limit overflow.
                          </div>
                        </div>
                      </Collapse>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingFlushThree">
                        <button
                          className={classnames('accordion-button', 'fw-medium', { collapsed: !col11 })}
                          type="button"
                          onClick={t_col11}
                          style={{ cursor: 'pointer' }}
                        >
                          Accordion Item #3
                        </button>
                      </h2>
                      <Collapse isOpen={col11} className="accordion-collapse">
                        <div className="accordion-body">
                          <div className="text-muted">
                            <strong className="text-dark">This is the third item's accordion body.</strong> It is hidden
                            by default, until the collapse plugin adds the appropriate classes that we use to style each
                            element. These classes control the overall appearance, as well as the showing and hiding via
                            CSS transitions. You can modify any of this with custom CSS or overriding our default
                            variables. It's also worth noting that just about any HTML can go within the{' '}
                            <code>.accordion-body</code>, though the transition does limit overflow.
                          </div>
                        </div>
                      </Collapse>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default UiTabsAccordions;
