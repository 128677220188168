import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PACKAGES_BY_VIRTUAL_STORAGE_ID } from 'src/helpers/graphql';
import { formatToLocaleDate } from 'src/helpers/ui';
import FilteredTable, { DefaultSortedByType } from 'src/components/FilteredTable/FilteredTable';

const defaultSortedBy: DefaultSortedByType[] = [
  {
    dataField: 'id',
    order: 'asc',
  },
];

export interface PackageListProps {
  virtualStorage: VirtualStorageDetails;
}

const PackageList: React.FC<PackageListProps> = ({ virtualStorage }) => {
  const [packages, setPackages] = useState<PackageInStorage[] | undefined>(undefined);
  const { t } = useTranslation();

  const columns = [
    {
      dataField: 'uniqueId',
      text: t('Common.id'),
      sort: true,
    },
    {
      dataField: 'ownerId',
      text: 'Owner id',
      sort: true,
    },
    {
      dataField: 'status',
      text: t('Common.status'),
      sort: true,
      formatter: (cell: any, row: PackageInStorage, rowIndex: number) => t(`Package.status.${row.status}`),
    },
    {
      dataField: 'packageType',
      text: t('Common.type'),
      sort: true,
      formatter: (cell: any, row: PackageInStorage, rowIndex: number) => t(`Package.packageType.${row.packageType}`),
    },
    {
      dataField: 'updatedAt',
      text: t('Common.lastChanged'),
      sort: true,
      formatter: (cell: any, row: PackageInStorage, rowIndex: number) =>
        formatToLocaleDate(row.updatedAt, 'May 23, 2022, 11:06:29 PM'),
    },
  ];

  // const generateUrlForStorage = (packageItem: PackageInStorage) => `/packages/${packageItem.id}`;

  const setItems = (packages: PackageInStorage[]) => {
    setPackages(packages);
  };

  return (
    <>
      <FilteredTable
        items={packages}
        setItems={setItems}
        getTableTitle={(itemsLength?: number) => `${itemsLength} packages`}
        query={PACKAGES_BY_VIRTUAL_STORAGE_ID}
        queryVariables={{ id: virtualStorage.id }}
        keyInResponse="packagesByVirtualStorageId"
        wrapper={(children: any) => children}
        columns={columns}
        defaultSortedBy={defaultSortedBy}
      />
    </>
  );
};

export default PackageList;
