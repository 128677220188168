import React from 'react';

import userDummy from 'src/assets/images/users/avatar-dummy.png';

type UserAvatarProps = {
  user?: any;
  className?: string;
  //  size:
};

const UserAvatar: React.FC<UserAvatarProps> = ({ user, className }) => {
  return <img className={className} src={userDummy} alt="" />;
};

export default UserAvatar;
