import React, { useState } from 'react';

import { EDIT_DELIVERY_DATE } from 'src/helpers/graphql';
import { useMutation } from '@apollo/client';
import DateTimePicker from 'react-datetime-picker';

import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import dayjs from 'dayjs';

const DateRangePicker = ({
  id,
  setShowDeliveryCalendar,
  showDeliveryCalendar,
  pickupRequestFrom,
  pickupRequestTo,
}: {
  id: string;
  setShowDeliveryCalendar: (value: boolean) => void;
  showDeliveryCalendar: Boolean;
  pickupRequestFrom: Date | string;
  pickupRequestTo: Date | string;
}) => {
  const currentPickUp = new Date() > new Date(pickupRequestFrom) ? new Date() : pickupRequestFrom;
  const [date, setDate] = useState<any>(currentPickUp);
  const dateFrom = new Date(pickupRequestFrom);
  const dateTo = new Date(pickupRequestTo);

  const diffInMilliseconds = Math.abs(Number(dateFrom) - Number(dateTo));
  const diffInHours = Math.floor(diffInMilliseconds / (1000 * 60 * 60));

  const [editDeliveryRequest] = useMutation(EDIT_DELIVERY_DATE);

  const convertDateToAnotherTimeZone = (date: any, timezone: any) => {
    const dateString = date.toLocaleString('en-US', {
      timeZone: timezone,
    });
    return new Date(dateString);
  };

  const getOffsetBetweenTimezonesForDate = (date: any, timezone1: any, timezone2: any) => {
    const timezone1Date = convertDateToAnotherTimeZone(date, timezone1);
    const timezone2Date = convertDateToAnotherTimeZone(date, timezone2);
    return timezone1Date.getTime() - timezone2Date.getTime();
  };

  let offset =
    getOffsetBetweenTimezonesForDate(
      new Date(date),
      'America/New_York',
      Intl.DateTimeFormat().resolvedOptions().timeZone,
    ) /
    1000 /
    60;
  let pickupRequestFromConverted = dayjs(new Date(date)).subtract(offset, 'minutes');
  let pickupRequestToConverted = dayjs(pickupRequestFromConverted).add(diffInHours, 'hours');

  const editDelivery = () => {
    editDeliveryRequest({
      variables: {
        id: id,
        pickupRequestFrom: pickupRequestFromConverted.toISOString(),
        pickupRequestTo: pickupRequestToConverted.toISOString(),
      },
    });
    setShowDeliveryCalendar(false);
  };

  return (
    <div className="d-flex flex-row flex-wrap gap-1">
      {showDeliveryCalendar ? (
        <>
          <DateTimePicker value={date} minDate={new Date()} onChange={setDate} />
          <div>
            <button
              onClick={() => setShowDeliveryCalendar(!showDeliveryCalendar)}
              className="btn btn-soft-light  border-0 cursor-pointer m-0 ms-2 py-0 px-2 rounded hover-overlay ripple"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Cancel Edit"
            >
              <i className="mdi mdi-close font-size-20"></i>
            </button>
            <button
              onClick={() => {
                editDelivery();
              }}
              disabled={date === null}
              className="btn btn-soft-light  border-0 cursor-pointer m-0 ms-2 py-0 px-2 rounded hover-overlay ripple"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Confirm Edit"
            >
              <i className="mdi mdi-check font-size-20"></i>
            </button>
          </div>
        </>
      ) : (
        <button
          onClick={() => setShowDeliveryCalendar(!showDeliveryCalendar)}
          className="btn btn-soft-light  border-0 cursor-pointer m-0 ms-2 py-0 px-2 rounded hover-overlay ripple"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="Edit Scheduled Delivery"
        >
          <i className="mdi mdi-pencil font-size-20"></i>
        </button>
      )}
    </div>
  );
};
export default DateRangePicker;
