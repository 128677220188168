import React, { useContext, useState } from 'react';
import MetaTags from 'react-meta-tags';
import { Container } from 'reactstrap';
import { t } from 'i18next';

import { GET_ALL_DELIVERY_REQUESTS, GET_MY_DELIVERY_REQUESTS } from 'src/helpers/graphql';
import { getAddressTexts, formatToLocaleDate, getDisplayName } from 'src/helpers/ui';
import { DeliveryRequestStatus } from 'src/types/types';
import { MULTI_FILTER_VALUE_SEPARATOR } from 'src/helpers/pagination';
import { AuthContext } from 'src/components/AuthContextSetter';
import DeliveryRequestStatusBadge from 'src/components/DeliveryRequest/DeliveryRequestStatusBadge';
import EditDeliveryRequestStatus from './EditDeliveryRequestStatus/EditDeliveryRequestStatus';
import FilteredTable from 'src/components/FilteredTable/FilteredTable';
import Breadcrumbs from 'src/components/Breadcrumbs';
import BulkActions from './ListActions/BulkActions';
import RowActions from './ListActions/RowActions';

const defaultSortedBy: any = [
  {
    dataField: 'status',
    order: 'asc',
  },
];

const Tasks: React.FC<{}> = () => {
  const authContext = useContext(AuthContext);

  const [showSetStatusDialog, setShowSetStatusDialog] = useState(false);
  const [statusDialogNewState, setStatusDialogNewState] = useState<DeliveryRequestStatus | undefined>(undefined);
  const [tasksForStatusChange, setTasksForStatusChange] = useState<
    (DeliveryRequestListItem | DeliveryRequestDetails)[]
  >([]);

  const [displayedTasks, setDisplayedTasks] = useState<DeliveryRequestListItem[] | undefined>(undefined);

  const changeDeliveryRequestStatus = (
    ev: React.MouseEvent,
    tasks: (DeliveryRequestListItem | DeliveryRequestDetails)[],
    newStatus: DeliveryRequestStatus,
  ) => {
    ev.stopPropagation();
    setStatusDialogNewState(newStatus);
    setShowSetStatusDialog(true);
    setTasksForStatusChange(tasks);
  };

  const columns = [
    {
      dataField: 'direction',
      text: 'Task',
      sort: true,
      formatter: (cell: any, row: DeliveryRequestListItem, rowIndex: number) =>
        t(`DeliveryRequest.direction.${row.direction}`),
    },
    {
      dataField: 'status',
      text: 'Status',
      sort: true,
      formatter: (cell: any, row: DeliveryRequestListItem, rowIndex: number) => (
        <DeliveryRequestStatusBadge status={row.status} />
      ),
    },
    {
      dataField: 'bag.id',
      text: 'Id',
      sort: true,
      formatter: (cell: any, row: DeliveryRequestListItem, rowIndex: number) =>
        row.package?.uniqueId || row.bag?.uniqueId,
    },

    {
      dataField: 'userId',
      text: 'Customer',
      sort: true,
      formatter: (cell: any, row: DeliveryRequestListItem, rowIndex: number) => getDisplayName(row.user),
    },
    {
      dataField: 'pickup',
      text: 'Pickup @',
      formatter: (cell: any, row: DeliveryRequestListItem, rowIndex: number) =>
        getAddressTexts(row.fromAddress, { format: 'short' }),
      sort: true,
    },
    {
      dataField: 'deliver',
      text: 'Deliver to',
      formatter: (cell: any, row: DeliveryRequestListItem, rowIndex: number) =>
        getAddressTexts(row.toAddress, { format: 'short' }),
      sort: true,
    },
    {
      dataField: 'assigned',
      text: 'Assigned to',
      sort: true,
      formatter: (cell: any, row: DeliveryRequestListItem, rowIndex: number) => getDisplayName(row.transporterUser),
    },

    {
      dataField: 'pickupRequestTo',
      text: 'Due',
      sort: true,
      formatter: (cell: any, row: DeliveryRequestListItem, rowIndex: number) =>
        formatToLocaleDate(row.pickupRequestTo, '5.23.2021 - 11:06 AM', 'America/New_York'),
    },
    {
      dataField: 'actions',
      text: 'Actions',
      sort: true,
      formatter: (cell: any, row: DeliveryRequestListItem, rowIndex: number) => (
        <div className="d-flex gap-2">
          <RowActions
            tasks={[row]}
            changeDeliveryRequestStatus={changeDeliveryRequestStatus}
            userContext={authContext.currentContext!}
          />
        </div>
      ),
    },
  ];

  const wrapper = (children: React.ReactNode) => (
    <div className="page-content">
      <MetaTags>
        <title>Tasks | Add + Space Admin</title>
      </MetaTags>
      <Container fluid>
        <Breadcrumbs
          items={[
            { label: 'Tasks', path: '#' },
            { label: 'Task list', path: '#' },
          ]}
        />
        {children}
      </Container>
    </div>
  );

  const generateUrlForTask = (task: DeliveryRequestListItem) => `/tasks/${task.id}`;

  const setItems = (tasks: DeliveryRequestListItem[]) => {
    setDisplayedTasks(tasks);
  };

  const getBulkActions = (selected: DeliveryRequestListItem[]) => {
    return (
      <BulkActions
        tasks={selected}
        changeDeliveryRequestStatus={changeDeliveryRequestStatus}
        userContext={authContext.currentContext!}
      />
    );
  };

  const isCurrentUserTransporter = authContext.auth.isTransporter();

  const baseTableProps = {
    items: displayedTasks,
    setItems: setItems,
    getTableTitle: (itemsLength?: number) => `${itemsLength} tasks`,
    wrapper: wrapper,
    columns: columns,
    defaultSortedBy: defaultSortedBy,
    generateUrlForEntity: generateUrlForTask,
    getBulkActions: getBulkActions,
  };

  const editTaskDialog = (
    <EditDeliveryRequestStatus
      show={showSetStatusDialog}
      setShow={setShowSetStatusDialog}
      newStatus={statusDialogNewState!}
      tasks={tasksForStatusChange!}
    />
  );

  if (isCurrentUserTransporter) {
    return (
      <>
        <FilteredTable
          {...baseTableProps}
          query={GET_MY_DELIVERY_REQUESTS}
          keyInResponse="myDeliveryRequests"
          savedFiltersKey="myTasks"
          paginated={false}
        />
        {editTaskDialog}
      </>
    );
  }

  const filterProcessor = (filters: any) => {
    if (!filters.filters) {
      return {};
    }

    if (Array.isArray(filters.filters)) {
      return { filters: [{ op: 'IN', field: 'DeliveryRequest.status', values: filters.filters }] };
    }

    return { filters: [{ op: 'EQ', field: 'DeliveryRequest.status', values: filters.filters }] };
  };

  const all = [
    DeliveryRequestStatus.CREATED,
    DeliveryRequestStatus.SCHEDULED,
    DeliveryRequestStatus.AT_TRANSPORTER,
    DeliveryRequestStatus.CANCELLED,
    DeliveryRequestStatus.MISSED,
    DeliveryRequestStatus.DELIVERED,
  ].join(MULTI_FILTER_VALUE_SEPARATOR);

  const inProgress = [
    DeliveryRequestStatus.CREATED,
    DeliveryRequestStatus.SCHEDULED,
    DeliveryRequestStatus.AT_TRANSPORTER,
  ].join(MULTI_FILTER_VALUE_SEPARATOR);

  return (
    <>
      <FilteredTable
        {...baseTableProps}
        query={GET_ALL_DELIVERY_REQUESTS}
        keyInResponse="allDeliveryRequests"
        savedFiltersKey="tasks"
        paginated={true}
        filterOptions={[
          {
            value: all,
            label: 'All',
          },
          { value: '', label: '---' },
          {
            value: inProgress,
            label: 'In progress',
          },
          { value: '', label: '---' },
          { value: DeliveryRequestStatus.CREATED, label: t(`DeliveryRequest.status.CREATED`) },
          { value: DeliveryRequestStatus.SCHEDULED, label: t(`DeliveryRequest.status.SCHEDULED`) },
          { value: DeliveryRequestStatus.AT_TRANSPORTER, label: t(`DeliveryRequest.status.AT_TRANSPORTER`) },
          { value: DeliveryRequestStatus.DELIVERED, label: t(`DeliveryRequest.status.DELIVERED`) },
          { value: '', label: '---' },
          { value: DeliveryRequestStatus.MISSED, label: t(`DeliveryRequest.status.MISSED`) },
          { value: DeliveryRequestStatus.CANCELLED, label: t(`DeliveryRequest.status.CANCELLED`) },
        ]}
        defaultFilterValue={inProgress}
        filterProcessor={filterProcessor}
      />

      {editTaskDialog}
    </>
  );
};

export default Tasks;
