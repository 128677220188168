import React, { useEffect, useContext } from 'react';

import { AuthContext } from 'src/components/AuthContextSetter';
import { logout } from 'src/helpers/backend_helper';

interface LogoutProps {}

const Logout: React.FC<LogoutProps> = () => {
  const authContext = useContext(AuthContext);

  useEffect(() => {
    (async () => {
      try {
        const response: Promise<any> = await logout();
        authContext.logout();
      } finally {
        // document.location = '/login';
      }
    })();
  }, []);

  return null;
};

export default Logout;
