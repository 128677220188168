import { DeliveryRequestStatus, DeliveryRequestDirection, DeliveryRequestScheduleType } from 'src/types/types';

const address1: FullAddress = {
  id: '1',
  areaId: null,
  city: 'New York',
  clientId: null,
  country: 'US',
  door: '22',
  floor: '5',
  info: 'Updated test address',
  isActive: true,
  postalCode: '10005',
  route: 'W 14th St',
  state: 'NY',
  streetNumber: '52',
};

const address2: FullAddress = {
  id: '2',
  areaId: null,
  city: 'New York',
  clientId: null,
  country: 'US',
  door: '1B',
  floor: '1',
  info: 'Brian Griffin Test address',
  isActive: true,
  postalCode: '10003',
  route: 'E 14th St',
  state: 'NY',
  streetNumber: '12',
};

export const sampleAdmin: User = {
  id: '11111111-1111-1111-1111-111111111111',
  email: 'demo@a.d.d.s.p.a.c.e.com',
  firstName: 'Admin',
  lastName: 'Demo',
  createdAt: '2022-04-07T07:19:51.659Z',
  phoneNumber: '+36309999997',
  userContexts: [
    {
      id: '11111111-1111-1111-2222-111111111111',
      role: 'USER',
      context: 'SEARCHER',
      company: null,
      addresses: [
        {
          ...address1,
          id: '0726c45b-46a5-44bd-b0ab-eeeb2d9aee49',
        },
        {
          ...address1,
          id: 'c18a192b-06ac-4076-bdc1-b5d74fb37805',
        },
        {
          ...address2,
          id: 'f09a6c16-5eb1-41de-bc58-a0f3236700d6',
        },
      ],
    },
    {
      id: '11111111-1111-1111-2222-111111111111',
      role: 'ADMIN',
      context: 'BACKOFFICE',
      company: null,
      addresses: [],
    },
  ],
};

export const sampleTransporter: User = {
  id: '11111111-1111-1111-1111-222222222222',
  email: 'transporter@a.d.d.s.p.a.c.e.com',
  firstName: 'Transporter',
  lastName: 'Context',
  createdAt: '2022-04-07T07:19:51.659Z',
  phoneNumber: '+36309999997',
  userContexts: [
    {
      id: '11111111-1111-1111-2222-111111111111',
      role: 'USER',
      context: 'TRANSPORTER',
      company: null,
      addresses: [
        {
          ...address1,
          id: '0726c45b-46a5-44bd-b0ab-eeeb2d9aee49',
        },
      ],
    },
  ],
};

export const sampleDeliveryRequest: DeliveryRequestDetails = {
  id: '11111111-1111-1111-4444-111111111111',
  status: DeliveryRequestStatus.CREATED,
  userId: '11111111-1111-1111-1111-111111111111',
  user: {
    id: '11111111-1111-1111-1111-111111111111',
    firstName: 'Admin',
    lastName: 'Demo',
  },
  transporterContextId: '11111111-1111-1111-1111-222222222222',
  transporterUser: {
    id: '11111111-1111-1111-1111-222222222222',
    firstName: 'Transporter',
    lastName: 'Context',
  },
  pickupRequestFrom: '2022-04-09T07:19:51.659Z',
  pickupRequestTo: '2022-04-10T07:19:51.659Z',
  pickupAt: '2022-04-09T07:21:51.659Z',
  scheduleType: DeliveryRequestScheduleType.SCHEDULED,
  bagId: '11111111-1111-1111-3333-111111111111',
  packageInfoId: '11111111-1111-1111-4444-111111111111',
  fromAddress: {
    ...address1,
    id: '0726c45b-46a5-44bd-b0ab-eeeb2d9aee48',
  },
  toAddress: {
    ...address2,
    id: 'f09a6c16-5eb1-41de-bc58-a0f3236700d5',
  },
  direction: DeliveryRequestDirection.USER_TO_STORAGE,
  updatedAt: '2022-04-09T07:21:51.659Z',
  /*
  direction: DeliveryRequestDirection.USER_TO_STORAGE,
  dropOffAt: '2022-04-09T07:27:51.659Z',
  */
};
