import React, { useState, useEffect } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useMutation, ApolloError } from '@apollo/client';

import { CANCEL_DELIVERY_REQUEST } from 'src/helpers/graphql';
import ModalForm, { setFieldErrors } from 'src/components/Forms/ModalForm';

type Inputs = {};

type StatusToCancelledType = {
  show: boolean;
  setShow: Function;
  handleSingleOrBulkSubmit: Function;
  tasks: (DeliveryRequestListItem | DeliveryRequestDetails)[];
};

const StatusToCancelled: React.FC<StatusToCancelledType> = ({ show, setShow, handleSingleOrBulkSubmit, tasks }) => {
  const {
    handleSubmit,
    setError,
    reset,
    formState: { errors },
  } = useForm<Inputs>();

  const [globalError, setGlobalError] = useState<string | ApolloError | undefined>('');

  const [cancelDeliveryRequest, { data, loading, error }] = useMutation(CANCEL_DELIVERY_REQUEST);

  useEffect(() => {
    reset();
  }, [tasks?.[0]?.id]);

  const onSubmit: SubmitHandler<Inputs> = async (data) =>
    handleSingleOrBulkSubmit({
      data: tasks.map((task) => ({
        id: task.id,
      })),
      submitFn: cancelDeliveryRequest,
      setFieldErrors: (err: any) => setFieldErrors(err, setError, setGlobalError),
    });

  useEffect(() => {
    setFieldErrors(error, setError, setGlobalError);
  }, [error]);

  return (
    <ModalForm
      title="Cancel task"
      show={show}
      setShow={setShow}
      onSubmit={handleSubmit(onSubmit)}
      loading={loading}
      globalError={globalError}
    >
      <p>
        {tasks.length === 1 ? 'Do you really want to cancel this task?' : 'Do you really want to cancel these tasks?'}
      </p>
    </ModalForm>
  );
};

export default StatusToCancelled;
