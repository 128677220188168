import React from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';

import WalletPieChart from './WalletPieChart';

const WalletBalance = () => {
  return (
    <React.Fragment>
      <Col xl={6}>
        <Card className="card-h-100">
          <CardBody>
            <div className="d-flex flex-wrap align-items-center mb-4">
              <h5 className="card-title me-2">Plan distribution (Users & MRR)</h5>
            </div>

            <Row className="align-items-center">
              <div className="col-sm">
                <div id="wallet-balance" className="apex-charts">
                  <WalletPieChart />
                </div>
              </div>
              <div className="col-sm align-self-center">
                <div className="mt-4 mt-sm-0">
                  <div>
                    <p className="mb-2">
                      <i className="mdi mdi-circle align-middle font-size-10 me-2 text-success"></i> Mini
                    </p>
                    <h6>
                      4412 = <span className="text-muted font-size-14 fw-normal">$ 4025.32</span>
                    </h6>
                  </div>

                  <div className="mt-4 pt-2">
                    <p className="mb-2">
                      <i className="mdi mdi-circle align-middle font-size-10 me-2 text-primary"></i> Basic
                    </p>
                    <h6>
                      5701 = <span className="text-muted font-size-14 fw-normal">$ 1123.64</span>
                    </h6>
                  </div>

                  <div className="mt-4 pt-2">
                    <p className="mb-2">
                      <i className="mdi mdi-circle align-middle font-size-10 me-2 text-info"></i> Medium
                    </p>
                    <h6>
                      33811 = <span className="text-muted font-size-14 fw-normal">$ 2263.09</span>
                    </h6>
                  </div>

                  <div className="mt-4 pt-2">
                    <p className="mb-2">
                      <i className="mdi mdi-circle align-middle font-size-10 me-2 text-info"></i> Extended
                    </p>
                    <h6>
                      33811 = <span className="text-muted font-size-14 fw-normal">$ 2263.09</span>
                    </h6>
                  </div>
                  <div className="mt-4 pt-2">
                    <p className="mb-2">
                      <i className="mdi mdi-circle align-middle font-size-10 me-2 text-info"></i> Most Value
                    </p>
                    <h6>
                      33811 = <span className="text-muted font-size-14 fw-normal">$ 2263.09</span>
                    </h6>
                  </div>
                </div>
              </div>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default WalletBalance;
