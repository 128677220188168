import Login from 'src/pages/Authentication/Login';
import Logout from 'src/pages/Authentication/Logout';
import ForgotPassword from 'src/pages/Authentication/ForgotPassword';

import Home from 'src/pages/Home';

import Tasks from 'src/pages/Tasks';
import TaskDetails from 'src/pages/Tasks/TaskDetails/TaskDetails';

import Boxes from 'src/pages/Boxes';

import PackageDetails from 'src/pages/Packages/PackageDetails/PackageDetails';

import Storages from 'src/pages/Storages';
import StorageDetails from 'src/pages/Storages/StorageDetails';

import Users from 'src/pages/Users';
import UserDetails from 'src/pages/Users/UserDetails/UserDetails';

import Labels from 'src/pages/Tools/Labels';

import Taxonomy from 'src/pages/Tools/AI';
import TagBlacklist from 'src/pages/Tools/AI/BlackList/TagBlackList';

import AuthLayout from 'src/components/AuthLayout';
import NonAuthLayout from 'src/components/NonAuthLayout';

interface RouteProps {
  path: string;
  component: any;
  notExact?: boolean;
  layout: React.FC<any>;
  ability: [action: any, subject: any, field?: string | undefined];
}

export const geAllRoutes = () => {
  const routes: RouteProps[] = [
    //-----------------------------
    // Noauth Pages
    //-----------------------------
    { path: '/login', component: Login, layout: NonAuthLayout, ability: ['visit', 'GuestPages'] },
    { path: '/logout', component: Logout, layout: NonAuthLayout, ability: ['visit', 'GenericPages'] },
    {
      path: '/forgot-password',
      component: ForgotPassword,
      layout: NonAuthLayout,
      ability: ['visit', 'GuestPages'],
    },

    { path: '/my-tasks', component: Tasks, layout: AuthLayout, ability: ['visit', 'AdminMyTasks', 'MyTasks'] },

    //-----------------------------
    // Admin Pages
    //-----------------------------

    { path: '/tasks', component: Tasks, layout: AuthLayout, ability: ['visit', 'Tasks', 'Tasks'] },
    {
      path: '/tasks/:taskId',
      component: TaskDetails,
      layout: AuthLayout,
      ability: ['visit', 'Tasks', 'TaskDetails'],
    },

    { path: '/storages', component: Storages, layout: AuthLayout, ability: ['visit', 'Storages', 'Storages'] },
    {
      path: '/storages/:storageId',
      component: StorageDetails,
      layout: AuthLayout,
      ability: ['visit', 'Storages', 'StorageDetails'],
    },

    { path: '/boxes', component: Boxes, layout: AuthLayout, ability: ['visit', 'Boxes', 'Boxes'] },

    {
      path: '/packages/:packageId',
      component: PackageDetails,
      layout: AuthLayout,
      ability: ['visit', 'Packages', 'PackageDetails'],
    },

    { path: '/users', component: Users, layout: AuthLayout, ability: ['visit', 'AdminPages'] },
    { path: '/users/:userId', component: UserDetails, layout: AuthLayout, ability: ['visit', 'AdminPages'] },

    { path: '/labels', component: Labels, layout: AuthLayout, ability: ['visit', 'AdminPages'] },

    { path: '/ai/tag-blacklist', component: TagBlacklist, layout: AuthLayout, ability: ['visit', 'AdminPages'] },
    { path: '/ai/taxonomy', component: Taxonomy, layout: AuthLayout, ability: ['visit', 'AdminPages'] },

    { path: '/home', component: Home, layout: AuthLayout, ability: ['visit', 'GenericPages'] },
  ];

  return routes;
};
