import React from 'react';
import MetaTags from 'react-meta-tags';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import Breadcrumbs from 'src/ux/components/Breadcrumb';

const Reports = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Reports | Add + Space Admin</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Reports" breadcrumbItem="Report overview"></Breadcrumbs>
          <Row>
            <h1>Reports</h1>
            <p>Description text</p>
            <Col lg="3">
              <Card>
                <Link to="/reports/transporter">
                  <CardBody>
                    <h1>
                      <i className="mdi mdi-truck text-muted"></i>
                    </h1>
                    <h3>Transporter</h3>
                    <p>Report description goes here</p>
                  </CardBody>
                </Link>
              </Card>
            </Col>
            <Col lg="3">
              <Card>
                <Link to="/reports/provider">
                  <CardBody>
                    <h1>
                      <i className="mdi mdi-grid text-muted"></i>
                    </h1>
                    <h3>Provider</h3>
                    <p>Report description goes here</p>
                  </CardBody>
                </Link>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default Reports;
