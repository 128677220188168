import { useLazyQuery, useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MetaTags from 'react-meta-tags';
import { Container, Row, Col, Spinner } from 'reactstrap';
import SuperCategory from 'src/components/SuperCategory';
import Breadcrumbs from 'src/components/Breadcrumbs';
import { ALL_BLACKLISTED_PACKAGE_TAGS, UPDATE_TAG } from 'src/helpers/graphql';
import TagBlacklistDialog from './TagBlacklistDialog';

const TagBlacklist = () => {
  const { t } = useTranslation();
  const [updateTag, updateTagMutation] = useMutation(UPDATE_TAG);
  const [getAllBlackListedPackageTags, { loading, error, data }] = useLazyQuery(ALL_BLACKLISTED_PACKAGE_TAGS);

  const [modal, setmodal] = useState(false);

  const onDeleteTag = () => (tag: PackageTag) => async () => {
    try {
      await updateTag({
        variables: { id: tag.id, categoryIds: tag.categoryIds, isBlacklisted: false },
      });
      getAllBlackListedPackageTags();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getAllBlackListedPackageTags();
  }, [getAllBlackListedPackageTags]);

  const items = data?.allBlacklistedPackageTags as PackageTag[];

  if (loading || updateTagMutation.loading || (!items && !error && !updateTagMutation.error)) {
    return <Spinner className="spinner--with-space" color="secondary" />;
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>AI Tag blacklist | Add + Space Admin</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs
            items={[
              {
                label: t('Menu.tools'),
                path: '#',
              },
              {
                label: t('Menu.blacklist'),
                path: '/ai/tag-blacklist',
              },
            ]}
          />
          <Row className="align-items-start mb-3" style={{ minHeight: '3.5rem' }}>
            <Col lg="12">
              <div className="d-flex justify-content-between">
                <div>
                  <h1>{t('Blacklist.title')}</h1>
                  <p className="lead">{t('Blacklist.description')}</p>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <SuperCategory
                name={t('Blacklist.category.name')}
                tags={items}
                onDeleteTag={onDeleteTag()}
                tagColor="#000"
                onAddTagToCategory={() => setmodal(!modal)}
              />
            </Col>
          </Row>
        </Container>
      </div>
      <TagBlacklistDialog show={modal} setShow={() => setmodal(!modal)} />
    </React.Fragment>
  );
};

export default TagBlacklist;
