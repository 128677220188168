import React, { useState, useContext, useEffect } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useMutation, ApolloError } from '@apollo/client';

import { DROP_OFF_PACKAGE_AT_USER, DROP_OFF_PACKAGE_AT_USER_ON_BEHALF_OF_TRANSPORTER } from 'src/helpers/graphql';
import { AuthContext } from 'src/components/AuthContextSetter';
import { getDisplayName } from 'src/helpers/ui';
import TextInput from 'src/components/Forms/TextInput';
import ModalForm, { setFieldErrors } from 'src/components/Forms/ModalForm';

type Inputs = {
  transporterContextId: string;
  packageUniqueId: string;
};

type StatusToDeliveredType = {
  show: boolean;
  setShow: Function;
  handleSingleOrBulkSubmit: Function;
  tasks: (DeliveryRequestListItem | DeliveryRequestDetails)[];
};

const StatusToDelivered: React.FC<StatusToDeliveredType> = ({ show, setShow, handleSingleOrBulkSubmit, tasks }) => {
  const authContext = useContext(AuthContext);

  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors },
  } = useForm<Inputs>();

  const [globalError, setGlobalError] = useState<string | ApolloError | undefined>('');

  const [dropoffPackageAtUser, { loading, error }] = useMutation(
    authContext.auth.isTransporter() ? DROP_OFF_PACKAGE_AT_USER : DROP_OFF_PACKAGE_AT_USER_ON_BEHALF_OF_TRANSPORTER,
  );

  const onSubmit: SubmitHandler<Inputs> = async (data) =>
    handleSingleOrBulkSubmit({
      data: tasks.map((task) => ({
        ...(authContext.auth.isTransporter() ? {} : { transporterContextId: task.transporterContextId }),
        packageUniqueId: task.package?.uniqueId,
      })),
      submitFn: dropoffPackageAtUser,
      setFieldErrors: (err: any) => setFieldErrors(err, setError, setGlobalError),
    });

  useEffect(() => {
    setFieldErrors(error, setError, setGlobalError);
  }, [error]);

  useEffect(() => {
    reset();
  }, [tasks?.[0]?.id]);

  return (
    <ModalForm
      title="Mark as delivered"
      show={show}
      setShow={setShow}
      onSubmit={handleSubmit(onSubmit)}
      loading={loading}
      globalError={globalError}
    >
      <TextInput
        label="Transporter"
        defaultValue={tasks.length === 1 ? getDisplayName(tasks[0].transporterUser) : undefined}
        {...register('transporterContextId')}
        disabled={true}
        errors={errors.transporterContextId}
      />

      <TextInput
        label="Bag or box unique Id"
        defaultValue={tasks.length === 1 ? tasks[0].package?.uniqueId : undefined}
        {...register('packageUniqueId')}
        disabled={true}
        errors={errors.packageUniqueId}
      />
    </ModalForm>
  );
};

export default StatusToDelivered;
