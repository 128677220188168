import React from 'react';
import MetaTags from 'react-meta-tags';

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Badge,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import PackageList from 'src/ux/components/PackageList';
import Breadcrumbs from 'src/ux/components/Breadcrumb';
import StorageWidgets from './StorageWidgets';

export interface StorageDetailsProps {}

const StorageDetails: React.FC<StorageDetailsProps> = () => {
  const options: Object = {
    chart: {
      height: 50,
      type: 'line',
      toolbar: { show: false },
    },
    colors: ['#5400CA'],
    stroke: {
      curve: 'smooth',
      width: 2,
    },
    xaxis: {
      labels: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
    },
    tooltip: {
      fixed: {
        enabled: false,
      },
      x: {
        show: false,
      },
      y: {
        title: {
          formatter: function (seriesName: any) {
            return '';
          },
        },
      },
      marker: {
        show: false,
      },
    },
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Storage Details | Add + Space Admin</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Storages" breadcrumbItem="Storage details" />
          <Row>
            <h1 className="mb-3">Storage name</h1>
            <Col lg="3">
              <Card>
                <CardHeader className="align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">
                    <i className="mdi mdi-grid me-3"></i>Details
                  </h4>
                  <button type="button" className="btn btn-soft-light btn-sm me-1">
                    <i className="mdi mdi-pencil label-icon"></i>
                  </button>
                  <UncontrolledDropdown>
                    <DropdownToggle className="btn btn-soft-light btn-sm" tag="a">
                      <i className="mdi mdi-dots-horizontal label-icon" />
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-end">
                      <li>
                        <DropdownItem to="#">Option 1</DropdownItem>
                      </li>
                      <li>
                        <DropdownItem to="#">Option 2</DropdownItem>
                      </li>
                      <li>
                        <DropdownItem to="#">Option 3</DropdownItem>
                      </li>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </CardHeader>
                <CardBody>
                  <h2>
                    <Badge className="mb-2 bg-success text-black">Active</Badge>
                  </h2>
                  <h5>
                    <a href="/users/user">Company/Owner</a> <br />
                    123 Customer Street
                    <br /> 10001 New York, NY
                  </h5>
                  <p>+1 555 123456789</p>
                  <h5 className="text-muted mb-1 mt-4">Details</h5>
                  <p>
                    Usage: 2000
                    <br />
                    Volume: 3000
                    <br />
                    Dimensions: W123 H123 L123.
                    <br />
                    Active since: 01.01.22
                    <br />
                    Approved by: Employee Name
                  </p>
                  <h5 className="text-muted mb-1 mt-4">Properties</h5>
                  <Badge pill className="me-1 bg-dark">
                    Secure
                  </Badge>
                  <Badge pill className="me-1 bg-dark">
                    Heated
                  </Badge>
                  <Badge pill className="me-1 bg-dark">
                    Premium
                  </Badge>
                </CardBody>
              </Card>
            </Col>
            <Col lg="9">
              <Row>
                <StorageWidgets options={options} />
              </Row>

              <PackageList title="6258 Packages in storage"></PackageList>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default StorageDetails;
