import React from 'react';
import { Card, CardBody } from 'reactstrap';

import classnames from 'classnames';

export interface CreditCardStatusProps {
  title: string;
  styleNames: string;
  cardDetails: string;
}

const CreditCardStatus: React.FC<CreditCardStatusProps> = ({ styleNames, title, cardDetails }) => {
  return (
    <React.Fragment>
      <Card className={classnames('', styleNames)}>
        <CardBody>
          <h2 className={classnames('mb-3', styleNames)}>
            <i className="mdi mdi-credit-card me-3"></i>
            {title}
          </h2>
          <p className="card-text">{cardDetails}</p>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default CreditCardStatus;
