import React from 'react';
import classnames from 'classnames';
import { Badge } from 'reactstrap';
import styled from 'styled-components';

import { UserContextRoleType, UserContextType } from 'src/types/types';

type UserContextBadgeProps = {
  context: UserContextType;
  role: UserContextRoleType;
  className?: string;
};

const UserContextBadge: React.FC<UserContextBadgeProps> = ({ context, role, className }) => {
  const classList = classnames('rounded-pill', className, {
    'bg-secondary': context === UserContextType.SEARCHER,
    'bg-dark': context === UserContextType.TRANSPORTER,
    'bg-primary': context === UserContextType.PROVIDER,
    'bg-danger': context === UserContextType.BACKOFFICE,
  });

  return (
    <StyledBadge className={classList} title={`${context} - ${role}`}>
      {context}
    </StyledBadge>
  );
};

export default UserContextBadge;

const StyledBadge = styled(Badge)`
  margin-right: 5px;

  &:last-child {
    margin-right: 0;
  }
`;
