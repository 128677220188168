import React, { useContext } from 'react';
import { Table, Card, CardHeader } from 'reactstrap';
import { UncontrolledDropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';

import UserContextBadge from 'src/components/User/UserBadges/UserContextBadge';
import { UserPageContext } from './UserDetails';

export interface AddressesProps {}

const Addresses: React.FC<AddressesProps> = () => {
  const { user } = useContext(UserPageContext);

  const addressesExists = user.userContexts.find((context) => context.addresses?.length > 0);

  if (!addressesExists) {
    return null;
  }

  return (
    <React.Fragment>
      <Card>
        <CardHeader>
          <h4 className="card-title mb-0 flex-grow-1">Addresses</h4>
        </CardHeader>
        <div className="card-body">
          <div className="table-responsive">
            <Table className="table mb-0 align-middle">
              <thead>
                <tr>
                  <th>Context</th>
                  <th>Role</th>
                  <th>Address</th>
                  <th>Zip</th>
                  <th>City</th>
                  <th>Name on doorbell</th>
                  <th>Floor</th>
                  <th>Description</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {user.userContexts.map((context) =>
                  context.addresses.map((address) => (
                    <tr key={address.id}>
                      <td>{context.context}</td>
                      <td>
                        <UserContextBadge role={context.role} context={context.context} />
                      </td>
                      <td>
                        {address.streetNumber} {address.route}
                      </td>
                      <td>{address.postalCode}</td>
                      <td>{address.city}</td>
                      <td>{address.door}</td>
                      <td>{address.floor}</td>
                      <td>{address.info}</td>
                      <td align="right">
                        <UncontrolledDropdown>
                          <DropdownToggle className="btn btn-soft-light btn-sm" tag="a">
                            <i className="mdi mdi-dots-horizontal"></i>
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-end">
                            <li>
                              <DropdownItem
                                to="#"
                                onClick={(ev) => {
                                  // !FIXME send the precheck request, then the delete request
                                }}
                              >
                                Delete
                              </DropdownItem>
                            </li>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </td>
                    </tr>
                  )),
                )}
              </tbody>
            </Table>
          </div>
        </div>
      </Card>
    </React.Fragment>
  );
};
export default Addresses;
