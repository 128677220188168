import { SubscriptionPaymentStatus } from 'src/types/types';

export const getActiveSubscription = (userSubscriptions: SubscriptionInfo[]) =>
  userSubscriptions.find((subscription) => subscription.paymentStatus === SubscriptionPaymentStatus.ACTIVE);

export const generateSubscriptionText = (subscription: SubscriptionInfo) => {
  if (subscription.paymentStatus !== SubscriptionPaymentStatus.ACTIVE) {
    return `subscription.paymentStatus.${subscription.paymentStatus}`;
  }

  /*
  const parts: string[] = [];
  parts.push(`${subscription.plan.includedBags} boxes`);

  subscription.plan.returns.forEach((ret) => {
    if (ret.included! > 0) {
      parts.push(`${ret.included} free ${ret.scheduleType}`);
    }
  });

  subscription.plan.pickups.forEach((ret) => {
    if (ret.included! > 0) {
      parts.push(`${ret.included} free ${ret.scheduleType}`);
    }
  });

  // '5 boxes - Free pick ups - Standard insurance';
  return parts.join(' - ');
  */

  const boxes = subscription.plan.includedPackages === 1 ? '1 box ' : `${subscription.plan.includedPackages} boxes `;

  return `${boxes} - Free pickups & returns - Standard insurance`;
};
