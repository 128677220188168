import React, { Component, ErrorInfo, ReactNode } from 'react';
import { Col, Container, Row } from 'reactstrap';

import error from 'src/assets/images/error-img.png';

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
  error?: Error;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(err: any): State {
    console.log(err);
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error: err };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      return (
        <div className="my-5 pt-5">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center mb-5">
                  <h1 className="display-1 fw-semibold">Ooooops...</h1>
                  <h4 className="text-uppercase">An error occured</h4>
                  <div className="mt-5 text-center">
                    <a href="/home" className="btn btn-primary">
                      Back to Homepage
                    </a>
                  </div>
                </div>
              </Col>
            </Row>
            <div className="row justify-content-center">
              <div className="col-md-10 col-xl-8">
                <div>
                  <img src={error} alt="" className="img-fluid" />
                </div>
              </div>
            </div>
          </Container>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
