import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLazyQuery } from '@apollo/client';
import { Card, CardHeader, CardBody, Spinner } from 'reactstrap';

import { getDisplayName } from 'src/helpers/ui';
import { GET_USER } from 'src/helpers/graphql';
import OwnerCard from './OwnerCard';

interface PackageDataProps {
  packageData: Package;
}
const PackageData: React.FC<PackageDataProps> = ({ packageData }) => {
  const { t } = useTranslation();

  const [getUserQuery, { loading, error, data }] = useLazyQuery(GET_USER);

  useEffect(() => {
    if (packageData.ownerId) {
      getUserQuery({ variables: { id: packageData.ownerId } });
    }
  }, [packageData.ownerId]);

  if (loading) {
    return <Spinner className="spinner--with-space" color="secondary" />;
  }

  const user = packageData.ownerId ? (data?.user as User) : null;

  const ownerBox = user ? (
    <OwnerCard packageData={packageData} userId={user.id} userName={getDisplayName(user)} />
  ) : null;

  const props = [
    ...(packageData.name ? [<>Name: {packageData.name}</>] : []),
    ...(packageData.note ? [<>Note: {packageData.note}</>] : []),
  ];

  const infoBox =
    props.length > 0 ? (
      <Card>
        <CardHeader className="align-items-center d-flex">
          <h4 className="card-title mb-0 flex-grow-1">
            <i className="mdi mdi-grid me-3"></i>
            {t('Common.details')}
          </h4>
        </CardHeader>
        <CardBody>
          {props.map((prop, ix) => (
            <p key={ix}>{prop}</p>
          ))}
        </CardBody>
      </Card>
    ) : null;

  return (
    <>
      {ownerBox}
      {infoBox}
    </>
  );
};

export default PackageData;
