interface StorageDetailsProps {
  id: number;
  title: string;
  price: number;
  rank: string;
  isDoller: boolean;
  postFix: string;
  statusColor: string;
  series: Array<any>;
}

const StorageWidgetsData: Array<StorageDetailsProps> = [
  {
    id: 1,
    title: 'Packages',
    price: 6258,
    rank: '-29 boxes',
    isDoller: false,
    postFix: '',
    statusColor: 'danger',
    series: [15, 42, 47, 2, 14, 19, 65, 75, 47, 15, 42, 47, 2, 14, 12],
  },

  {
    id: 2,
    title: 'Capacity',
    price: 865435,
    rank: '+29 boxes',
    isDoller: false,
    postFix: '',
    statusColor: 'success',
    series: [2, 10, 18, 22, 36, 15, 47, 75, 65, 19, 14, 2, 47, 42, 15],
  },

  {
    id: 3,
    title: 'ARR',
    price: 432,
    rank: '+$2.8k',
    isDoller: true,
    postFix: 'M',
    statusColor: 'success',
    series: [47, 15, 2, 67, 22, 20, 36, 60, 60, 30, 50, 11, 12, 3, 8],
  },
];

export { StorageWidgetsData };
