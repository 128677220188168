import React from 'react';
import { Link } from 'react-router-dom';
import { Container } from 'reactstrap';

const ListAllUxPages = () => {
  return (
    <div className="page-content">
      <Container fluid>
        <Link to="/ux">all pages</Link>

        <hr />

        <Link to="/ux/pages-comingsoon">pages-comingsoon</Link>
        <br />
        <Link to="/ux/page-confirm-mail">page-confirm-mail</Link>
        <br />
        <Link to="/ux/page-email-verification">page-email-verification</Link>
        <br />
        <Link to="/ux/page-login">page-login</Link>
        <br />
        <Link to="/ux/page-recoverpw">page-recoverpw</Link>
        <br />
        <Link to="/ux/page-register">page-register</Link>
        <br />
        <Link to="/ux/page-two-step-verification">page-two-step-verification</Link>
        <br />
        <Link to="/ux/profile">profile</Link>

        <hr />

        <Link to="/ux/pages-maintenance">pages-maintenance</Link>
        <br />
        <Link to="/ux/pages-404">pages-404</Link>
        <br />
        <Link to="/ux/pages-500">pages-500</Link>

        <hr />

        <Link to="/ux/storages">storages</Link>
        <br />
        <Link to="/ux/storages/storage">storages/storage</Link>
        <br />
        <Link to="/ux/storages/package">storages/package</Link>
        <br />
        <Link to="/ux/packages/:packageId">packages/:packageId</Link>

        <hr />

        <Link to="/ux/dashboard">dashboard</Link>
        <br />
        <Link to="/ux/reports">reports</Link>
        <br />
        <Link to="/ux/reports/transporter">reports/transporter</Link>
        <br />
        <Link to="/ux/apps-calendar">apps-calendar</Link>
        <br />
        <Link to="/ux/apps-chat">apps-chat</Link>
        <br />
        <Link to="/ux/email-inbox">email-inbox</Link>
        <br />
        <Link to="/ux/email-read">email-read</Link>
        <br />
        <Link to="/ux/invoices-list">invoices-list</Link>
        <br />
        <Link to="/ux/invoices-detail">invoices-detail</Link>
        <br />
        <Link to="/ux/contacts-grid">contacts-grid</Link>
        <br />
        <Link to="/ux/contacts-list">contacts-list</Link>
        <br />
        <Link to="/ux/contacts-profile">contacts-profile</Link>
        <br />
        <Link to="/ux/pages-starter">pages-starter</Link>
        <br />
        <Link to="/ux/pages-timeline">pages-timeline</Link>
        <br />
        <Link to="/ux/pages-faqs">pages-faqs</Link>
        <br />
        <Link to="/ux/pages-pricing">pages-pricing</Link>

        <hr />

        <Link to="/ux/extended-lightbox">extended-lightbox</Link>
        <br />
        <Link to="/ux/extended-notifications">extended-notifications</Link>
        <br />
        <Link to="/ux/extended-rangeslider">extended-rangeslider</Link>
        <br />
        <Link to="/ux/extended-rating">extended-rating</Link>
        <br />
        <Link to="/ux/extended-session-timeout">extended-session-timeout</Link>
        <br />
        <Link to="/ux/extended-sweet-alert">extended-sweet-alert</Link>

        <hr />

        <Link to="/ux/ui-alerts">ui-alerts</Link>
        <br />
        <Link to="/ux/ui-buttons">ui-buttons</Link>
        <br />
        <Link to="/ux/ui-cards">ui-cards</Link>
        <br />
        <Link to="/ux/ui-carousel">ui-carousel</Link>
        <br />
        <Link to="/ux/ui-dropdowns">ui-dropdowns</Link>
        <br />
        <Link to="/ux/ui-grid">ui-grid</Link>
        <br />
        <Link to="/ux/ui-modals">ui-modals</Link>
        <br />
        <Link to="/ux/ui-images">ui-images</Link>
        <br />
        <Link to="/ux/ui-drawer">ui-drawer</Link>
        <br />
        <Link to="/ux/ui-progressbars">ui-progressbars</Link>
        <br />
        <Link to="/ux/ui-tabs-accordions">ui-tabs-accordions</Link>
        <br />
        <Link to="/ux/ui-typography">ui-typography</Link>
        <br />
        <Link to="/ux/ui-video">ui-video</Link>
        <br />
        <Link to="/ux/ui-general">ui-general</Link>
        <br />
        <Link to="/ux/ui-colors">ui-colors</Link>

        <hr />

        <Link to="/ux/form-elements">form-elements</Link>
        <br />
        <Link to="/ux/form-validation">form-validation</Link>
        <br />
        <Link to="/ux/form-advanced">form-advanced</Link>
        <br />
        <Link to="/ux/form-editors">form-editors</Link>
        <br />
        <Link to="/ux/form-uploads">form-uploads</Link>
        <br />
        <Link to="/ux/form-wizard">form-wizard</Link>
        <br />
        <Link to="/ux/form-mask">form-mask</Link>

        <hr />

        <Link to="/ux/tables-basic">tables-basic</Link>
        <br />
        <Link to="/ux/tables-datatable">tables-datatable</Link>
        <br />
        <Link to="/ux/tables-responsive">tables-responsive</Link>
        <br />
        <Link to="/ux/tables-editable">tables-editable</Link>

        <hr />

        <Link to="/ux/charts-apex">charts-apex</Link>
        <br />
        <Link to="/ux/charts-echart">charts-echart</Link>
        <br />
        <Link to="/ux/charts-chartjs">charts-chartjs</Link>
        <br />
        <Link to="/ux/charts-sparkline">charts-sparkline</Link>

        <hr />

        <Link to="/ux/icons-boxicons">icons-boxicons</Link>
        <br />
        <Link to="/ux/icons-materialdesign">icons-materialdesign</Link>
        <br />
        <Link to="/ux/icons-dripicons">icons-dripicons</Link>
        <br />
        <Link to="/ux/icons-fontawesome">icons-fontawesome</Link>

        <hr />

        <Link to="/ux/maps-google">maps-google</Link>
        <br />
        <Link to="/ux/maps-vector">maps-vector</Link>
        <br />
        <Link to="/ux/maps-leaflet">maps-leaflet</Link>
        <hr />
      </Container>
    </div>
  );
};

export default ListAllUxPages;
