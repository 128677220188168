import React, { ChangeEventHandler } from 'react';
import classnames from 'classnames';

import Label from './Label';
import Errors, { ErrorsType } from './Errors';

export interface SelectProps {
  label?: string;
  selectOptions: (SelectOption | SelectOptGroup)[];
  defaultValue?: string;
  disabled?: boolean;
  name?: string;
  type?: string;
  onChange?: ChangeEventHandler;
  errors: ErrorsType;
  ref: any;
}

export interface SelectOption {
  value: string;
  text: string;
  type: 'option';
}

export interface SelectOptGroup {
  label: string;
  items: SelectOption[];
  type: 'optgroup';
}

export const toOption = (value: string, text: string) => {
  const option: SelectOption = {
    value,
    text,
    type: 'option',
  };
  return option;
};

export const toOptGroup = (label: string, items: SelectOption[]) => {
  const optGroup: SelectOptGroup = {
    label,
    items,
    type: 'optgroup',
  };
  return optGroup;
};

const renderOptionElement = (option: SelectOption) => (
  <option value={option.value} key={option.value} disabled={option.value === ''}>
    {option.text}
  </option>
);

const renderOptGroupElement = (optGroup: SelectOptGroup) => (
  <optgroup label={optGroup.label} key={optGroup.label}>
    {optGroup.items.map((option) => renderOptionElement(option))}
  </optgroup>
);

export const renderSelectOptions = (selectOptions: (SelectOption | SelectOptGroup)[]) =>
  selectOptions.map((option) => {
    if (option.type === 'option') {
      return renderOptionElement(option);
    } else if (option.type === 'optgroup') {
      return renderOptGroupElement(option);
    }
  });

const Select: React.FC<SelectProps> = React.forwardRef(
  ({ label, defaultValue, selectOptions, errors, name, disabled, ...rest }, ref) => {
    const inputClasses = classnames('form-select', {
      'is-invalid': !!errors,
    });

    return (
      <div className="mb-3">
        <Label htmlFor={`formrow-${name}-input`} label={label} />
        <select
          className={inputClasses}
          id={`formrow-${name}-input`}
          name={name}
          defaultValue={defaultValue}
          disabled={disabled}
          {...rest}
          ref={ref as React.MutableRefObject<HTMLSelectElement>}
        >
          {renderSelectOptions(selectOptions)}
        </select>

        <Errors errors={errors} />
      </div>
    );
  },
);

export default Select;
