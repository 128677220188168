import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  Badge,
  Table,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
} from 'reactstrap';

export interface PackageListProps {
  title?: string;
}

const PackageList: React.FC<PackageListProps> = ({ title }) => {
  const history = useHistory();

  const onNavigateToPackageDetails = (ev: React.SyntheticEvent) => {
    history.push('/ux/storages/package');
  };

  return (
    <React.Fragment>
      <Card>
        <CardHeader>
          <CardTitle>{title}</CardTitle>
        </CardHeader>
        <CardBody>
          <div className="table-responsive">
            <Table className="table mb-0 align-middle">
              <thead>
                <tr>
                  <th>Status</th>
                  <th>Package ID</th>
                  <th>User ID</th>
                  <th>Name</th>
                  <th>Updated</th>
                </tr>
              </thead>
              <tbody>
                <tr onClick={onNavigateToPackageDetails}>
                  <td>
                    <Badge className="me-2 bg-dark">Created</Badge>
                  </td>
                  <td>12345678</td>
                  <td>12345678</td>
                  <td>Package Name</td>
                  <td>01.01.21 @ 09:11 AM</td>
                  <td align="right">
                    <div>
                      <UncontrolledDropdown>
                        <DropdownToggle className="btn btn-soft-light btn-sm" tag="a">
                          <i className="mdi mdi-dots-horizontal"></i>
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-end">
                          <li>
                            <DropdownItem to="#">Action 1</DropdownItem>
                          </li>
                          <li>
                            <DropdownItem to="#">Action 2</DropdownItem>
                          </li>
                          <li>
                            <DropdownItem to="#">Action 3</DropdownItem>
                          </li>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </td>
                </tr>
                <tr onClick={onNavigateToPackageDetails}>
                  <td>
                    <Badge className="me-2 bg-success">Scheduled</Badge>
                  </td>
                  <td>12345678</td>
                  <td>12345678</td>
                  <td>Package Name</td>
                  <td>01.01.21 @ 09:11 AM</td>
                  <td align="right">
                    <div>
                      <UncontrolledDropdown>
                        <DropdownToggle className="btn btn-soft-light btn-sm" tag="a">
                          <i className="mdi mdi-dots-horizontal"></i>
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-end">
                          <li>
                            <DropdownItem to="#">Action 1</DropdownItem>
                          </li>
                          <li>
                            <DropdownItem to="#">Action 2</DropdownItem>
                          </li>
                          <li>
                            <DropdownItem to="#">Action 3</DropdownItem>
                          </li>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </td>
                </tr>
                <tr onClick={onNavigateToPackageDetails}>
                  <td>
                    <Badge className="me-2 bg-success">Scheduled</Badge>
                  </td>
                  <td>12345678</td>
                  <td>12345678</td>
                  <td>Package Name</td>
                  <td>01.01.21 @ 09:11 AM</td>
                  <td align="right">
                    <div>
                      <UncontrolledDropdown>
                        <DropdownToggle className="btn btn-soft-light btn-sm" tag="a">
                          <i className="mdi mdi-dots-horizontal"></i>
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-end">
                          <li>
                            <DropdownItem to="#">Action 1</DropdownItem>
                          </li>
                          <li>
                            <DropdownItem to="#">Action 2</DropdownItem>
                          </li>
                          <li>
                            <DropdownItem to="#">Action 3</DropdownItem>
                          </li>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </td>
                </tr>
                <tr onClick={onNavigateToPackageDetails}>
                  <td>
                    <Badge className="me-2 bg-success">Scheduled</Badge>
                  </td>
                  <td>12345678</td>
                  <td>12345678</td>
                  <td>Package Name</td>
                  <td>01.01.21 @ 09:11 AM</td>
                  <td align="right">
                    <div>
                      <UncontrolledDropdown>
                        <DropdownToggle className="btn btn-soft-light btn-sm" tag="a">
                          <i className="mdi mdi-dots-horizontal"></i>
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-end">
                          <li>
                            <DropdownItem to="#">Action 1</DropdownItem>
                          </li>
                          <li>
                            <DropdownItem to="#">Action 2</DropdownItem>
                          </li>
                          <li>
                            <DropdownItem to="#">Action 3</DropdownItem>
                          </li>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </td>
                </tr>
                <tr onClick={onNavigateToPackageDetails}>
                  <td>
                    <Badge className="me-2 bg-warning">Canceled</Badge>
                  </td>
                  <td>12345678</td>
                  <td>12345678</td>
                  <td>Package Name</td>
                  <td>01.01.21 @ 09:11 AM</td>
                  <td align="right">
                    <div>
                      <UncontrolledDropdown>
                        <DropdownToggle className="btn btn-soft-light btn-sm" tag="a">
                          <i className="mdi mdi-dots-horizontal"></i>
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-end">
                          <li>
                            <DropdownItem to="#">Action 1</DropdownItem>
                          </li>
                          <li>
                            <DropdownItem to="#">Action 2</DropdownItem>
                          </li>
                          <li>
                            <DropdownItem to="#">Action 3</DropdownItem>
                          </li>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </td>
                </tr>
                <tr onClick={onNavigateToPackageDetails}>
                  <td>
                    <Badge className="me-2 bg-danger">Missed</Badge>
                  </td>
                  <td>12345678</td>
                  <td>12345678</td>
                  <td>Package Name</td>
                  <td>01.01.21 @ 09:11 AM</td>
                  <td align="right">
                    <div>
                      <UncontrolledDropdown>
                        <DropdownToggle className="btn btn-soft-light btn-sm" tag="a">
                          <i className="mdi mdi-dots-horizontal"></i>
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-end">
                          <li>
                            <DropdownItem to="#">Action 1</DropdownItem>
                          </li>
                          <li>
                            <DropdownItem to="#">Action 2</DropdownItem>
                          </li>
                          <li>
                            <DropdownItem to="#">Action 3</DropdownItem>
                          </li>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};
export default PackageList;
