import React, { useState } from 'react';
import ReactDrawer from 'react-drawer';
import 'react-drawer/lib/react-drawer.css';

import { Link } from 'react-router-dom';

// Redux Store
import { toggleLeftmenu } from 'src/store/actions';

// Import menuDropdown
import ProfileMenu from './ProfileMenu';
import RightSidebar from './RightSidebar';
import LightDarkMenu from './LightDarkMenu';
import SearchById from './SearchById';

// import images
import logo from 'src/assets/images/logo-sm.svg';

//redux
import { useSelector, useDispatch } from 'react-redux';

const Header = (props: any) => {
  const dispatch = useDispatch();
  const { leftMenu, layoutMode } = useSelector((state: any) => ({
    leftMenu: state.Layout.leftMenu,
    layoutMode: state.Layout.layoutMode,
  }));

  const [isSearch, setSearch] = useState<boolean>(false);
  const [position, setPosition] = useState<any>();
  const [open, setOpen] = useState<boolean>(false);

  const toggleTopDrawer = () => {
    setPosition('right');
    setOpen(!open);
  };

  const onDrawerClose = () => {
    setOpen(false);
  };
  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box">
              <Link to="/home" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logo} alt="" height="24" />
                </span>
                <span className="logo-lg">
                  <img src={logo} alt="" height="24" />
                  <span className="logo-txt">Admin</span>
                </span>
              </Link>

              <Link to="/home" className="logo logo-light">
                <span className="logo-sm">
                  <img src={logo} alt="" height="24" />
                </span>
                <span className="logo-lg">
                  <img src={logo} alt="" height="24" />
                  <span className="logo-txt">Admin</span>
                </span>
              </Link>
            </div>

            <button
              type="button"
              className="btn btn-sm px-3 font-size-16 d-lg-none header-item"
              data-toggle="collapse"
              onClick={() => {
                dispatch(toggleLeftmenu(!leftMenu));
              }}
              data-target="#topnav-menu-content"
            >
              <i className="fa fa-fw fa-bars" />
            </button>

            <SearchById />
          </div>

          <div className="d-flex">
            <div className="dropdown d-inline-block d-lg-none ms-2">
              <button
                type="button"
                className="btn header-item noti-icon "
                id="page-header-search-dropdown"
                onClick={() => setSearch(!isSearch)}
              >
                <i className="mdi mdi-magnify" />
              </button>
              <div
                className={
                  isSearch
                    ? 'dropdown-menu dropdown-menu-lg dropdown-menu-end p-0 show'
                    : 'dropdown-menu dropdown-menu-lg dropdown-menu-end p-0'
                }
                aria-labelledby="page-header-search-dropdown"
              >
                <form className="p-3">
                  <div className="form-group m-0">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search ..."
                        aria-label="Recipient's username"
                      />
                      <div className="input-group-append">
                        <button className="btn btn-primary" type="submit">
                          <i className="mdi mdi-magnify" />
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            {/* light / dark mode */}
            <LightDarkMenu layoutMode={layoutMode} onChangeLayoutMode={props.onChangeLayoutMode} />

            <ProfileMenu />
          </div>
        </div>
      </header>
      <ReactDrawer open={open} position={position} onClose={onDrawerClose}>
        <RightSidebar onClose={onDrawerClose} onChangeLayoutMode={props.onChangeLayoutMode} />
      </ReactDrawer>
    </React.Fragment>
  );
};

export default Header;
