import React from 'react';
import { FieldError } from 'react-hook-form';

export type ErrorsType = undefined | FieldError | string | string[];

export interface TextInputProps {
  errors: ErrorsType;
}

const renderErrors = (errors: string[]) => {
  return (
    <>
      {errors.map((error, ix) => (
        <div className="invalid-feedback" key={ix}>
          {error}
        </div>
      ))}
    </>
  );
};

const TextInput: React.FC<TextInputProps> = ({ errors }) => {
  if (!errors) {
    return null;
  }

  if (Array.isArray(errors)) {
    return renderErrors(errors);
  }

  const rhfError = errors as FieldError;
  if (rhfError.message !== undefined && rhfError.type !== undefined) {
    if (rhfError.message) {
      return renderErrors([rhfError.message]);
    }

    if (rhfError.type === 'required') {
      return renderErrors(['Required field!']);
    }

    return renderErrors(['Error!']);
  }

  return renderErrors([errors as string]);
};

export default TextInput;
