import Storage, { StorageTypes } from './storage';

export const localStorage = new Storage(StorageTypes.localStorage, {
  defaults: {},
});

export const sessionStorage = new Storage(StorageTypes.sessionStorage);

// sessionStorage by default, localStorage as fallback
export const tabStorage = {
  setItem: (key: string, val: any, forced = false) => {
    sessionStorage.setItem(key, val);
    if (forced || typeof localStorage.getItem(key) === 'undefined' || localStorage.getItem(key) === null) {
      localStorage.setItem(key, val);
    }
  },

  getItem: (key: string) => {
    const val = sessionStorage.getItem(key);
    if (typeof val !== 'undefined') {
      return val;
    }

    return localStorage.getItem(key);
  },

  removeItem: (key: string, forced = false) => {
    sessionStorage.removeItem(key);
    if (forced) {
      localStorage.removeItem(key);
    }
  },

  clear: (forced = false) => {
    sessionStorage.clear();
    if (forced) {
      localStorage.clear();
    }
  },
};
