import React from 'react';
import Icon from '@ailibs/feather-react-ts';
import _intersection from 'lodash/intersection';
import _reduce from 'lodash/reduce';

import { DeliveryRequestStatus } from 'src/types/types';
import { getActionsForDeliveryRequest, canBeApplied } from 'src/helpers/deliveryRequests';

interface BulkActionsProps {
  tasks: (DeliveryRequestListItem | DeliveryRequestDetails)[];
  changeDeliveryRequestStatus: Function;
  userContext: UserContext;
}

const BulkActions: React.FC<BulkActionsProps> = ({ tasks, userContext, changeDeliveryRequestStatus }) => {
  if (tasks.length === 0) {
    return <></>;
  }

  const statuses = _reduce(
    tasks,
    (result: DeliveryRequestStatus[], currentDeliveryRequest, key) => {
      return _intersection(result, getActionsForDeliveryRequest(currentDeliveryRequest));
    },
    getActionsForDeliveryRequest(tasks[0]),
  );

  if (statuses.length === 0) {
    return <></>;
  }

  const actions = [];

  if (
    canBeApplied(DeliveryRequestStatus.SCHEDULED, statuses, userContext, {
      forBulkActions: true,
    })
  ) {
    actions.push(
      <button
        type="button"
        className="btn btn-soft-light"
        key="schedule"
        title="Schedule"
        onClick={(ev) => changeDeliveryRequestStatus(ev, tasks, DeliveryRequestStatus.SCHEDULED)}
      >
        <i className={`me-1 mdi mdi-calendar-clock`}></i>
        Schedule
      </button>,
    );
  }

  if (
    canBeApplied(DeliveryRequestStatus.AT_TRANSPORTER, statuses, userContext, {
      forBulkActions: true,
    })
  ) {
    actions.push(
      <button
        type="button"
        className="btn btn-soft-light"
        key="transporter"
        title="Assign to transporter"
        onClick={(ev) => changeDeliveryRequestStatus(ev, tasks, DeliveryRequestStatus.AT_TRANSPORTER)}
      >
        <i className={`me-1 mdi mdi-truck`}></i>
        Assign to transporter
      </button>,
    );
  }

  if (
    canBeApplied(DeliveryRequestStatus.DELIVERED, statuses, userContext, {
      forBulkActions: true,
    })
  ) {
    actions.push(
      <button
        type="button"
        className="btn btn-soft-light"
        key="delivered"
        title="Delivered"
        onClick={(ev) => changeDeliveryRequestStatus(ev, tasks, DeliveryRequestStatus.DELIVERED)}
      >
        <Icon name="grid" size="16" className={`me-1`} />
        Delivered
      </button>,
    );
  }

  if (
    canBeApplied(DeliveryRequestStatus.MISSED, statuses, userContext, {
      forBulkActions: true,
    })
  ) {
    actions.push(
      <button
        type="button"
        className="btn btn-soft-light"
        key="missed"
        title="Missed"
        onClick={(ev) => changeDeliveryRequestStatus(ev, tasks, DeliveryRequestStatus.MISSED)}
      >
        <i className={`me-1 mdi mdi-call-missed`}></i>
        Missed
      </button>,
    );
  }

  if (
    canBeApplied(DeliveryRequestStatus.CANCELLED, statuses, userContext, {
      forBulkActions: true,
    })
  ) {
    actions.push(
      <button
        type="button"
        className="btn btn-soft-light"
        key="cancel"
        title="Cancel"
        onClick={(ev) => changeDeliveryRequestStatus(ev, tasks, DeliveryRequestStatus.CANCELLED)}
      >
        <i className={`me-1 mdi mdi-cancel`}></i>
        Cancel
      </button>,
    );
  }

  return <>{actions}</>;
};

export default BulkActions;
