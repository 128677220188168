import React from 'react';
import { DropdownItem, UncontrolledDropdown, DropdownMenu, DropdownToggle } from 'reactstrap';

import { DeliveryRequestStatus } from 'src/types/types';
import { getActionsForDeliveryRequest, canBeApplied } from 'src/helpers/deliveryRequests';

interface RowActionsProps {
  tasks: (DeliveryRequestListItem | DeliveryRequestDetails)[];
  changeDeliveryRequestStatus: Function;
  userContext: UserContext;
}

const RowActions: React.FC<RowActionsProps> = ({ tasks, userContext, changeDeliveryRequestStatus }) => {
  const actions = [];
  const availableActions = getActionsForDeliveryRequest(tasks[0]);

  if (canBeApplied(DeliveryRequestStatus.SCHEDULED, availableActions, userContext)) {
    actions.push(
      <li key="SCHEDULED">
        <DropdownItem onClick={(ev) => changeDeliveryRequestStatus(ev, tasks, DeliveryRequestStatus.SCHEDULED)}>
          Schedule
        </DropdownItem>
      </li>,
    );
  }

  if (canBeApplied(DeliveryRequestStatus.AT_TRANSPORTER, availableActions, userContext)) {
    actions.push(
      <li key="AT_TRANSPORTER">
        <DropdownItem onClick={(ev) => changeDeliveryRequestStatus(ev, tasks, DeliveryRequestStatus.AT_TRANSPORTER)}>
          Assign to transporter
        </DropdownItem>
      </li>,
    );
  }

  if (canBeApplied(DeliveryRequestStatus.DELIVERED, availableActions, userContext)) {
    actions.push(
      <li key="DELIVERED">
        <DropdownItem onClick={(ev) => changeDeliveryRequestStatus(ev, tasks, DeliveryRequestStatus.DELIVERED)}>
          Delivered
        </DropdownItem>
      </li>,
    );
  }

  if (canBeApplied(DeliveryRequestStatus.MISSED, availableActions, userContext)) {
    actions.push(
      <li key="MISSED">
        <DropdownItem onClick={(ev) => changeDeliveryRequestStatus(ev, tasks, DeliveryRequestStatus.MISSED)}>
          Missed
        </DropdownItem>
      </li>,
    );
  }

  if (canBeApplied(DeliveryRequestStatus.CANCELLED, availableActions, userContext)) {
    actions.push(
      <li key="CANCELLED">
        <DropdownItem onClick={(ev) => changeDeliveryRequestStatus(ev, tasks, DeliveryRequestStatus.CANCELLED)}>
          Cancel
        </DropdownItem>
      </li>,
    );
  }

  if (actions.length === 0) {
    return <>{actions}</>;
  }

  return (
    <UncontrolledDropdown onClick={(ev) => ev.stopPropagation()}>
      <DropdownToggle tag="button" className="btn btn-soft-light">
        ...
      </DropdownToggle>
      <DropdownMenu end>{actions}</DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default RowActions;
