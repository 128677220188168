import React, { useState, useEffect } from 'react';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { useMutation, ApolloError } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ALL_BLACKLISTED_PACKAGE_TAGS, UPDATE_TAG } from 'src/helpers/graphql';
import ModalForm, { setFieldErrors } from 'src/components/Forms/ModalForm';
import TagSelector from 'src/components/Forms/TagSelector';
import { ReactSelectOption } from 'src/components/Forms/ReactSelect';
import { PackageTagSource } from 'src/types/types';

type Inputs = {
  tagIds: { label: string; value: string; categoryIds: string[] }[];
};

type TagBlacklistDialogProps = {
  show: boolean;
  setShow: (arg0: boolean) => void;
  categoryId?: string;
  defaultValues?: ReactSelectOption[];
};

const TagBlacklistDialog: React.FC<TagBlacklistDialogProps> = ({ show, setShow }) => {
  const { t } = useTranslation();

  const {
    handleSubmit,
    setError,
    reset,
    control,
    formState: { errors },
  } = useForm<Inputs>();

  const [globalError, setGlobalError] = useState<string | ApolloError | undefined>('');
  const [updateTag, { loading, error }] = useMutation(UPDATE_TAG, {
    refetchQueries: [{ query: ALL_BLACKLISTED_PACKAGE_TAGS }],
  });

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      const updatedTags = data.tagIds.map((tag) =>
        updateTag({ variables: { id: tag.value, categoryIds: tag.categoryIds || [], isBlacklisted: true } }),
      );
      await Promise.all(updatedTags);
      setShow(false);
    } catch (err) {
      setFieldErrors(err, setError, setGlobalError);
    }
  };

  useEffect(() => {
    setFieldErrors(error, setError, setGlobalError);
  }, [error, setError]);

  useEffect(() => {
    reset();
  }, [reset]);

  return (
    <ModalForm
      title={t('Blacklist.add.title')}
      show={show}
      setShow={setShow}
      onSubmit={handleSubmit(onSubmit)}
      loading={loading}
      globalError={globalError}
      submitText="Add"
      cancelText="Cancel"
      size="me"
    >
      <p>{t('Blacklist.add.description')}</p>

      <Field>
        <Controller
          name="tagIds"
          control={control}
          render={({ field }) => (
            <TagSelector
              label="Tags"
              disabled={loading}
              isMulti={true}
              errors={errors.tagIds as unknown as string}
              source={PackageTagSource.AI}
              {...field}
            />
          )}
        />
      </Field>
    </ModalForm>
  );
};

export default TagBlacklistDialog;

const SearchField = styled.div`
  &.me-3 {
    width: 100%;
  }
  .mb-3 {
    display: flex;
    align-items: center;
  }
  .form-label {
    margin-right: 4px;
    margin-bottom: 0;
    white-space: nowrap;
    &:after {
      content: ':';
    }
  }
`;

const Field = ({ children, ...rest }: { children: React.ReactNode }) => (
  <SearchField className="float-start me-3" {...rest}>
    {children}
  </SearchField>
);
