import React from 'react';
import MetaTags from 'react-meta-tags';
import { Link } from 'react-router-dom';
import {
  Col,
  Container,
  Row,
  Table,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
} from 'reactstrap';
import Breadcrumbs from 'src/ux/components/Breadcrumb';

const TransporterReport = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Transporter Report | Add + Space Admin</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Reports" breadcrumbItem="Transporter"></Breadcrumbs>
          <Row>
            <div className="d-flex justify-content-between">
              <div>
                <h1>Transporter reports</h1>
              </div>

              <div>
                <Link to="#" className="btn btn-soft-light" onClick={() => {}}>
                  <i className="mdi mdi-download me-1"></i> CSV
                </Link>
              </div>
            </div>
          </Row>
          <Row className="mb-3">
            <div className="d-flex flex-wrap gap-2">
              <div>
                <UncontrolledDropdown>
                  <DropdownToggle className="btn btn-soft-light" tag="a">
                    <i className="mdi mdi-account" /> All transporters
                    <i className="mdi mdi-chevron-down" />
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-start">
                    <li>
                      <DropdownItem to="#">Transporter 1</DropdownItem>
                    </li>
                    <li>
                      <DropdownItem to="#">Transporter 2</DropdownItem>
                    </li>
                    <li>
                      <DropdownItem to="#">Transporter n</DropdownItem>
                    </li>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
              <div>
                <UncontrolledDropdown>
                  <DropdownToggle className="btn btn-soft-light" tag="a">
                    <i className="mdi mdi-calendar" /> All months
                    <i className="mdi mdi-chevron-down" />
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-start">
                    <li>
                      <DropdownItem to="#">Month 1</DropdownItem>
                    </li>
                    <li>
                      <DropdownItem to="#">Month 2</DropdownItem>
                    </li>
                    <li>
                      <DropdownItem to="#">Month n</DropdownItem>
                    </li>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </div>
          </Row>
          <Row>
            <Col lg="12">
              <h2>Results</h2>
              <Table>
                <thead>
                  <th>Transporter</th>
                  <th>Pickup at</th>
                  <th>Deliver to</th>
                  <th>Scheduled</th>
                  <th>Completed</th>
                  <th># Boxes</th>
                </thead>
                <tbody>
                  <td>Transporter Name</td>
                  <td>Pickup address</td>
                  <td>Deliver to address</td>
                  <td>Scheduled time</td>
                  <td>Completed time</td>
                  <td>12</td>
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default TransporterReport;
