import React, { useContext } from 'react';
import { useQuery } from '@apollo/client';
import { Spinner } from 'reactstrap';
import classnames from 'classnames';

import { ALL_PAYMENT_PRODUCTS, USER_PAYMENT_METHODS, USER_PAYMENT_INTENTS } from 'src/helpers/graphql';
import { SubscriptionPaymentStatus } from 'src/types/types';
import { getActiveSubscription, generateSubscriptionText } from 'src/helpers/payment/paymentUtils';
import { UserPageContext } from '../UserDetails';
import CreditCardStatus from './CreditCardStatus';
import PlanCard from './PlanCard';
import TransactionList from './TransactionList';

const EXPIRES_WARNING_DAYS = 60;

interface SubscriptionProps {
  subscriptions: SubscriptionInfo[];
}

const Subscription: React.FC<SubscriptionProps> = ({ subscriptions }) => {
  const { user } = useContext(UserPageContext);

  const allPaymentProductsQuery = useQuery(ALL_PAYMENT_PRODUCTS);
  const userPaymentIntentsQuery = useQuery(USER_PAYMENT_INTENTS, { variables: { userId: user.id } });
  const userPaymentMethodsQuery = useQuery(USER_PAYMENT_METHODS, { variables: { userId: user.id } });

  if (allPaymentProductsQuery.loading || userPaymentIntentsQuery.loading || userPaymentMethodsQuery.loading) {
    return <Spinner className="spinner--with-space" color="secondary" />;
  }

  const allPaymentProducts = allPaymentProductsQuery?.data.allPaymentProducts as PaymentProduct[];
  const userPaymentIntents = userPaymentIntentsQuery?.data.userPaymentIntents as PaymentIntent[];
  const userPaymentMethods = userPaymentMethodsQuery?.data.userPaymentMethods as PaymentMethod[];

  console.log({
    allPaymentProducts,
    userPaymentMethods,
    userPaymentIntents,
    subscriptions,
  });

  const activeSubscription = getActiveSubscription(subscriptions)!;

  const generatePlanCard = (subscription?: SubscriptionInfo) => {
    if (!subscription) {
      return null;
    }

    const classList = classnames('text-white-50', {
      'bg-primary': subscription.paymentStatus === SubscriptionPaymentStatus.ACTIVE,
      'bg-danger':
        subscription.paymentStatus === SubscriptionPaymentStatus.CANCELED ||
        subscription.paymentStatus === SubscriptionPaymentStatus.FAILED,
      'bg-warning':
        subscription.paymentStatus === SubscriptionPaymentStatus.REQUIRES_ACTION ||
        subscription.paymentStatus === SubscriptionPaymentStatus.REQUIRES_PAYMENT_METHOD ||
        subscription.paymentStatus === SubscriptionPaymentStatus.WAITING_FOR_COMPLETION,
    });

    return (
      <PlanCard
        planTitle={subscription.plan.name!}
        planDetails={generateSubscriptionText(subscription)}
        styleNames={classList}
      />
    );
  };

  const generateCreditCardStatus = (userPaymentMethod: PaymentMethod) => {
    const expiresAt = new Date(`${userPaymentMethod.expYear}-${userPaymentMethod.expMonth}-01`);

    let classList = classnames('bg-success border-success');
    let title = 'Valid';

    // check if date is less than two months from now
    if (expiresAt <= new Date()) {
      classList = classnames('bg-danger border-danger text-white');
      title = 'Expired';
    } else if (expiresAt < new Date(Date.now() + EXPIRES_WARNING_DAYS * 24 * 60 * 60 * 1000)) {
      classList = classnames('bg-warning border-warning');
      title = 'Expires soon';
    }

    return (
      <CreditCardStatus
        key={userPaymentMethod.id}
        title={title}
        styleNames={classList}
        cardDetails={`${userPaymentMethod.brand?.toUpperCase()} *${userPaymentMethod.last4}`}
      />
    );
  };

  return (
    <>
      {generatePlanCard(activeSubscription)}

      {userPaymentMethods.map((userPaymentMethod) => generateCreditCardStatus(userPaymentMethod))}

      <TransactionList paymentIntents={userPaymentIntents} allPaymentProducts={allPaymentProducts} />
    </>
  );
};

export default Subscription;
