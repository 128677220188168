import React, { useState, useEffect } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useMutation, ApolloError } from '@apollo/client';
import { Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { UPDATE_STORAGE } from 'src/helpers/graphql';
import { StorageStatus } from 'src/types/types';
import TextInput from 'src/components/Forms/TextInput';
import Select, { toOption } from 'src/components/Forms/Select';
import ModalForm, { setFieldErrors } from 'src/components/Forms/ModalForm';

type Inputs = {
  name: string;
  width: number;
  height: number;
  depth: number;
  standardBoxCapacity: number;
  volume: number;
  status: StorageStatus;
};

type EditStorageDialogProps = {
  show: boolean;
  setShow: Function;
  virtualStorage: VirtualStorageDetails;
};

const EditStorageDialog: React.FC<EditStorageDialogProps> = ({ show, setShow, virtualStorage }) => {
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors },
  } = useForm<Inputs>();

  const [globalError, setGlobalError] = useState<string | ApolloError | undefined>('');
  const [editStorage, editStorageMutation] = useMutation(UPDATE_STORAGE);

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    console.log('submitted', data);

    try {
      const editStorageResult = await editStorage({
        variables: {
          id: virtualStorage.id,
          name: data.name,
          width: parseFloat(`${data.width}`),
          height: parseFloat(`${data.height}`),
          depth: parseFloat(`${data.depth}`),
          standardBoxCapacity: parseFloat(`${data.standardBoxCapacity}`),
          volume: parseFloat(`${data.volume}`),
          type: data.status,
        },
      });

      setShow(false);
    } catch (err) {
      setFieldErrors(err, setError, setGlobalError);
    }
  };

  const getAvailableStatuses = () => {
    const availableStatuses = Object.keys(StorageStatus).filter((key) => key !== StorageStatus.ACTIVE);

    // ACTIVE only possible is the type was already ACTIVE. Otherwise the storage must be activated throught the
    // approve endpoints.
    if (virtualStorage.storage.type === StorageStatus.ACTIVE) {
      availableStatuses.push(StorageStatus.ACTIVE);
    }

    return availableStatuses.map((status) => toOption(status, t(`Storage.status.${status}`)));
  };

  useEffect(() => {
    reset();
  }, [reset]);

  const loading = editStorageMutation.loading;

  return (
    <ModalForm
      title={t('Storages.edit')}
      show={show}
      setShow={setShow}
      onSubmit={handleSubmit(onSubmit)}
      loading={loading}
      globalError={globalError}
    >
      <TextInput
        label={t('Common.name')}
        {...register('name', { required: true })}
        disabled={loading}
        errors={errors.name}
        defaultValue={virtualStorage.storage.name}
      />

      <Select
        label={t('Common.status')}
        selectOptions={getAvailableStatuses()}
        defaultValue={virtualStorage.storage.type}
        {...register('status', { required: true })}
        disabled={loading}
        errors={errors.status}
      />

      <Row>
        <Col md={4}>
          <TextInput
            label={t('StorageDetails.width')}
            {...register('width', { required: true })}
            disabled={loading}
            errors={errors.width}
            defaultValue={virtualStorage ? `${virtualStorage.storage.width}` : undefined}
            type="number"
          />
        </Col>
        <Col md={4}>
          <TextInput
            label={t('StorageDetails.height')}
            {...register('height', { required: true })}
            disabled={loading}
            errors={errors.height}
            defaultValue={virtualStorage ? `${virtualStorage.storage.height}` : undefined}
            type="number"
          />
        </Col>
        <Col md={4}>
          <TextInput
            label={t('StorageDetails.depth')}
            {...register('depth', { required: true })}
            disabled={loading}
            errors={errors.depth}
            defaultValue={virtualStorage ? `${virtualStorage.storage.depth}` : undefined}
            type="number"
          />
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <TextInput
            label={t('StorageDetails.capacity')}
            {...register('standardBoxCapacity', { required: true })}
            disabled={loading}
            errors={errors.standardBoxCapacity}
            defaultValue={virtualStorage ? `${virtualStorage.storage.standardBoxCapacity}` : undefined}
            type="number"
          />
        </Col>
        <Col md={6}>
          <TextInput
            label={t('StorageDetails.volume')}
            {...register('volume', { required: true })}
            disabled={loading}
            errors={errors.volume}
            defaultValue={virtualStorage ? `${virtualStorage.storage.volume}` : undefined}
            type="number"
          />
        </Col>
      </Row>
    </ModalForm>
  );
};

export default EditStorageDialog;
