import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import {
  Card,
  CardHeader,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

import { PACKAGE_BY_UNIQUE_ID, UNASSIGN_PACKAGE, PACKAGE_INFO_BY_PACKAGE_UNIQUE_ID } from 'src/helpers/graphql';
import { formatAddress } from 'src/helpers/ui';
import { ConfirmContext } from 'src/components/ConfirmContextSetter';
import UserAvatar from 'src/components/UserAvatar';

interface OwnerCardProps {
  packageData: Package;
  userId?: string;
  userName?: string;
  address?: FullAddress;
}
const OwnerCard: React.FC<OwnerCardProps> = ({ packageData, userId, userName, address }) => {
  const { t } = useTranslation();
  const confirmModal = useContext(ConfirmContext);

  const [unassignPackageMutation] = useMutation(UNASSIGN_PACKAGE, {
    refetchQueries: [
      { query: PACKAGE_BY_UNIQUE_ID, variables: { uniqueId: packageData.uniqueId } },
      { query: PACKAGE_INFO_BY_PACKAGE_UNIQUE_ID, variables: { uniqueId: packageData.uniqueId } },
    ],
  });

  const disassociate = () => {
    confirmModal.show({
      modalTitle: t('PackageDetails.disassociate'),
      modalText: t('PackageDetails.disassociate.confirm.text'),
      onConfirm: async () => {
        await unassignPackageMutation({ variables: { id: packageData.id } });
      },
    });
  };

  if (!userId) {
    return null;
  }

  return (
    <Card>
      <CardHeader className="align-items-center d-flex">
        <h4 className="card-title mb-0 flex-grow-1">
          <i className="mdi mdi-account-circle me-3"></i>
          {t('Common.owner')}
        </h4>

        <UncontrolledDropdown>
          <DropdownToggle className="btn btn-soft-light btn-sm" tag="a">
            <i className="mdi mdi-dots-horizontal label-icon" />
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-start">
            <li>
              <DropdownItem to="#" onClick={disassociate}>
                {t('PackageDetails.disassociate')}
              </DropdownItem>
            </li>
          </DropdownMenu>
        </UncontrolledDropdown>
      </CardHeader>
      <CardBody>
        <div className="avatar-xl mb-2">
          <UserAvatar className="img-fluid rounded-circle d-block" />
        </div>
        <h5>
          <a href={`/users/${userId}`}>{userName}</a> <br />
          {address ? formatAddress(address) : null}
        </h5>
      </CardBody>
    </Card>
  );
};

export default OwnerCard;
