import React from 'react';
import classnames from 'classnames';
import { Badge } from 'reactstrap';
import styled from 'styled-components';
import { t } from 'i18next';

import { DeliveryRequestDirection, DeliveryRequestStatus } from 'src/types/types';

type DeliveryRequestStatusBadgeProps = {
  status?: DeliveryRequestStatus;
  direction?: DeliveryRequestDirection;
  size?: 'md' | 'lg';
};

const DeliveryRequestStatusBadge: React.FC<DeliveryRequestStatusBadgeProps> = ({ status, direction, size = 'md' }) => {
  if (!status) {
    return null;
  }

  const classList = classnames(status, {
    rounded: size === 'md',
    'mb-2': size === 'lg',
    'bg-success text-dark': status === DeliveryRequestStatus.CREATED || status === DeliveryRequestStatus.SCHEDULED,
    'bg-dark': status === DeliveryRequestStatus.AT_TRANSPORTER || status === DeliveryRequestStatus.DELIVERED,
    'bg-danger': status === DeliveryRequestStatus.CANCELLED || status === DeliveryRequestStatus.MISSED,
  });

  const prefix = direction ? `${t(`DeliveryRequest.direction.${direction}`)}: ` : '';
  const text = `${prefix}${t(`DeliveryRequest.status.${status}`)}`;

  if (size === 'md') {
    return <StyledBadge className={classList}>{text}</StyledBadge>;
  }

  return <Badge className={classList}>{text}</Badge>;
};

export default DeliveryRequestStatusBadge;

const StyledBadge = styled(Badge)`
  margin-right: 5px;

  &:last-child {
    margin-right: 0;
  }
`;
