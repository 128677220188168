import React, { useEffect } from 'react';
import { Modal as BootstrapModal } from 'reactstrap';

export type ModalSizes = 'lg' | 'me';

type ModalProps = {
  show: boolean;
  setShow: Function;
  title: string;
  children: React.ReactNode;
  size?: ModalSizes;
};

const Modal: React.FC<ModalProps> = ({ show, setShow, title, children, size = 'lg' }) => {
  useEffect(() => {
    if (show) {
      document.body.classList.add('modal-open');
    }

    return () => {
      document.body.classList.remove('modal-open');
    };
  }, [show]);

  return (
    <BootstrapModal size={size} isOpen={show} toggle={(ev: React.MouseEvent) => setShow(!show)}>
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          {title}
        </h5>
        <button
          type="button"
          onClick={() => {
            setShow(false);
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      {children}
    </BootstrapModal>
  );
};

export default Modal;
