import React from 'react';

import HorizontalLayout from './HorizontalLayout/index';

interface AuthLayoutProps {
  children: React.ReactNode;
}

const AuthLayout: React.FC<AuthLayoutProps> = ({ children }) => {
  return <HorizontalLayout>{children}</HorizontalLayout>;
};

export default AuthLayout;
