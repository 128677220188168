import { UserContextType } from 'src/types/types';

export const isContextValidForAdminUI = (userContext: UserContext[], contextId: string) => {
  const validContexts = getValidContextsForAdminUI(userContext);
  return !!validContexts.find((context) => context.id === contextId);
};

export const getValidContextsForAdminUI = (userContexts: UserContext[]) => {
  return userContexts.filter(
    (context) => context.context === UserContextType.TRANSPORTER || context.context === UserContextType.BACKOFFICE,
  );
};

export const getUsersTransporterContext = (user: User) =>
  user.userContexts.find((context) => context.context === UserContextType.TRANSPORTER);

export const findPreferredContext = (userContexts: UserContext[]) => {
  const contexts = getValidContextsForAdminUI(userContexts);
  return contexts.find((context) => context.role === 'ADMIN') || contexts[0];
};
