import React, { useContext } from 'react';
import { Card, CardBody, CardHeader, CardTitle, Col } from 'reactstrap';
import SimpleBar from 'simplebar-react';
import classnames from 'classnames';
import { t } from 'i18next';

import config from 'src/config';
import { PaymentIntentStatus } from 'src/types/types';
import { formatToLocaleDate, formatSubscriptionPrice } from 'src/helpers/ui';
import { UserPageContext } from '../UserDetails';

export interface TransactionListProps {
  paymentIntents: PaymentIntent[];
  allPaymentProducts: PaymentProduct[];
}

const TransactionList: React.FC<TransactionListProps> = ({ paymentIntents, allPaymentProducts }) => {
  const { user } = useContext(UserPageContext);

  if (paymentIntents.length === 0) {
    return null;
  }

  const getEventText = (paymentIntent: PaymentIntent) => {
    const currentProduct = allPaymentProducts.find(
      (product) => product.id === paymentIntent.productId || product.id === paymentIntent.oneTimeProductId,
    );
    if (!currentProduct) {
      return null;
    }

    // eg.: Failed: Subscribed to plan
    const label = `${t(`Transaction.status.${paymentIntent.status}`)}${t(`Transaction.event.${currentProduct.type}`)} ${
      currentProduct.name
    }`;

    const classList = classnames('font-size-14 mb-1', {
      'text-danger': paymentIntent.status === PaymentIntentStatus.FAILED,
    });

    return <h5 className={classList}>{label}</h5>;
  };

  // console.log('paymentIntent', paymentIntents[0]);
  // paymentIntents[0].status = SUCCESS.
  // paymentIntents[0].productId  48d2e405-2338-4d30-9d0e-278d116bed46

  return (
    <React.Fragment>
      <Col>
        <Card>
          <CardHeader className="align-items-center d-flex">
            <CardTitle className="mb-0 flex-grow-1">Transactions</CardTitle>

            {user.stripeCustomerId ? (
              <a
                href={`${config.stripeDashboard}customers/${user.stripeCustomerId}`}
                target="_blank"
                rel="noreferrer"
                className="btn btn-soft-light btn-sm"
              >
                Stripe <i className="mdi mdi-open-in-new label-icon"></i>
              </a>
            ) : null}
          </CardHeader>

          <CardBody className="px-0">
            <SimpleBar className="table-responsive px-3" style={{ maxHeight: '500px' }}>
              <table className="table align-middle table-borderless">
                <tbody>
                  {paymentIntents.map((paymentIntent) => (
                    <tr key={paymentIntent.id}>
                      <td>
                        <div>
                          {getEventText(paymentIntent)}
                          <p className="text-muted mb-0 font-size-12">
                            {formatToLocaleDate(paymentIntent.createdAt, 'May 23, 2022')}
                          </p>
                        </div>
                      </td>
                      <td>
                        <div className="text-end">
                          <h5 className="font-size-14 text-muted mb-0">
                            {formatSubscriptionPrice(paymentIntent.currency, paymentIntent.amount)}
                          </h5>
                          <p className="text-muted mb-0 font-size-12">Amount</p>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </SimpleBar>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default TransactionList;
