import React from 'react';
import { Badge } from 'reactstrap';
import classnames from 'classnames';
import { t } from 'i18next';

import { PackageInfoStatus } from 'src/types/types';

type PackageInfoStatusBadgeProps = {
  status?: PackageInfoStatus;
};

const PackageInfoStatusBadge: React.FC<PackageInfoStatusBadgeProps> = ({ status }) => {
  if (!status) {
    return null;
  }

  const classList = classnames('me-2', {
    'bg-dark': status === PackageInfoStatus.CREATED,
    'bg-success': status === PackageInfoStatus.RETURNED || status === PackageInfoStatus.STORED,
    'bg-warning': status === PackageInfoStatus.WAITING_FOR_PICKUP || status === PackageInfoStatus.WAITING_FOR_RETURN,
    'bg-danger':
      status === PackageInfoStatus.PICKUP_CANCELLED ||
      status === PackageInfoStatus.PICKUP_MISSED ||
      status === PackageInfoStatus.RETURN_CANCELLED ||
      status === PackageInfoStatus.RETURN_MISSED,
  });

  return <Badge className={classList}>{t(`PackageInfo.status.${status}`)}</Badge>;
};

export default PackageInfoStatusBadge;
