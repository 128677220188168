import React from 'react';
import {} from 'reactstrap';

export interface TableActionsProps {}

const TableActions: React.FC<TableActionsProps> = ({}) => {
  return (
    <div className="d-flex flex-wrap gap-2">
      <button type="button" className="btn btn-soft-light">
        <i className="me-1 mdi mdi-merge"></i>Merge
      </button>
      <button type="button" className="btn btn-soft-light">
        <i className="me-1 mdi mdi-pencil"></i>Change status
      </button>
      <button type="button" className="btn btn-soft-light">
        <i className="me-1 mdi mdi-truck"></i>Assign
      </button>
    </div>
  );
};
export default TableActions;
