import React, { useState, useEffect } from 'react';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { useMutation, ApolloError } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ADD_PACKAGE_ITEM_TAG_TO_CATEGORY, ALL_CATEGORIES, SEARCH_TAGS } from 'src/helpers/graphql';
import ModalForm, { setFieldErrors } from 'src/components/Forms/ModalForm';
import TagSelector from 'src/components/Forms/TagSelector';

type Inputs = {
  tagIds: { label: string; value: string; categoryIds: string[] }[];
};

type AddTagToCategoryDialogProps = {
  category: Category;
  show: boolean;
  setShow: (arg0: boolean) => void;
};

const AddTagToCategoryDialog: React.FC<AddTagToCategoryDialogProps> = ({ category, show, setShow }) => {
  const { t } = useTranslation();

  const {
    handleSubmit,
    setError,
    reset,
    control,
    formState: { errors },
  } = useForm<Inputs>();

  const [globalError, setGlobalError] = useState<string | ApolloError | undefined>('');
  const [addTagsToCategory, { loading, error }] = useMutation(ADD_PACKAGE_ITEM_TAG_TO_CATEGORY, {
    refetchQueries: [
      { query: ALL_CATEGORIES },
      { query: SEARCH_TAGS, variables: { withoutCategory: true, includeBlacklist: false } },
    ],
  });

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      await addTagsToCategory({ variables: { tagIds: data.tagIds.map((tag) => tag.value), categoryId: category.id } });
      setShow(false);
    } catch (err) {
      setFieldErrors(err, setError, setGlobalError);
    }
  };

  useEffect(() => {
    setFieldErrors(error, setError, setGlobalError);
  }, [error, setError]);

  useEffect(() => {
    reset();
  }, [reset]);

  return (
    <ModalForm
      title={t('Categories.addTag.title')}
      show={show}
      setShow={setShow}
      onSubmit={handleSubmit(onSubmit)}
      loading={loading}
      globalError={globalError}
      submitText={t('Categories.addTag.addButton')}
      cancelText="Cancel"
      size="me"
    >
      <p>{t('Categories.addTag.description')}</p>

      <Field>
        <Controller
          name="tagIds"
          control={control}
          render={({ field }) => (
            <TagSelector
              label={t('Categories.addTag.label')}
              disabled={loading}
              isMulti={true}
              errors={errors.tagIds as unknown as string}
              {...field}
            />
          )}
        />
      </Field>
    </ModalForm>
  );
};

export default AddTagToCategoryDialog;

const SearchField = styled.div`
  &.me-3 {
    width: 100%;
  }
  .mb-3 {
    display: flex;
    align-items: center;
  }
  .form-label {
    margin-right: 4px;
    margin-bottom: 0;
    white-space: nowrap;
    &:after {
      content: ':';
    }
  }
`;

const Field = ({ children, ...rest }: { children: React.ReactNode }) => (
  <SearchField className="float-start me-3" {...rest}>
    {children}
  </SearchField>
);
