import React, { useState } from 'react';
import MetaTags from 'react-meta-tags';
import { Container } from 'reactstrap';
import { t } from 'i18next';

import { GET_COMPANY_USERS } from 'src/helpers/graphql';
import { UserContextType } from 'src/types/types';
import { NO_FILTER_VALUE } from 'src/helpers/pagination';
import Breadcrumbs from 'src/components/Breadcrumbs';
import EditUserDialog from './EditUser/EditUserDialog';
import UserContextBadge from 'src/components/User/UserBadges/UserContextBadge';
import { getDisplayName } from 'src/helpers/ui';
import FilteredTable, { DefaultSortedByType } from 'src/components/FilteredTable/FilteredTable';

const columns = [
  {
    dataField: 'name',
    text: 'Name',
    sort: true,
    formatter: (cell: any, row: User, rowIndex: number) => <div>{getDisplayName(row)}</div>,
  },
  {
    dataField: 'email',
    text: 'Email',
    sort: true,
  },
  {
    dataField: 'phoneNumber',
    text: 'Phone',
    sort: true,
  },
  {
    dataField: 'context',
    text: 'Context',
    sort: true,
    formatter: (cell: any, row: User, rowIndex: number) => (
      <div>
        {row.userContexts.map((context, ix) => (
          <UserContextBadge context={context.context} role={context.role} className="me-3" key={ix} />
        ))}
      </div>
    ),
  },
];

const defaultSortedBy: DefaultSortedByType[] = [
  {
    dataField: 'id',
    order: 'asc',
  },
];

const Users: React.FC<{}> = () => {
  const [show, setShow] = useState(false);
  const [users, setUsers] = useState<User[] | undefined>(undefined);

  /*
  const addUser = (ev: React.MouseEvent, user?: User) => {
    ev.stopPropagation();
    setShow(true);
  };
  */

  const wrapper = (children: React.ReactNode) => (
    <div className="page-content">
      <MetaTags>
        <title>Users | Add + Space Admin</title>
      </MetaTags>
      <Container fluid>
        <Breadcrumbs
          items={[
            { label: 'Users', path: '#' },
            { label: 'Users list', path: '#' },
          ]}
        />

        {children}
      </Container>
    </div>
  );

  const generateUrlForUser = (user: User) => `/users/${user.id}`;

  const setItems = (users: User[]) => {
    setUsers(users);
  };

  const filterProcessor = (filters: any) => {
    if (!filters.filters) {
      return {};
    }

    if (Array.isArray(filters.filters)) {
      return {
        filters: [
          { op: 'EQ', field: 'userContexts.context', values: filters.filters, relationField: 'User.userContexts' },
        ],
      };
    }

    return {
      filters: [
        { op: 'EQ', field: 'userContexts.context', values: [filters.filters], relationField: 'User.userContexts' },
      ],
    };
  };

  return (
    <>
      <FilteredTable
        items={users}
        setItems={setItems}
        getTableTitle={(itemsLength?: number) => `${itemsLength} active users`}
        query={GET_COMPANY_USERS}
        keyInResponse="allUsers"
        savedFiltersKey="users"
        wrapper={wrapper}
        columns={columns}
        defaultSortedBy={defaultSortedBy}
        filterOptions={[
          { value: NO_FILTER_VALUE, label: 'All' },
          { value: '', label: '---' },
          { value: UserContextType.SEARCHER, label: t(`User.context.${UserContextType.SEARCHER}`) },
          { value: UserContextType.PROVIDER, label: t(`User.context.${UserContextType.PROVIDER}`) },
          { value: UserContextType.TRANSPORTER, label: t(`User.context.${UserContextType.TRANSPORTER}`) },
          { value: UserContextType.BACKOFFICE, label: t(`User.context.${UserContextType.BACKOFFICE}`) },
        ]}
        defaultFilterValue={NO_FILTER_VALUE}
        filterProcessor={filterProcessor}
        /*
        titleActions={
          <Link to="#" className="btn btn-primary" onClick={addUser} data-toggle="modal" data-target="#myModal">
            <i className="mdi mdi-plus-thick me-1"></i> New user
          </Link>}
        */
        generateUrlForEntity={generateUrlForUser}
      />

      <EditUserDialog show={show} setShow={setShow} />
    </>
  );
};

export default Users;
