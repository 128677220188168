import React, { useState } from 'react';
import MetaTags from 'react-meta-tags';
import { useHistory, withRouter, Link } from 'react-router-dom';
import {
  Col,
  Container,
  Row,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Modal,
} from 'reactstrap';
//Import Breadcrumb
import Breadcrumbs from 'src/ux/components/Breadcrumb';
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  SizePerPageDropdownStandalone,
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import TableActions from 'src/ux/components/TableActions';

const Storages = () => {
  const history = useHistory();

  const storageColumns = [
    {
      dataField: 'id',
      text: 'ID',
      sort: true,
    },
    {
      dataField: 'status',
      text: 'Status',
      sort: true,
    },

    {
      dataField: 'owner',
      text: 'Owner',
      sort: true,
    },
    {
      dataField: 'location',
      text: 'Location',
      sort: true,
    },
    {
      dataField: 'volume',
      text: 'Volume',
      sort: true,
    },
    {
      dataField: 'packages',
      text: 'Packages',
      sort: true,
    },
    {
      dataField: 'actions',
      text: 'Actions',
      sort: true,
    },
  ];
  // Table Data
  const storageData = [
    {
      id: 12345678,
      status: 'Active',
      owner: 'Name',
      changedDate: '01.01.22 09:00 am',
      volume: '1000 qf',
      location: '123 Storage Street, NY',
      packages: '3123',
      actions: '',
    },
    {
      id: 12345679,
      status: 'Created',
      owner: 'Name',
      changedDate: '01.01.22 09:00 am',
      volume: '1000 qf',
      location: '123 Storage Street, NY',
      packages: '213',
      actions: '',
    },
    {
      id: 12345680,
      status: 'Under review',
      owner: 'Name',
      changedDate: '01.01.22 09:00 am',
      volume: '1000 qf',
      location: '123 Storage Street, NY',
      packages: '1242',
      actions: '',
    },
    {
      id: 12345681,
      status: 'Declined',
      owner: 'Name',
      changedDate: '01.01.22 09:00 am',
      volume: '1000 qf',
      location: '123 Storage Street, NY',
      packages: '134',
      actions: '',
    },
  ];
  const defaultSorted: any = [
    {
      dataField: 'id',
      order: 'asc',
    },
  ];

  const pageOptions: any = {
    sizePerPage: 10,
    totalSize: storageData.length, // replace later with size(customers),
    custom: true,
  };

  // Select All Button operation
  const selectRow: any = {
    mode: 'checkbox',
  };
  const [modal, setmodal] = useState(false);
  function removeBodyCss() {
    document.body.classList.add('no_padding');
  }
  function togModal() {
    setmodal(!modal);
    removeBodyCss();
  }

  const rowEvents = {
    onClick: (ev: React.SyntheticEvent) => {
      history.push('/ux/storages/storage');
    },
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Storages | Add + Space Admin</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Storages" breadcrumbItem="Storage list" />
          <Row className="align-items-start" style={{ minHeight: '3.5rem' }}>
            <Col lg="12">
              <div className="d-flex justify-content-between">
                <div>
                  <h1>4 storages</h1>
                </div>

                <div>
                  <Link
                    to="#"
                    className="btn btn-primary"
                    onClick={() => {
                      togModal();
                    }}
                    data-toggle="modal"
                    data-target="#myModal"
                  >
                    <i className="mdi mdi-plus-thick me-1"></i> New storage
                  </Link>
                </div>
              </div>
            </Col>
          </Row>

          {/* Render Breadcrumbs */}
          <Row>
            <Col lg="12">
              <PaginationProvider
                pagination={paginationFactory(pageOptions)}
                // keyField="id"
                // columns={orderListColumns}
                // data={users}
              >
                {({ paginationProps, paginationTableProps }) => (
                  <ToolkitProvider keyField="id" data={storageData} columns={storageColumns} bootstrap4 search>
                    {(toolkitProps) => (
                      <React.Fragment>
                        <Row className="mb-2">
                          <div className="d-flex flex-wrap gap-2">
                            <div>
                              <UncontrolledDropdown>
                                <DropdownToggle className="btn btn-soft-light" tag="a">
                                  <i className="mdi mdi-filter" /> All
                                  <i className="mdi mdi-chevron-down" />
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-start">
                                  <li>
                                    <DropdownItem to="#">Status 1</DropdownItem>
                                  </li>
                                  <li>
                                    <DropdownItem to="#">Status 2</DropdownItem>
                                  </li>
                                  <li>
                                    <DropdownItem to="#">Status 3</DropdownItem>
                                  </li>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </div>
                            <TableActions />
                          </div>
                        </Row>
                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                                selectRow={selectRow}
                                defaultSorted={defaultSorted}
                                classes={'table align-middle table-nowrap table-hover'}
                                rowEvents={rowEvents}
                                bordered={false}
                                striped={false}
                                // responsive
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row className="align-items-md-center mt-30">
                          <Col className="inner-custom-pagination d-flex">
                            <div className="d-inline">
                              <SizePerPageDropdownStandalone {...paginationProps} />
                            </div>
                            <div className="text-md-right ms-auto">
                              <PaginationListStandalone {...paginationProps} />
                            </div>
                          </Col>
                        </Row>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                )}
              </PaginationProvider>
            </Col>
          </Row>
        </Container>
      </div>
      <Modal
        size="lg"
        isOpen={modal}
        toggle={() => {
          togModal();
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            New storage
          </h5>
          <button
            type="button"
            onClick={() => {
              setmodal(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <h5>Form goes here</h5>
          <p>Lorem ipsum.</p>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              togModal();
            }}
            className="btn btn-soft-light "
            data-dismiss="modal"
          >
            Close
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => {
              togModal();
            }}
          >
            Save
          </button>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default withRouter(Storages);
