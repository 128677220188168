import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { useLazyQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, Row, Col, Badge } from 'reactstrap';

import { BatchType } from 'src/types/types';
import { ALL_BAGS_BY_BATCH_ID, ALL_PACKAGES_BY_BATCH_ID } from 'src/helpers/graphql';
import { formatToLocaleDate, getDisplayName, downloadFile } from 'src/helpers/ui';

export interface LabelCardProps {
  batch: Batch;
}

const LabelCard: React.FC<LabelCardProps> = ({ batch }) => {
  const { t } = useTranslation();
  const [downloadStarted, setDownloadStarted] = useState(false);

  const [allBagsByBatchId, allBagsByBatchIdResponse] = useLazyQuery(ALL_BAGS_BY_BATCH_ID, {
    variables: {
      batchId: batch.id,
    },
  });

  const [allPackagesByBatchId, allPackagesByBatchIdResponse] = useLazyQuery(ALL_PACKAGES_BY_BATCH_ID, {
    variables: {
      batchId: batch.id,
    },
  });

  const badgeClasses = classnames('me-2', {
    'bg-secondary': batch.type === BatchType.PACKAGE,
    'bg-primary': batch.type === BatchType.BAG,
  });

  const generateCSV = () => {
    setDownloadStarted(true);
    if (batch.type === BatchType.BAG) {
      allBagsByBatchId();
    } else {
      allPackagesByBatchId();
    }
  };

  useEffect(() => {
    const response = batch.type === BatchType.BAG ? allBagsByBatchIdResponse : allPackagesByBatchIdResponse;

    if (!response?.data || !downloadStarted) {
      return;
    }

    // #qr,uniqueId,type
    // https://app.add.space/package/qr/foU-zAI-GKY,foU-zAI-GKY,Package

    const data = ['#qr,uniqueId,type'];
    const items = response?.data[
      batch.type === BatchType.BAG ? 'allBagsByBatchId' : 'allPackagesByBatchId'
    ] as BatchItem[];
    items.forEach((item) => {
      data.push(
        `https://app.add.space/${batch.type.toLowerCase()}/qr/${item.uniqueId},${item.uniqueId},${t(
          `Batch.type.${batch.type}`,
        )}`,
      );
    });

    setDownloadStarted(false);
    downloadFile('qrcodes.csv', 'text/csv', data.join('\n'));
  }, [allBagsByBatchIdResponse, allPackagesByBatchIdResponse, batch.type, downloadStarted, t]);

  return (
    <React.Fragment>
      <Card className="bg-soft-light">
        <CardBody>
          <Row className="align-items-center">
            <Col lg="10">
              <h4>
                <Badge pill className={badgeClasses}>
                  {t(`Batch.type.${batch.type}`)}
                </Badge>
              </h4>
              {t('BatchList.generatedBy')} <a href={`/users/${batch.user.id}`}>{getDisplayName(batch.user)}</a>{' '}
              {formatToLocaleDate(batch.createdAt, '5.23.2021 - 11:06 AM')}
            </Col>
            <Col lg="2" className="">
              <div className="d-flex justify-content-end">
                <Link to="#" className="btn btn-soft-light" onClick={generateCSV}>
                  <i className="mdi mdi-download"></i>
                </Link>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default LabelCard;
