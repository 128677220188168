import React, { ChangeEventHandler } from 'react';
import classnames from 'classnames';

import Label from './Label';
import Errors, { ErrorsType } from './Errors';

export interface TextInputProps {
  label?: string;
  placeholder?: string;
  defaultValue?: string;
  wrapperClassName?: string;
  name?: string;
  type?: string;
  disabled?: boolean;
  onChange?: ChangeEventHandler;
  errors: ErrorsType;
  ref: any;
}

const TextInput: React.FC<TextInputProps> = React.forwardRef(
  ({ label, defaultValue, errors, name, wrapperClassName, ...rest }, ref) => {
    const inputClasses = classnames('form-control', {
      'is-invalid': !!errors,
    });

    return (
      <div className={wrapperClassName !== undefined ? wrapperClassName : 'mb-3'}>
        <Label htmlFor={`formrow-${name}-input`} label={label} />
        <input
          type="text"
          className={inputClasses}
          id={`formrow-${name}-input`}
          name={name}
          defaultValue={defaultValue}
          {...rest}
          ref={ref as React.MutableRefObject<HTMLInputElement>}
        />
        <Errors errors={errors} />
      </div>
    );
  },
);

export default TextInput;
