import React, { useState, useContext } from 'react';
import MetaTags from 'react-meta-tags';
import axios from 'axios';
import { StatusCodes } from 'http-status-codes';
import { Row, Col, Alert, Container } from 'reactstrap';
import { Link } from 'react-router-dom';
import { AvForm, AvField } from 'availity-reactstrap-validation';

import { AuthContext } from 'src/components/AuthContextSetter';
import { login } from 'src/helpers/backend_helper';
import logo from 'src/assets/images/logo-sm.svg';
import CarouselPage from 'src/ux/pages/AuthenticationInner/CarouselPage';

interface LoginProps {}

const Login: React.FC<LoginProps> = (props) => {
  const authContext = useContext(AuthContext);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleValidSubmit = async (event: any, values: any) => {
    try {
      setLoading(true);
      const response: Promise<any> = await login({
        email: values.email,
        password: values.password,
      });

      setError('');
      authContext.login(values.email);
    } catch (err: any) {
      let error = err;
      if (axios.isAxiosError(err) && err.response?.status === StatusCodes.UNAUTHORIZED) {
        error = new Error('Invalid username or password');
      }
      setError(error.message);
    }
    setLoading(false);
  };

  return (
    <React.Fragment>
      <MetaTags>
        <title>Login | Add + Space Admin</title>
      </MetaTags>
      <div className="auth-page">
        <Container fluid className="p-0">
          <Row className="g-0">
            <Col lg={4} md={5} className="col-xxl-3">
              <div className="auth-full-page-content d-flex p-sm-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-5">
                      <Link to="/" className="d-block auth-logo">
                        <img src={logo} alt="" height="32" /> <span className="logo-txt">Admin</span>
                      </Link>
                    </div>
                    <div className="auth-content my-auto">
                      <div>
                        <h1 className="mb-0">Add + Happiness</h1>
                        <p className="text-muted mt-2">Sign in and do great stuff.</p>
                      </div>
                      <AvForm
                        className="custom-form mt-4 pt-2"
                        onValidSubmit={(e: any, v: any) => {
                          handleValidSubmit(e, v);
                        }}
                      >
                        {error ? <Alert color="danger">{error}</Alert> : null}
                        <div className="mb-3">
                          <AvField
                            name="email"
                            label="Email"
                            className="form-control"
                            placeholder="Your email"
                            type="email"
                            required
                          />
                        </div>
                        <div className="mb-3">
                          <div className="d-flex align-items-start">
                            <div className="flex-grow-1">
                              <label className="form-label">Password</label>
                            </div>
                            <div className="flex-shrink-0">
                              <div className="">
                                <Link to="/forgot-password" className="text-muted" tabIndex={-1}>
                                  Forgot password?
                                </Link>
                              </div>
                            </div>
                          </div>

                          <div className="mb-3">
                            <AvField
                              name="password"
                              type="password"
                              className="form-control"
                              required
                              placeholder="Your password"
                            />
                          </div>
                        </div>
                        <div className="mb-3">
                          <button className="btn btn-primary waves-effect waves-light" type="submit" disabled={loading}>
                            Log In
                          </button>
                        </div>
                      </AvForm>
                    </div>
                    <div className="mt-4 mt-md-5">
                      <p className="mb-0">© {new Date().getFullYear()} Add + Space</p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <CarouselPage />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Login;
