import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, BreadcrumbItem } from 'reactstrap';

export type BreadCrumbItemProps = {
  label: string;
  path: string;
};

interface BreadcrumbsProps {
  items: BreadCrumbItemProps[];
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ items }) => {
  if (items.length === 0) {
    return null;
  }

  return (
    <Row>
      <Col xs="12">
        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
          <h4 className="mb-0 font-size-18">{items[items.length - 1].label}</h4>
          <div className="page-title-right">
            <ol className="breadcrumb m-0">
              {items.map((item, ix) => (
                <BreadcrumbItem active={ix === items.length - 1} key={`breadcrumbs-${ix}`}>
                  <Link to={item.path}>{item.label}</Link>
                </BreadcrumbItem>
              ))}
            </ol>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default Breadcrumbs;
