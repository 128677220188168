import React, { useState, useEffect } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useMutation, ApolloError } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { CREATE_BAGS, CREATE_PACKAGES, ALL_BATCHES } from 'src/helpers/graphql';
import { BatchType } from 'src/types/types';
import config from 'src/config';
import Select, { toOption } from 'src/components/Forms/Select';
import TextInput from 'src/components/Forms/TextInput';
import ModalForm, { setFieldErrors } from 'src/components/Forms/ModalForm';

type Inputs = {
  type: BatchType;
  amount: string;
};

type CreateLabelsDialogProps = {
  show: boolean;
  setShow: Function;
};

const CreateLabelsDialog: React.FC<CreateLabelsDialogProps> = ({ show, setShow }) => {
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors },
  } = useForm<Inputs>();

  const [globalError, setGlobalError] = useState<string | ApolloError | undefined>('');
  const [createBags, createBagsMutation] = useMutation(CREATE_BAGS);
  const [createPackages, createPackagesMutation] = useMutation(CREATE_PACKAGES);

  const typeOptions = [
    toOption(BatchType.BAG, t(`Batch.type.${BatchType.BAG}`)),
    toOption(BatchType.PACKAGE, t(`Batch.type.${BatchType.PACKAGE}`)),
  ];

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      if (data.type === BatchType.BAG) {
        await createBags({
          refetchQueries: [ALL_BATCHES],
          variables: {
            amount: parseInt(data.amount, 10),
          },
        });
      } else {
        await createPackages({
          refetchQueries: [ALL_BATCHES],
          variables: {
            amount: parseInt(data.amount, 10),
            packageTypeId: config.app.packageTypeDefaultId,
          },
        });
      }

      setShow(false);
    } catch (err) {
      setFieldErrors(err, setError, setGlobalError);
    }
  };

  useEffect(() => {
    const errors = {
      ...createBagsMutation.error,
      ...createPackagesMutation.error,
    };
    setFieldErrors(errors, setError, setGlobalError);
  }, [createBagsMutation.error, createPackagesMutation.error, setError]);

  useEffect(() => {
    reset();
  }, [reset]);

  const loading = createPackagesMutation.loading || createPackagesMutation.loading || createBagsMutation.loading;

  return (
    <ModalForm
      title={t('BatchList.generate.title')}
      show={show}
      setShow={setShow}
      onSubmit={handleSubmit(onSubmit)}
      loading={loading}
      globalError={globalError}
      submitText="Generate"
      cancelText="Cancel"
      size="me"
    >
      <p>{t('BatchList.generate.description')}</p>

      <Select
        label="Label type"
        {...register('type', { required: true })}
        disabled={loading}
        selectOptions={typeOptions}
        errors={errors.type}
      />

      <TextInput
        type="number"
        label="Amount"
        defaultValue=""
        placeholder={t('BatchList.generate.amount.placeholder')}
        {...register('amount', { required: true })}
        disabled={loading}
        errors={errors.amount}
      />
    </ModalForm>
  );
};

export default CreateLabelsDialog;
