import React, { useState } from 'react';
import MetaTags from 'react-meta-tags';
import { useParams, useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { GET_USER, USER_SUBSCRIPTIONS } from 'src/helpers/graphql';
import Auth from 'src/helpers/auth/auth';
import Breadcrumbs from 'src/components/Breadcrumbs';
import Timeline from 'src/components/Timeline';
import PackageList from './PackageList';
import Header from './Header';
import Subscription from './Subscription/Subscription';
import ResponseHandler from 'src/components/ResponseHandler';
import EditUserDialog from '../EditUser/EditUserDialog';
import Addresses from './Addresses';
import UserContextBadge from 'src/components/User/UserBadges/UserContextBadge';
import UserAvatar from 'src/components/UserAvatar';
import { formatToLocaleDate, getDisplayName } from 'src/helpers/ui';
import { sampleAdmin } from 'src/helpers/contextDefaults';
import Storages from './Storages';
import TasksList from './Tasks';

const userPageContext = {
  user: sampleAdmin,
};

export const UserPageContext = React.createContext(userPageContext);

const UserDetails: React.FC<{}> = () => {
  const [showEditDialog, setShowEditDialog] = useState(false);
  const history = useHistory();
  const { t } = useTranslation();

  const { userId } = useParams<{ userId: string }>();

  const getUserQuery = useQuery(GET_USER, { variables: { id: userId } });
  const userSubscriptionsQuery = useQuery(USER_SUBSCRIPTIONS, { variables: { userId: userId } });

  const editUser = (ev: React.MouseEvent) => {
    ev.stopPropagation();
    setShowEditDialog(true);
  };

  const renderPageInternals = (user: User, subscriptions: SubscriptionInfo[]) => {
    if (!user) {
      if (!getUserQuery.loading) {
        history.push('/users');
      }
      return null;
    }

    const displayedUserAuth = new Auth(user, null);

    return (
      <UserPageContext.Provider value={{ user }}>
        <Breadcrumbs
          items={[
            {
              label: t('UserDetails.users'),
              path: '/users',
            },
            {
              label: getDisplayName(user!),
              path: `/users/${user.id}`,
            },
          ]}
        />
        <Row>
          <Col lg="9">
            <Card>
              <CardBody>
                <Row>
                  <div className="col-sm order-2 order-sm-1">
                    <div className="d-flex align-items-start mt-3 mt-sm-0">
                      <div className="flex-shrink-0">
                        <div className="avatar-xl me-3">
                          <UserAvatar user={user} className="img-fluid rounded-circle d-block" />
                        </div>
                      </div>
                      <div className="flex-grow-1">
                        <div>
                          <h3 className="mb-1">{getDisplayName(user!)}</h3>
                          {user!.userContexts.map((context, ix) => (
                            <UserContextBadge role={context.role} context={context.context} className="mb-3" key={ix} />
                          ))}

                          <p className="text-muted font-size-12">
                            {t('UserDetails.signedUp')} {formatToLocaleDate(new Date(user?.createdAt), 'May 23, 2022')}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-auto order-1 order-sm-2">
                    <Header onEditUser={editUser} subscriptions={subscriptions} />
                  </div>
                </Row>
              </CardBody>
            </Card>

            {displayedUserAuth.hasSearcherContext() ? <Addresses /> : null}
            {displayedUserAuth.hasProviderContext() ? <Storages /> : null}
            {displayedUserAuth.hasTransporterContext() ? <TasksList /> : null}
            {displayedUserAuth.hasSearcherContext() ? <PackageList /> : null}
            <Timeline title="History" />
          </Col>
          <Col lg="3">
            <Subscription subscriptions={subscriptions} />
          </Col>
        </Row>
        <EditUserDialog user={user!} show={showEditDialog} setShow={setShowEditDialog} />
      </UserPageContext.Provider>
    );
  };

  return (
    <ResponseHandler
      loading={getUserQuery.loading || userSubscriptionsQuery.loading}
      error={getUserQuery.error || userSubscriptionsQuery.error}
    >
      <div className="page-content">
        <MetaTags>
          <title>
            {t('UserDetails.title')} | {t('Common.title.staticPart')}
          </title>
        </MetaTags>
        <Container fluid>
          {renderPageInternals(
            getUserQuery.data?.user as User,
            userSubscriptionsQuery.data?.userSubscriptions as SubscriptionInfo[],
          )}
        </Container>
      </div>
    </ResponseHandler>
  );
};

export default UserDetails;
