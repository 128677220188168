import React, { useContext } from 'react';
import { Table, Card, CardHeader } from 'reactstrap';
import { useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { Spinner } from 'reactstrap';

import { UserPageContext } from './UserDetails';
import { TRANSPORTER_DELIVERY_REQUESTS } from 'src/helpers/graphql';
import { getAddressTexts, formatToLocaleDate } from 'src/helpers/ui';
import DeliveryRequestStatusBadge from 'src/components/DeliveryRequest/DeliveryRequestStatusBadge';
import { t } from 'i18next';

export interface TasksListProps {}

const TasksList: React.FC<TasksListProps> = () => {
  const history = useHistory();
  const { user } = useContext(UserPageContext);

  const getUserQuery = useQuery(TRANSPORTER_DELIVERY_REQUESTS, { variables: { userId: user.id } });
  console.log('tasks', getUserQuery.data?.transporterDeliveryRequests);

  const navigateToTaskDetails = (taskId: string) => {
    history.push(`/tasks/${taskId}`);
  };

  if (getUserQuery.loading) {
    return <Spinner className="spinner--with-space" color="secondary" />;
  }

  return (
    <React.Fragment>
      <Card>
        <CardHeader>
          <h4 className="card-title mb-0 flex-grow-1">{t('DeliveryRequest.list.title')}</h4>
        </CardHeader>
        <div className="card-body">
          <div className="table-responsive">
            <Table className="table mb-0 align-middle">
              <thead>
                <tr>
                  <th>{t('Common.status')}</th>
                  <th>{t('Common.pickup_at')}</th>
                  <th>{t('Common.deliver_to')}</th>
                  <th>{t('Common.updated')}</th>
                </tr>
              </thead>
              <tbody>
                {getUserQuery.data?.transporterDeliveryRequests.map((task: DeliveryRequestTransporterListItem) => (
                  <tr key={task.id} onClick={() => navigateToTaskDetails(task.id)}>
                    <td>
                      <DeliveryRequestStatusBadge status={task.status} direction={task.direction} />
                    </td>
                    <td>{getAddressTexts(task.fromAddress, { format: 'short' })}</td>
                    <td>{getAddressTexts(task.toAddress, { format: 'short' })}</td>
                    <td>{formatToLocaleDate(task.updatedAt, '5.23.2021 - 11:06 AM')}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </Card>
    </React.Fragment>
  );
};
export default TasksList;
