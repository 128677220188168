interface DashboardProps {
  id: number;
  title: string;
  price: number;
  rank: string;
  isDoller: boolean;
  postFix: string;
  statusColor: string;
  series: Array<any>;
}

const WidgetsData: Array<DashboardProps> = [
  {
    id: 1,
    title: 'Registered users',
    price: 1234,
    rank: '+20 users',
    isDoller: false,
    postFix: '',
    statusColor: 'success',
    series: [2, 10, 18, 22, 36, 15, 47, 75, 65, 19, 14, 2, 47, 42, 15],
  },
  {
    id: 2,
    title: 'Paying users',
    price: 123,
    rank: '-29 users',
    isDoller: false,
    postFix: '',
    statusColor: 'danger',
    series: [15, 42, 47, 2, 14, 19, 65, 75, 47, 15, 42, 47, 2, 14, 12],
  },
  {
    id: 3,
    title: 'Stored boxes',
    price: 432,
    rank: '+2',
    isDoller: false,
    postFix: '',
    statusColor: 'success',
    series: [47, 15, 2, 67, 22, 20, 36, 60, 60, 30, 50, 11, 12, 3, 8],
  },
  {
    id: 5,
    title: 'Estimated MRR',
    price: 1257,
    rank: '+$2.75',
    isDoller: true,
    postFix: '',
    statusColor: 'success',
    series: [12, 14, 2, 47, 42, 15, 47, 75, 65, 19, 14, 2, 47, 42, 15],
  },
];

export { WidgetsData };
