import React, { useEffect } from 'react';
import MetaTags from 'react-meta-tags';
import { useParams, useHistory } from 'react-router-dom';
import { useQuery, useLazyQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { Container, Row, Col } from 'reactstrap';

import {
  PACKAGE_BY_UNIQUE_ID,
  PACKAGE_INFO_BY_PACKAGE_UNIQUE_ID,
  GET_DELIVERY_REQUEST,
  GET_BASE_USER,
} from 'src/helpers/graphql';
import { getDisplayName } from 'src/helpers/ui';
import Breadcrumbs from 'src/components/Breadcrumbs';
import Timeline from 'src/components/Timeline';
import ResponseHandler from 'src/components/ResponseHandler';
import OwnerCard from './OwnerCard';
import TransporterCard from './TransporterCard';
import Items from './Items';
import PackageData from './PackageData';

const PackageDetails: React.FC<{}> = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const { packageId } = useParams<{ packageId: string }>();

  const packageByUniqueIdQuery = useQuery(PACKAGE_BY_UNIQUE_ID, { variables: { uniqueId: packageId } });
  const packageInfoByPackageUniqueIdQuery = useQuery(PACKAGE_INFO_BY_PACKAGE_UNIQUE_ID, {
    variables: { uniqueId: packageId },
  });

  const [getBaseUser, getBaseUserQuery] = useLazyQuery(GET_BASE_USER);
  const [getDeliveryRequest, getDeliveryRequestQuery] = useLazyQuery(GET_DELIVERY_REQUEST);
  const lastDeliveryInfoId = packageInfoByPackageUniqueIdQuery.data?.packageInfoByPackageUniqueId?.lastDeliveryInfo?.id;
  const ownerId = packageByUniqueIdQuery.data?.packageByUniqueId?.ownerId;

  useEffect(() => {
    if (lastDeliveryInfoId) {
      getDeliveryRequest({ variables: { id: lastDeliveryInfoId } });
    }
  }, [lastDeliveryInfoId]);

  useEffect(() => {
    if (ownerId) {
      getBaseUser({ variables: { id: ownerId } });
    }
  }, [ownerId]);

  const generateBreadcrumbs = (packageData: Package) => (
    <Breadcrumbs
      items={[
        {
          label: t('Packages.title'),
          path: '#',
        },
        {
          label: t('PackageDetails.title'),
          path: `/packages/${packageData.uniqueId}`,
        },
      ]}
    />
  );

  const renderPackagePage = (packageData: Package) => {
    console.log({ packageData });

    return (
      <>
        {generateBreadcrumbs(packageData)}
        <Row>
          <h1 className="mb-3">{packageData.uniqueId}</h1>

          <PackageData packageData={packageData} />
        </Row>
      </>
    );
  };

  const renderPackageInfoPage = (
    packageData: Package,
    packageInfo: PackageInfoDetails,
    user: BaseUserInfo,
    task: DeliveryRequestDetails,
  ) => {
    console.log({ packageData, packageInfo, user, task });

    const ownerRelated = user ? (
      <>
        <OwnerCard
          packageData={packageData}
          userId={user.id}
          userName={getDisplayName(user)}
          address={task?.fromAddress}
        />

        <TransporterCard packageData={packageData} packageInfo={packageInfo} task={task} />
      </>
    ) : null;

    return (
      <>
        {generateBreadcrumbs(packageData)}
        <Row>
          <h1 className="mb-3">{packageData.uniqueId}</h1>
          <p>{packageInfo.description}</p>
          <Col lg="3">
            {ownerRelated}

            <Timeline title="Package log" />
          </Col>
          <Col lg="9">
            <Items packageData={packageData} packageInfo={packageInfo} />
          </Col>
        </Row>
      </>
    );
  };

  const renderPageInternals = (
    packageData: Package,
    packageInfo: PackageInfoDetails,
    user: BaseUserInfo,
    task: DeliveryRequestDetails,
  ) => {
    if (
      packageByUniqueIdQuery.loading ||
      packageInfoByPackageUniqueIdQuery.loading ||
      (ownerId && (!getBaseUserQuery.called || getBaseUserQuery.loading)) ||
      (lastDeliveryInfoId && (!getDeliveryRequestQuery.called || getDeliveryRequestQuery.loading))
    ) {
      return null;
    }

    if (packageInfo) {
      return renderPackageInfoPage(packageData, packageInfo, user, task);
    }

    if (packageData) {
      return renderPackagePage(packageData);
    }

    history.push('/pages-404');
  };

  return (
    <ResponseHandler
      loading={
        packageByUniqueIdQuery.loading ||
        packageInfoByPackageUniqueIdQuery.loading ||
        (ownerId && (!getBaseUserQuery.called || getBaseUserQuery.loading)) ||
        (lastDeliveryInfoId && (!getDeliveryRequestQuery.called || getDeliveryRequestQuery.loading))
      }
    >
      <div className="page-content">
        <MetaTags>
          <title>
            {t('PackageDetails.title')} | {t('Common.title.staticPart')}
          </title>
        </MetaTags>
        <Container fluid>
          {renderPageInternals(
            packageByUniqueIdQuery.data?.packageByUniqueId as Package,
            packageInfoByPackageUniqueIdQuery.data?.packageInfoByPackageUniqueId as PackageInfoDetails,
            getBaseUserQuery.data?.user as BaseUserInfo,
            getDeliveryRequestQuery.data?.deliveryRequest as DeliveryRequestDetails,
          )}
        </Container>
      </div>
    </ResponseHandler>
  );
};

export default PackageDetails;
