import React, { useState } from 'react';
import { Modal, ModalBody, ModalFooter, Spinner } from 'reactstrap';
import { useQuery } from '@apollo/client';

import { DeliveryRequestStatus } from 'src/types/types';
import { GET_TRANSPORTERS_REQUEST, GET_VIRTUAL_STORAGES, MAX_PAGINATED_ITEMS_COUNT } from 'src/helpers/graphql';
import SelectStorage from './SelectStorage';
import StatusToScheduledDialog from './StatusToScheduledDialog';
import StatusToPickup from './StatusToPickup';
import StatusToDelivered from './StatusToDelivered';
import StatusToCancelled from './StatusToCancelled';
import StatusToMissed from './StatusToMissed';
import StatusToCreate from './StatusToCreate';

interface EditDeliveryRequestStatusProps {
  newStatus: DeliveryRequestStatus | 'CHANGE_STORAGE';
  show: boolean;
  setShow: Function;
  tasks: (DeliveryRequestListItem | DeliveryRequestDetails)[];
}

type SingleOrBulkSubmitParams = {
  data: any;
  submitFn: Function;
  setFieldErrors: Function;
};

const EditDeliveryRequestStatus: React.FC<EditDeliveryRequestStatusProps> = ({ newStatus, show, setShow, tasks }) => {
  const [resultWindowData, setResultWindowData] = useState<{ error: number; success: number }>({
    error: 0,
    success: 0,
  });
  const [showResultWindow, setShowResultWindow] = useState(false);

  const {
    loading: transportersLoading,
    error: transportersError,
    data: transportersData,
  } = useQuery(GET_TRANSPORTERS_REQUEST, {
    variables: {
      first: MAX_PAGINATED_ITEMS_COUNT,
    },
  });

  const {
    loading: virtualStoragesLoading,
    error: virtualStoragesError,
    data: virtualStoragesData,
  } = useQuery(GET_VIRTUAL_STORAGES, {
    variables: {
      first: MAX_PAGINATED_ITEMS_COUNT,
    },
  });

  const handleSingleOrBulkSubmit = async ({ data, submitFn, setFieldErrors }: SingleOrBulkSubmitParams) => {
    if (data.length === 1) {
      try {
        await submitFn({
          variables: data[0],
        });
        setShow(false);
      } catch (err) {
        setFieldErrors(err);
      }
    } else {
      let errors = 0;
      let success = 0;

      await Promise.all(
        data.map(async (currentData: any) => {
          try {
            await submitFn({
              variables: currentData,
            });
            success++;
          } catch (err) {
            errors++;
          }
        }),
      );

      setResultWindowData({ success, error: errors });
      setShowResultWindow(true);
    }
  };

  const closeResultWindow = () => {
    setShow(false);
    setShowResultWindow(false);
  };

  if (virtualStoragesLoading || transportersLoading) {
    return (
      <Modal isOpen={show}>
        <ModalBody>
          <Spinner className="spinner--with-space" color="secondary" />
        </ModalBody>
      </Modal>
    );
  }

  const getResultBody = (success: number, error: number) => {
    const total = success + error;

    if (total === success) {
      return (
        <>
          <h5 className="text-center text-success">
            {total} / {success}
          </h5>
          <p>{success} task(s) are changed successfully.</p>
        </>
      );
    }

    return (
      <>
        <h5 className="text-center text-danger">
          {total} / {success}
        </h5>
        <p>{success} task(s) are changed successfully.</p>
        <p>
          {error} task(s) are not updated because of some error. Edit them separately, so you can see what's wrong with
          them!
        </p>
      </>
    );
  };

  if (showResultWindow) {
    return (
      <Modal isOpen={true} toggle={() => closeResultWindow()}>
        <ModalBody>{getResultBody(resultWindowData.success, resultWindowData.error)}</ModalBody>
        <ModalFooter>
          <button type="button" className="btn btn-soft-light" data-dismiss="modal" onClick={closeResultWindow}>
            Close
          </button>
        </ModalFooter>
      </Modal>
    );
  }

  if (newStatus === DeliveryRequestStatus.CREATED) {
    return (
      <StatusToCreate show={show} setShow={setShow} tasks={tasks} handleSingleOrBulkSubmit={handleSingleOrBulkSubmit} />
    );
  }

  if (newStatus === DeliveryRequestStatus.SCHEDULED) {
    return (
      <StatusToScheduledDialog
        show={show}
        setShow={setShow}
        tasks={tasks}
        handleSingleOrBulkSubmit={handleSingleOrBulkSubmit}
        transporters={transportersData?.allTransporterUsers?.edges.map((edge: any) => edge.node)}
        storages={virtualStoragesData?.allVirtualStoragesWithStorage?.edges.map((edge: any) => edge.node)}
      />
    );
  }

  if (newStatus === DeliveryRequestStatus.AT_TRANSPORTER) {
    return (
      <StatusToPickup show={show} setShow={setShow} tasks={tasks} handleSingleOrBulkSubmit={handleSingleOrBulkSubmit} />
    );
  }

  if (newStatus === DeliveryRequestStatus.DELIVERED) {
    return (
      <StatusToDelivered
        show={show}
        setShow={setShow}
        tasks={tasks}
        handleSingleOrBulkSubmit={handleSingleOrBulkSubmit}
      />
    );
  }

  if (newStatus === 'CHANGE_STORAGE') {
    return (
      <SelectStorage
        show={show}
        setShow={setShow}
        tasks={tasks}
        handleSingleOrBulkSubmit={handleSingleOrBulkSubmit}
        storages={virtualStoragesData?.allVirtualStoragesWithStorage?.edges.map((edge: any) => edge.node)}
      />
    );
  }

  if (newStatus === DeliveryRequestStatus.CANCELLED) {
    return (
      <StatusToCancelled
        show={show}
        setShow={setShow}
        tasks={tasks}
        handleSingleOrBulkSubmit={handleSingleOrBulkSubmit}
      />
    );
  }

  if (newStatus === DeliveryRequestStatus.MISSED) {
    return (
      <StatusToMissed show={show} setShow={setShow} tasks={tasks} handleSingleOrBulkSubmit={handleSingleOrBulkSubmit} />
    );
  }

  return null;
};

export default EditDeliveryRequestStatus;
