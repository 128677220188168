import React from 'react';
import { Card, CardBody } from 'reactstrap';

import classnames from 'classnames';

export interface PlanCardProps {
  planTitle: string;
  planDetails: string;
  styleNames: string;
}

const PlanCard: React.FC<PlanCardProps> = ({ planTitle, planDetails, styleNames }) => {
  return (
    <React.Fragment>
      <Card className={classnames('', styleNames)}>
        <CardBody>
          <h2 className="mb-3 text-white">
            <i className="mdi mdi-grid me-3"></i>
            {planTitle}
          </h2>
          <p className="card-text">{planDetails}</p>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default PlanCard;
