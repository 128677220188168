import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

export interface ConfirmModalType {
  modalTitle: string;
  modalText: string;
  actionText?: string;
  pendingActionText?: string;
  successMessage?: string;
  onConfirm: Function;
  onCancel?: Function;
}

const defaultConfirmModalOptions: ConfirmModalType = {
  modalTitle: '',
  modalText: '',
  onConfirm: () => {},
};

interface ConfirmContextSetterProps {
  children: React.ReactNode;
}

type ConfirmContextType = {
  visible: boolean;
  disabled: boolean;
  options: ConfirmModalType;
  show: (options: ConfirmModalType) => void;
  hide: Function;
  enable: Function;
  disable: Function;
};

const confirmContext: ConfirmContextType = {
  visible: false,
  disabled: false,
  options: defaultConfirmModalOptions,
  show: () => {},
  hide: () => {},
  enable: () => {},
  disable: () => {},
};

export const ConfirmContext = React.createContext(confirmContext);

const ConfirmContextSetter: React.FC<ConfirmContextSetterProps> = ({ children }) => {
  const { t } = useTranslation();

  const defaultOptions = {
    ...defaultConfirmModalOptions,
    pendingActionText: t('Common.saving'),
  };

  const [confirmModal, setConfirmModal] = useState<ConfirmModalType>(defaultOptions);

  const [show, setShow] = useState(false);
  const [disabled, setDisabled] = useState(false);

  return (
    <ConfirmContext.Provider
      value={{
        ...confirmContext,
        options: confirmModal,
        visible: show,
        disabled: disabled,
        show: (options: ConfirmModalType) => {
          setConfirmModal({ ...defaultOptions, ...options });
          setShow(true);
          setDisabled(false);
        },
        hide: () => {
          setShow(false);
          setDisabled(false);
          setConfirmModal(defaultOptions);
        },
        disable: (options: ConfirmModalType) => {
          setDisabled(true);
        },
        enable: () => {
          setDisabled(false);
        },
      }}
    >
      {children}
    </ConfirmContext.Provider>
  );
};

export default ConfirmContextSetter;
