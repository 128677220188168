import React from 'react';
import { Spinner, UncontrolledAlert } from 'reactstrap';
import styled from 'styled-components/macro';
import { ApolloError } from '@apollo/client';

interface ResponseHandlerProps {
  children: React.ReactNode;
  loading: boolean;
  error?: ApolloError;
}

const ResponseHandler: React.FC<ResponseHandlerProps> = ({ children, loading, error }) => {
  if (loading) {
    return <Spinner className="spinner--with-space" color="secondary" />;
  }

  if (error) {
    console.error(error);
    return (
      <Box color="danger" className="px-4 text-center">
        <i className="mdi mdi-block-helper d-block display-4 mt-2 mb-3 text-danger"></i>
        <h5 className="text-danger">Error</h5>
        <p>{error.message}</p>
      </Box>
    );
  }

  return <>{children}</>;
};

export default ResponseHandler;

const Box = styled(UncontrolledAlert)`
  max-width: 400px;
  margin: 30vh auto;
`;
