import React, { useEffect } from 'react';
import { changeLayout } from 'src/store/layout/actions';
import { useDispatch } from 'react-redux';

interface NonAuthLayoutProps {
  children: React.ReactNode;
}

const NonAuthLayout: React.FC<NonAuthLayoutProps> = ({ children }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(changeLayout('vertical'));
  }, [dispatch]);

  return <React.Fragment>{children}</React.Fragment>;
};

export default NonAuthLayout;
