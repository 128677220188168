import _isDate from 'lodash/isDate';
import _isObjectLike from 'lodash/isObjectLike';

export enum StorageTypes {
  localStorage = 'localStorage',
  sessionStorage = 'sessionStorage',
}

export interface BrowserStorageType {
  getItem: Function;
  setItem: Function;
  removeItem: Function;
  clear: Function;
}

interface OptionsType {
  [key: string]: any;
  defaults: {
    [key: string]: any;
  };
}

const PREFIX = 'addspace';
const ENCODE_JSON = '%%%JSON%%%';
const ENCODE_NUMBER = '%%%NUM%%%';
const ENCODE_BOOL = '%%%BOOL%%%';
const ENCODE_DATE = '%%%DATE%%%';

// polyfilling the localStorage where it's not supported, or disabled (webview)
export default class Storage {
  _data: any = {};
  _storageEngine: BrowserStorageType;
  _options: OptionsType = {
    defaults: {},
  };

  constructor(storageType: StorageTypes, options: OptionsType = { defaults: {} }) {
    this._storageEngine = window[storageType] as BrowserStorageType;
    this._options = {
      ...{
        defaults: {},
      },
      ...options,
    };

    try {
      const TEST_KEY = 'addspace-testing-storage';
      window.localStorage.setItem(TEST_KEY, TEST_KEY);
      if (window.localStorage.getItem(TEST_KEY) !== TEST_KEY) {
        throw new Error("LocalStorage can't be accessed correctly");
      }
      window.localStorage.removeItem(TEST_KEY);
      // throw new Error('test the fallback storage by commenting this line out')
    } catch (ex) {
      // if there was any problem, we shouldn't user the browser's storage solution
      this._storageEngine = {
        setItem: (key: string, val: any) => {
          this._data[key] = String(val);
        },
        getItem: (key: string) => (Object.prototype.hasOwnProperty.call(this._data, key) ? this._data[key] : undefined),
        removeItem: (key: string) => delete this._data[key],
        clear: () => {
          this._data = {};
        },
      };
    }
  }

  parseValue(value: any) {
    // null, number, etc
    if (!value || !value.indexOf) {
      return value;
    }

    if (value.indexOf(ENCODE_JSON) === 0) {
      return JSON.parse(value.substring(ENCODE_JSON.length));
    }

    if (value.indexOf(ENCODE_NUMBER) === 0) {
      return Number.parseFloat(value.substring(ENCODE_NUMBER.length));
    }

    if (value.indexOf(ENCODE_BOOL) === 0) {
      return value.substring(ENCODE_BOOL.length) === 'true';
    }

    if (value.indexOf(ENCODE_DATE) === 0) {
      return new Date(value);
    }

    return value;
  }

  setItem(key: string, value: any) {
    let persistableValue = value;

    if (persistableValue === null) {
      persistableValue = value;
    } else if (_isDate(value)) {
      persistableValue = `${ENCODE_DATE}${value}`;
    } else if (_isObjectLike(value)) {
      persistableValue = `${ENCODE_JSON}${JSON.stringify(value)}`;
    } else if (typeof value === 'number') {
      persistableValue = `${ENCODE_NUMBER}${value}`;
    } else if (typeof value === 'boolean') {
      persistableValue = `${ENCODE_BOOL}${value}`;
    }

    this._storageEngine.setItem(`${PREFIX}-${key}`, persistableValue);
  }

  getItem(key: string) {
    const value = this._storageEngine.getItem(`${PREFIX}-${key}`);
    if (value === undefined) {
      if (this._options.defaults[key] !== undefined) {
        return this._options.defaults[key];
      }
    }

    return this.parseValue(value);
  }

  removeItem(key: string) {
    this._storageEngine.removeItem(`${PREFIX}-${key}`);
  }

  clear() {
    this._storageEngine.clear();
  }
}
