import React, { useState } from 'react';
import MetaTags from 'react-meta-tags';
import { Link } from 'react-router-dom';
import { Container } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { GET_VIRTUAL_STORAGES } from 'src/helpers/graphql';
import { getAddressTexts, getDisplayName } from 'src/helpers/ui';
import Breadcrumbs from 'src/components/Breadcrumbs';
import CreateVirtualStorageDialog from './CreateVirtualStorageDialog';
import FilteredTable, { DefaultSortedByType } from 'src/components/FilteredTable/FilteredTable';

const defaultSortedBy: DefaultSortedByType[] = [
  {
    dataField: 'id',
    order: 'asc',
  },
];

const Storages: React.FC<{}> = () => {
  const [show, setShow] = useState(false);
  const [virtualStorages, setVirtualStorages] = useState<VirtualStorageWithAddressListItem[] | undefined>(undefined);
  const { t } = useTranslation();

  const columns = [
    {
      dataField: 'storage.name',
      text: t('Common.name'),
      sort: true,
      formatter: (cell: any, row: VirtualStorageWithAddressListItem, rowIndex: number) =>
        row.storage.name || t('StorageDetails.unnamed'),
    },
    {
      dataField: 'user',
      text: t('Common.owner'),
      sort: true,
      formatter: (cell: any, row: VirtualStorageWithAddressListItem, rowIndex: number) => (
        <div>{getDisplayName(row.user)}</div>
      ),
    },
    {
      dataField: 'address',
      text: t('Common.location'),
      formatter: (cell: any, row: VirtualStorageWithAddressListItem, rowIndex: number) =>
        row?.address ? getAddressTexts(row?.address, { format: 'short' }) : '',
      sort: true,
    },
    {
      dataField: 'volume',
      text: t('StorageDetails.volume'),
      sort: true,
    },
    {
      dataField: 'packages',
      text: t('Common.packages'),
      sort: true,
    },
  ];

  const wrapper = (children: React.ReactNode) => (
    <div className="page-content">
      <MetaTags>
        <title>
          {t('Storages.title')} {t('Common.title.staticPart')}
        </title>
      </MetaTags>
      <Container fluid>
        <Breadcrumbs
          items={[
            { label: t('Storages.title'), path: '#' },
            { label: t('Storages.list'), path: '#' },
          ]}
        />

        {children}
      </Container>
    </div>
  );

  const createStorage = (ev: React.MouseEvent) => {
    ev.stopPropagation();
    setShow(true);
  };

  const generateUrlForStorage = (storage: VirtualStorageWithAddressListItem) => `/storages/${storage.id}`;

  const setItems = (storages: VirtualStorageWithAddressListItem[]) => {
    setVirtualStorages(storages);
  };

  return (
    <>
      <FilteredTable
        items={virtualStorages}
        setItems={setItems}
        getTableTitle={(itemsLength?: number) => `${itemsLength} ${t('Storages.listsummary')}`}
        query={GET_VIRTUAL_STORAGES}
        keyInResponse="allVirtualStoragesWithStorage"
        wrapper={wrapper}
        columns={columns}
        defaultSortedBy={defaultSortedBy}
        // generateUrlForEntity={generateUrlForStorage}
        // titleActions={
        //   <Link to="#" className="btn btn-primary" onClick={createStorage}>
        //     <i className="mdi mdi-plus-thick me-1"></i> {t('Storages.add')}
        //   </Link>
        // }
      />

      {/* <CreateVirtualStorageDialog show={show} setShow={setShow} /> */}
    </>
  );
};

export default Storages;
