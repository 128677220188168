import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useMutation } from '@apollo/client';

import { DELETE_DELIVERY_REQUEST, GET_ALL_DELIVERY_REQUESTS } from 'src/helpers/graphql';

export interface DeleteTaskDialogProps {
  task?: DeliveryRequestListItem;
  show: boolean;
  setShow: Function;
}

const DeleteTaskDialog: React.FC<DeleteTaskDialogProps> = ({ task, show, setShow }) => {
  const history = useHistory();
  const [deleteConfirmedModalVisibility, setDeleteConfirmedModalVisibility] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const [deleteDeliveryRequest] = useMutation(DELETE_DELIVERY_REQUEST, {
    // the delete doesn't remove the deleted entity from the tasklist
    refetchQueries: [{ query: GET_ALL_DELIVERY_REQUESTS }],
  });

  if (!task) {
    return null;
  }

  return (
    <React.Fragment>
      {show ? (
        <SweetAlert
          title="Deleting a task cannot be reversed."
          warning
          showCancel
          showConfirm={inputValue === 'DELETE'}
          // confirmButtonText="Yes, delete it!"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={async () => {
            try {
              await deleteDeliveryRequest({
                variables: {
                  id: task.id,
                },
              });
              setShow(false);
              setDeleteConfirmedModalVisibility(true);
            } catch (err) {}
          }}
          onCancel={() => setShow(false)}
        >
          If you really want to continue, type "DELETE" in the field below.
          <p className="mt-3">
            <input
              type="text"
              className="form-control"
              value={inputValue}
              onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                setInputValue(ev.currentTarget.value);
              }}
            />
          </p>
        </SweetAlert>
      ) : null}

      {deleteConfirmedModalVisibility ? (
        <SweetAlert
          success
          title="Deleted"
          onConfirm={() => {
            setDeleteConfirmedModalVisibility(false);
            history.push('/tasks');
          }}
        >
          This task has been deleted.
        </SweetAlert>
      ) : null}
    </React.Fragment>
  );
};
export default DeleteTaskDialog;
