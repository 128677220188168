import { ForgotPasswordTypes } from './actionTypes';

const initialState = {
  forgotSuccess: null,
  forgotError: null,
  loading: false,
};

const forgotPassword = (state = initialState, action: any) => {
  switch (action.type) {
    case ForgotPasswordTypes.FORGOT_PASSWORD:
      state = {
        ...state,
        forgotSuccess: null,
        forgotError: null,
        loading: true,
      };
      break;
    case ForgotPasswordTypes.FORGOT_PASSWORD_SUCCESS:
      state = {
        ...state,
        forgotSuccess: action.payload,
        loading: false,
      };
      break;
    case ForgotPasswordTypes.FORGOT_PASSWORD_ERROR:
      state = { ...state, forgotError: action.payload, loading: false };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default forgotPassword;
