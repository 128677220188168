import React, { useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { AuthContext } from 'src/components/AuthContextSetter';

const Home: React.FC<{}> = () => {
  const authContext = useContext(AuthContext);
  const history = useHistory();

  useEffect(() => {
    history.push(authContext.auth.getHomeUrl());
  }, []);
  return null;
};

export default Home;
