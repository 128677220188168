import React, { ChangeEventHandler } from 'react';
import classnames from 'classnames';
import { Input, Label as ReactStrapLabel } from 'reactstrap';

import Label from './Label';
import Errors, { ErrorsType } from './Errors';

export interface ToggleProps {
  label?: string;
  placeholder?: string;
  defaultChecked: boolean;
  name?: string;
  type?: string;
  disabled?: boolean;
  onChange?: ChangeEventHandler;
  errors: ErrorsType;
  ref: any;
}

const Toggle: React.FC<ToggleProps> = React.forwardRef(({ label, defaultChecked, errors, name, ...rest }, ref) => {
  const inputClasses = classnames('form-control', {
    'is-invalid': !!errors,
  });

  return (
    <div className="mb-3">
      <Label htmlFor={`formrow-${name}-input`} label={label} />

      <Input
        switch="danger"
        className={inputClasses}
        name={name}
        id={`formrow-${name}-input`}
        defaultChecked={defaultChecked}
        {...rest}
        innerRef={ref as any}
        type="checkbox"
      />
      <ReactStrapLabel className="me-1" htmlFor={`formrow-${name}-input`} data-on-label="Yes" data-off-label="No" />

      <Errors errors={errors} />
    </div>
  );
});

export default Toggle;
