import React from 'react';
import config from 'src/config';

export const getDisplayName = (user: BaseUserInfo) => {
  if (user === undefined || user === null) {
    return '';
  }

  return `${user?.firstName} ${user?.lastName}`;
};

type AddressFormat = 'long' | 'short' | '2lines';
export const getAddressTexts = (address: FullAddress, { format = 'long' }: { format?: AddressFormat } = {}) => {
  if (!address) {
    return '';
  }

  const { city, country, door, floor, info, postalCode, route, state, streetNumber } = address;

  if (format === 'short') {
    return [`${streetNumber} ${route}`, `${postalCode} ${city}`, state].filter((e) => e).join(', ');
  }

  if (format === '2lines') {
    return [`${streetNumber} ${route}`, `${postalCode} ${city}`, state];
  }

  const address1 = [
    `${streetNumber} ${route}`,
    door ? `door ${door}` : undefined,
    floor ? `FL ${floor}` : undefined,
    info,
  ]
    .filter((e) => e) // filter out undefineds
    .join(', ');

  const address2 = [`${postalCode} ${city}`, state, country]
    .filter((e) => e) // filter out undefineds
    .join(', ');

  return `${address1}, ${address2}`;
};

export const formatAddress = (address: FullAddress, { format = 'long' }: { format?: AddressFormat } = {}) => {
  const texts = getAddressTexts(address, { format });

  if (format === '2lines') {
    return (
      <>
        {texts[0]}
        <br />
        {texts[1]}
      </>
    );
  }

  return <>{texts}</>;
};

export const padWithZero = (num: number) => (num < 10 ? `0${num}` : num);

type DateFormatType =
  | 'May 23, 2022'
  | 'May 23, 2022, 11:06:29 PM'
  | '5.23.2021 - 11:06 AM'
  | '5.23.22'
  | 'datetime-local';
export const formatToLocaleDate = (date: Date | string, format: DateFormatType, timeZone = 'America/New_York') => {
  if (!date) {
    return '';
  }

  if (typeof date === 'string') {
    date = new Date(date);
  }

  // const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
  let options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };

  switch (format) {
    case 'May 23, 2022, 11:06:29 PM': {
      options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
      };
      break;
    }

    case '5.23.2021 - 11:06 AM': {
      options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: undefined,
        hour12: true,
      };
      break;
    }

    case '5.23.22': {
      return `${padWithZero(date.getMonth() + 1)}.${padWithZero(date.getDate())}.${date
        .getFullYear()
        .toString()
        .slice(-2)}`;
    }

    case 'datetime-local':
      return `${date.getFullYear()}-${padWithZero(date.getMonth() + 1)}-${padWithZero(date.getDate())}T${padWithZero(
        date.getHours(),
      )}:${padWithZero(date.getMinutes())}:${padWithZero(date.getSeconds())}`;

    default:
  }

  return date.toLocaleDateString('en-US', {
    ...options,
    ...(timeZone ? { timeZone, timeZoneName: 'short' } : { timeZoneName: 'short' }),
  });
};

export const downloadFile = (filename: string, mimeType: string, data: any) => {
  const blob = new Blob([data], { type: mimeType });

  const windowNavigator = window.navigator as any;

  if (windowNavigator.msSaveOrOpenBlob) {
    windowNavigator.msSaveBlob(blob, filename);
  } else {
    const elem = window.document.createElement('a');
    elem.href = window.URL.createObjectURL(blob);
    elem.download = filename;
    document.body.appendChild(elem);
    elem.click();
    document.body.removeChild(elem);
  }
};

export const formatSubscriptionPrice = (currency: string, amount: number) => {
  const properAmount = amount / 100;

  return new Intl.NumberFormat('en-EN', {
    style: 'currency',
    currency: currency.toUpperCase(),
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(properAmount);
};

export const getImagePath = (relativePath: string) => `${config.backendUrl}/files/download/${relativePath}`;
