import React, { useEffect, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'reactstrap';
import { Link } from 'react-router-dom';

import { AuthContext } from 'src/components/AuthContextSetter';

import classname from 'classnames';

//Import Icons
import Icon from '@ailibs/feather-react-ts';

//i18n
import { withTranslation } from 'react-i18next';

//redux
import { useSelector } from 'react-redux';

const Navbar = (props: any) => {
  const authContext = useContext(AuthContext);

  const { leftMenu } = useSelector((state: any) => ({
    leftMenu: state.Layout.leftMenu,
  }));

  const [tools, settools] = useState<boolean>(false);

  useEffect(() => {
    if (authContext.ability.cannot('see', 'AdminMenu', 'Menubar')) {
      return;
    }

    var matchingMenuItem = null;
    var ul: any = document.getElementById('navigation');
    var items: any = ul.getElementsByTagName('a');
    for (var i = 0; i < items.length; ++i) {
      if (window.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  });

  if (authContext.ability.cannot('see', 'AdminMenu', 'Menubar')) {
    return null;
  }

  function activateParentDropdown(item: any) {
    item.classList.add('active');
    const parent = item.parentElement;
    if (parent) {
      parent.classList.add('active'); // li
      const parent2 = parent.parentElement;
      parent2.classList.add('active'); // li
      const parent3 = parent2.parentElement;
      if (parent3) {
        parent3.classList.add('active'); // li
        const parent4 = parent3.parentElement;
        if (parent4) {
          parent4.classList.add('active'); // li
          const parent5 = parent4.parentElement;
          if (parent5) {
            parent5.classList.add('active'); // li
            const parent6 = parent5.parentElement;
            if (parent6) {
              parent6.classList.add('active'); // li
            }
          }
        }
      }
    }
    return false;
  }

  return (
    <React.Fragment>
      <div className="topnav">
        <div className="container-fluid">
          <nav className="navbar navbar-light navbar-expand-lg topnav-menu" id="navigation">
            <Collapse isOpen={leftMenu} className="navbar-collapse" id="topnav-menu-content">
              <ul className="navbar-nav">
                <li className="nav-item dropdown">
                  <Link className="nav-link dropdown-toggle arrow-none" to="/ux">
                    <Icon name="home" />
                    <span>All</span>
                  </Link>
                </li>
                <li className="nav-item dropdown">
                  <Link className="nav-link dropdown-toggle arrow-none" to="/ux/dashboard">
                    <Icon name="home" />
                    <span>{props.t('Dashboard')}</span>
                  </Link>
                </li>
                <li className="nav-item dropdown">
                  <Link className="nav-link dropdown-toggle arrow-none" to="/tasks">
                    <Icon name="truck" />
                    <span>
                      {props.t('Tasks')} <span className="badge bg-danger ms-1">74</span>
                    </span>
                  </Link>
                </li>
                <li className="nav-item dropdown">
                  <Link className="nav-link dropdown-toggle arrow-none" to="/boxes">
                    <Icon name="package" />
                    <span>
                      {props.t('Boxes')} <span className="badge bg-light ms-1">2</span>
                    </span>
                  </Link>
                </li>
                <li className="nav-item dropdown">
                  <Link className="nav-link dropdown-toggle arrow-none" to="/ux/storages">
                    <Icon name="grid" />
                    <span>{props.t('Storages')}</span>
                  </Link>
                </li>
                <li className="nav-item dropdown">
                  <Link className="nav-link dropdown-toggle arrow-none" to="/ux/reports">
                    <Icon name="file" />
                    <span>{props.t('Reports')}</span>
                  </Link>
                </li>
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle arrow-none"
                    to="#"
                    onClick={(e) => {
                      e.preventDefault();
                      settools(!tools);
                    }}
                  >
                    <Icon name="tool" />
                    <span>{props.t('Tools')}</span>
                  </Link>
                  <div className={classname('dropdown-menu', { show: tools })}>
                    <Link className="dropdown-item" to="/labels">
                      <span>{props.t('Generate labels')}</span>
                    </Link>
                    <Link className="dropdown-item" to="/ai/taxonomy">
                      <span>{props.t('AI Taxonomy')}</span>
                    </Link>
                    <Link className="dropdown-item" to="/ai/tag-blacklist">
                      <span>{props.t('AI Tag blacklist')}</span>
                    </Link>
                  </div>
                </li>

                <li className="nav-item dropdown">
                  <Link className="nav-link dropdown-toggle arrow-none" to="/users">
                    <Icon name="users" />
                    <span>{props.t('Users')}</span>
                  </Link>
                </li>
              </ul>
            </Collapse>
          </nav>
        </div>
      </div>
    </React.Fragment>
  );
};

Navbar.propTypes = {
  leftMenu: PropTypes.any,
  location: PropTypes.any,
  menuOpen: PropTypes.any,
  t: PropTypes.any,
};

export default withTranslation()(Navbar);
