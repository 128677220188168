import React, { useMemo } from 'react';
import Async from 'react-select/async';
import { useLazyQuery } from '@apollo/client';

import { SEARCH_TAGS } from 'src/helpers/graphql';
import Label from './Label';
import { ReactSelectOption } from './ReactSelect';
import Errors, { ErrorsType } from './Errors';
import debounce from 'debounce-promise';
import { PackageTagSource } from 'src/types/types';
export interface TagSelectorProps {
  label?: string;
  isMulti?: boolean;
  defaultValue?: ReactSelectOption[];
  name?: string;
  disabled?: boolean;
  errors: ErrorsType;
  includeBlacklisted?: boolean;
  categoryId?: string;
  withoutCategory?: boolean;
  source?: PackageTagSource;
}

const TagSelector: React.FC<TagSelectorProps> = React.forwardRef(
  (
    {
      label,
      isMulti = false,
      defaultValue,
      errors,
      name,
      includeBlacklisted = false,
      withoutCategory,
      categoryId,
      source,
      ...rest
    },
    ref,
  ) => {
    const [getTags, { loading, error, data }] = useLazyQuery(SEARCH_TAGS);

    const debouncedLoadTags = useMemo(() => {
      const loadTags = async (inputValue: string): Promise<any> => {
        const res = await getTags({
          variables: {
            query: inputValue,
            includeBlacklisted,
            categoryId,
            withoutCategory,
            source,
          },
        });

        const tags = res?.data?.searchTags?.nodes?.map((tag: SearchPackageTag) => ({
          value: tag.id,
          label: tag.name,
          categoryIds: tag.categoryIds,
        }));

        return tags;
      };

      return debounce(loadTags, 500);
    }, [categoryId, getTags, includeBlacklisted, withoutCategory, source]);

    return (
      <div className="mb-3">
        <Label htmlFor={`formrow-${name}-input`} label={label} />
        <Async
          isMulti={isMulti}
          loadOptions={debouncedLoadTags}
          defaultValue={defaultValue}
          styles={{
            control: (original) => {
              return {
                ...original,
                minWidth: '150px',
              };
            },
          }}
          {...rest}
        />
        <Errors errors={errors} />
      </div>
    );
  },
);

export default TagSelector;
