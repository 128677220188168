import React, { useEffect } from 'react';
import { ApolloError } from '@apollo/client';
import { t } from 'i18next';
import _isEmpty from 'lodash/isEmpty';

import Modal, { ModalSizes } from 'src/components/Modal/Modal';
import ModalError from './ModalError';

type ModalFormProps = {
  show: boolean;
  setShow: Function;
  title: string;
  children: React.ReactNode;
  globalError?: string | ApolloError;
  loading?: boolean;
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  submitText?: string;
  cancelText?: string;
  size?: ModalSizes;
};

const ModalForm: React.FC<ModalFormProps> = ({
  show,
  setShow,
  title,
  children,
  globalError,
  loading,
  onSubmit,
  submitText = 'Confirm',
  cancelText = 'Close',
  size,
}) => {
  useEffect(() => {
    if (show) {
      document.body.classList.add('modal-open');
    }

    return () => {
      document.body.classList.remove('modal-open');
    };
  }, [show]);

  return (
    <Modal title={title} show={show} setShow={setShow} size={size}>
      <form onSubmit={onSubmit}>
        <div className="modal-body">
          <ModalError error={globalError} />
          {children}
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-soft-light"
            data-dismiss="modal"
            onClick={() => setShow(false)}
            disabled={loading}
          >
            {cancelText}
          </button>
          <button type="submit" className="btn btn-primary" disabled={loading}>
            {submitText}
          </button>
        </div>
      </form>
    </Modal>
  );
};

export const setFieldErrors = (err: any, setError: Function, setGlobalError: Function) => {
  if (!err || _isEmpty(err)) {
    setGlobalError('');
    return;
  }

  if (err.message) {
    const tkey = `Errors.Backend.${err.message}`;
    const translated = t(tkey);
    // string is possibly not translated
    if (tkey === translated) {
      console.warn(`Translation for ${tkey} was not found`);
      setGlobalError(t('Errors.Backend.DELIVERY_SERVICE_GENERIC_ERROR'));
      return;
    }

    setGlobalError(translated);
  }
  /*
  if ((err as any).type !== ErrorTypeValidationError) {
    return err;
  }

  const validationError = err as ValidationError;

  if (validationError.message) {
    setGlobalError(validationError.message);
  }

  _forEach(validationError.errors, (fieldErrors, fieldName) => {
    setError(fieldName, { type: 'server', message: fieldErrors });
  });
  */
};

export default ModalForm;
