import React, { useState, useEffect } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useMutation, ApolloError } from '@apollo/client';

import { EDIT_DELIVERY_REQUEST, ROLLBACK_DELIVERY_STATUS } from 'src/helpers/graphql';
import { DeliveryRequestDirection, DeliveryRequestStatus, UserContextRoleType, UserContextType } from 'src/types/types';
import Select, { toOption } from 'src/components/Forms/Select';
import ModalForm, { setFieldErrors } from 'src/components/Forms/ModalForm';
import { getDisplayName } from 'src/helpers/ui';

type Inputs = {
  scheduleType: string;
  storage: string;
  transporter: string;
};

type StatusToScheduledDialogProps = {
  show: boolean;
  setShow: Function;
  handleSingleOrBulkSubmit: Function;
  tasks: (DeliveryRequestListItem | DeliveryRequestDetails)[];
  transporters: Transporter[];
  storages: VirtualStorageListItem[];
};

const StatusToScheduledDialog: React.FC<StatusToScheduledDialogProps> = ({
  show,
  setShow,
  handleSingleOrBulkSubmit,
  tasks,
  transporters,
  storages,
}) => {
  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors },
  } = useForm<Inputs>();

  const [globalError, setGlobalError] = useState<string | ApolloError | undefined>('');

  const [editDeliveryRequest, { loading, error }] = useMutation(EDIT_DELIVERY_REQUEST);
  const [rollbackDeliveryStatus, { loading: rollbackLoading, error: rollbackError }] =
    useMutation(ROLLBACK_DELIVERY_STATUS);

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    const defaultParams: any = {
      transporterContextId: data.transporter,
      status: DeliveryRequestStatus.SCHEDULED,
    };

    if (tasks[0].direction !== DeliveryRequestDirection.STORAGE_TO_USER) {
      defaultParams.toVirtualStorageId = data.storage;
    }

    handleSingleOrBulkSubmit({
      data: tasks.map((task) => ({
        id: task.id,
        ...defaultParams,
      })),
      submitFn: tasks[0].status === 'AT_TRANSPORTER' ? rollbackDeliveryStatus : editDeliveryRequest,
      setFieldErrors: (err: any) => setFieldErrors(err, setError, setGlobalError),
    });
  };

  useEffect(() => {
    if (error || rollbackError) {
      const fieldError = rollbackError || error;
      setFieldErrors(fieldError, setError, setGlobalError);
    }
  }, [error, rollbackError]);

  useEffect(() => {
    reset();
  }, [tasks?.[0]?.id]);

  const storageOptions = storages.map((storage) => toOption(storage.id, storage.storage.name));

  const transporterOptions = transporters.map((transporter) =>
    toOption(
      transporter.userContexts.find(
        (context) => context.context === UserContextType.TRANSPORTER && context.role === UserContextRoleType.USER,
      )!.id,
      getDisplayName(transporter),
    ),
  );

  return (
    <ModalForm
      title="Schedule delivery"
      show={show}
      setShow={setShow}
      onSubmit={handleSubmit(onSubmit)}
      loading={loading || rollbackLoading}
      globalError={globalError}
    >
      {tasks[0].direction !== DeliveryRequestDirection.STORAGE_TO_USER ? (
        <Select
          label="Storage"
          selectOptions={storageOptions}
          defaultValue={tasks.length === 1 ? tasks[0].toVirtualStorageId : undefined}
          {...register('storage', { required: true })}
          disabled={loading}
          errors={errors.storage}
        />
      ) : null}
      <Select
        label="Transporter"
        selectOptions={transporterOptions}
        defaultValue={tasks.length === 1 ? tasks[0].transporterContextId : undefined}
        {...register('transporter', { required: true })}
        disabled={loading}
        errors={errors.transporter}
      />
    </ModalForm>
  );
};

export default StatusToScheduledDialog;
