import React, { useEffect, useState, useRef, MouseEvent } from 'react';
import { useLazyQuery } from '@apollo/client';
import { DocumentNode } from 'graphql';
import { Spinner } from 'reactstrap';
import { useHistory, useLocation } from 'react-router-dom';
import { Col, Row, Input } from 'reactstrap';
import styled from 'styled-components';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable, { SortOrder, ColumnDescription } from 'react-bootstrap-table-next';
import Pagination from 'react-responsive-pagination';

import {
  clearOldValues,
  prepareFilters,
  FilterOptionType,
  PaginatedResult,
  getFilterSettingsFromUrl,
  getNormalizedParamsFromUrl,
  replaceUrlWithSearchParams,
} from 'src/helpers/pagination';
import { tabStorage } from 'src/helpers/storage/settings';
import ResponseHandler from 'src/components/ResponseHandler';
import ItemsPerPage from './Pagination/ItemsPerPage';
import FilterDropdown from './FilterDropdown';

export interface DefaultSortedByType {
  dataField: any;
  order: SortOrder;
}

export interface FilteredTableProps<T> {
  items?: T[];
  setItems: (items: T[]) => void;
  getTableTitle?: (itemsLength?: number) => string;
  titleActions?: React.ReactNode;
  query: DocumentNode;
  queryVariables?: any;
  keyInResponse: string;
  savedFiltersKey?: string;
  wrapper: Function;
  defaultSortedBy: DefaultSortedByType[];
  columns: ColumnDescription[];
  generateUrlForEntity?: (item: T) => string;
  getBulkActions?: (selectedItems: T[]) => React.ReactNode;
  filterOptions?: FilterOptionType[];
  defaultFilterValue?: string;
  filterProcessor?: (filters: any) => any;
  paginated?: boolean;
}

const FilteredTable = <T extends { id: string }>({
  items,
  setItems,
  getTableTitle,
  titleActions,
  query,
  queryVariables,
  keyInResponse,
  savedFiltersKey,
  wrapper,
  defaultSortedBy,
  columns,
  generateUrlForEntity,
  getBulkActions,
  filterOptions,
  defaultFilterValue,
  filterProcessor,
  paginated = true,
}: FilteredTableProps<T>) => {
  const history = useHistory();
  const { search, pathname } = useLocation();

  const [queryList, { loading, error, data }] = useLazyQuery(query);
  const [renderedBulkActions, setRenderedBulkActions] = useState<React.ReactNode>(<></>);
  const [currentPageSize, setCurrentPageSize] = useState<number>(getNormalizedParamsFromUrl(search).pageSize);
  const [allResponseData, setAllResponseData] = useState<PaginatedResult | T[] | undefined>(undefined);
  const [searchValue, setSearchValue] = useState('');
  const tableRef = useRef() as React.MutableRefObject<BootstrapTable<any, number>>;

  const isFilteringEnabled = filterOptions && filterOptions.length > 0;

  const updateBulkActions = () => {
    if (!getBulkActions || !tableRef.current) {
      return;
    }

    const ids = (tableRef.current as any).selectionContext.selected;
    const selectedItems = ids.map((id: string) => items?.find((item) => item.id === id));
    setRenderedBulkActions(getBulkActions(selectedItems));
  };

  const getSavedFiltersKeyName = () => `table-filter-${savedFiltersKey}-v1`;

  const persistFilterValue = (value: any | null) => {
    if (value === null) {
      tabStorage.removeItem(getSavedFiltersKeyName(), value);
    } else {
      tabStorage.setItem(getSavedFiltersKeyName(), value);
    }
  };

  const getFilterValue = () => {
    if (!isFilteringEnabled) {
      return undefined;
    }

    const fromStorage = tabStorage.getItem(getSavedFiltersKeyName());
    const filter = fromStorage || defaultFilterValue;

    return getFilterSettingsFromUrl(search, filter);
  };

  const onSelectPageSize = (size: number) => {
    setCurrentPageSize(size);
    runListingQuery(
      {
        filter: getFilterValue(),
        pageSize: size,
      },
      true,
    );
  };

  const onPaginateToPage = (page: number) => {
    const { cursors } = allResponseData as PaginatedResult;
    //  console.log('paging', page, cursors);

    const data: any = {
      filter: getFilterValue(),
    };

    if (page > 1) {
      data.after = cursors[page - 2];
    }

    runListingQuery(data, true);
  };

  const onFilterChange = (filter: string) => {
    persistFilterValue(filter);

    runListingQuery(
      {
        filter,
      },
      true,
    );
  };

  const showPagination = () => {
    const allData = allResponseData as PaginatedResult;

    const { cursors } = allData;
    const totalPages = cursors.length + 1;

    if (totalPages === 1) {
      return null;
    }

    const currentPagesLastCursor = allData.edges[allData.edges.length - 1].cursor;

    // console.log('showPagination1', currentPagesLastCursor, cursors);

    let cursorFoundOnPage = cursors.findIndex((cursor) => cursor === currentPagesLastCursor);

    if (cursorFoundOnPage === -1) {
      cursorFoundOnPage = cursors.length + 1;
    }

    // console.log('showPagination2', cursorFoundOnPage, allData.edges[allData.edges.length - 1].cursor);

    return <Pagination current={cursorFoundOnPage + 1} total={totalPages} onPageChange={onPaginateToPage} />;
  };

  const rowEvents = {
    onClick: (ev: React.SyntheticEvent, row: T, rowIndex: number) => {
      if (generateUrlForEntity) {
        history.push(generateUrlForEntity(row));
      }
    },
  };

  useEffect(() => {
    if (data?.[keyInResponse]) {
      const items = paginated
        ? data?.[keyInResponse].edges.map((edge: { cursor: string; node: T }) => edge.node)
        : data?.[keyInResponse];
      setItems(items);
      setAllResponseData(data?.[keyInResponse]);
    }
  }, [data]);

  useEffect(() => {
    updateBulkActions();
  }, [items]);

  const runListingQuery = (data: any, updateUrl: boolean) => {
    if (data.pageSize) {
      data.first = data.pageSize;
      delete data.pageSize;
    } else {
      data.first = currentPageSize;
    }

    // removing old values that apollo may have restored
    const cleanedUpData = clearOldValues({
      ...data,
    });

    if (updateUrl) {
      const urlParams = { ...cleanedUpData };
      if (urlParams.first) {
        urlParams.pageSize = urlParams.first;
        delete urlParams.first;
      }
      replaceUrlWithSearchParams(urlParams, history, pathname);
    }

    const userSearchFields = ['User.firstName', 'User.lastName', 'User.email'];

    const getSearchFilters = (searchText: string, fields = userSearchFields) =>
      fields.map((field) => ({
        op: 'ILIKE',
        field,
        values: [searchText],
      }));

    const searchVariables = {
      operator: 'AND',
      childExpressions: [
        {
          operator: 'OR',
          filters: getSearchFilters(searchValue),
        },
      ],
    };

    queryList({
      variables: {
        ...(search ? searchVariables : {}),
        ...(queryVariables ? queryVariables : {}),
        ...cleanedUpData,
        ...(isFilteringEnabled ? filterProcessor!(prepareFilters(data.filter)) : {}),
      },
    });
  };

  // run once on load
  useEffect(() => {
    const queryParams = getNormalizedParamsFromUrl(search);

    runListingQuery(
      {
        after: queryParams.after,
        filter: getFilterValue(),
      },
      false,
    );
  }, [queryList]);

  useEffect(() => {
    if (error) {
      if (getFilterValue()) {
        // if there were some filter set, let's null that out, and try to refetch the list without them
        persistFilterValue(null);

        runListingQuery(
          {
            filter: defaultFilterValue,
          },
          true,
        );
      } else {
        // the list has some real problems, show the error page instead
        throw new Error();
      }
    }
  }, [error]);

  const handleDelete = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const inputElement = e.target as HTMLInputElement;
    if (inputElement.value === '') {
      runListingQuery(
        {
          filter: searchValue,
        },
        true,
      );
    }
  };

  if (loading || (!items && !error)) {
    return wrapper(<Spinner className="spinner--with-space" color="secondary" />);
  }

  return wrapper(
    <ResponseHandler loading={loading} error={error}>
      <Row className="align-items-start" style={{ minHeight: '3.5rem' }}>
        <Col lg="12">
          <div className="d-flex justify-content-between">
            <div>
              <h1>
                {getTableTitle
                  ? getTableTitle(
                      paginated
                        ? (allResponseData as PaginatedResult)?.totalCount
                        : (allResponseData as Array<T>).length,
                    )
                  : null}
              </h1>
            </div>

            {titleActions ? <div>{titleActions} </div> : null}
          </div>
        </Col>
      </Row>

      <Row>
        <Col lg="12">
          <ToolkitProvider keyField="id" data={items!} columns={columns} bootstrap4 search>
            {(toolkitProps) => (
              <React.Fragment>
                <Row className="mb-2">
                  <div className="d-flex flex-wrap gap-2">
                    {isFilteringEnabled ? (
                      <FilterDropdown
                        filterOptions={filterOptions}
                        currentValue={getFilterValue()}
                        loading={loading}
                        onFilterChange={onFilterChange}
                      />
                    ) : null}
                    {renderedBulkActions}
                  </div>
                </Row>
                {pathname === '/users' && (
                  <Row className="app-search d-none d-lg-block py-2">
                    <Col>
                      <Input
                        type="text"
                        value={searchValue}
                        className="form-control"
                        placeholder="Search ..."
                        onChange={(e) => setSearchValue(e.target.value)}
                        onKeyUp={(e) => handleDelete(e)}
                      />
                      <button
                        className="btn btn-primary"
                        type="submit"
                        onClick={() => {
                          runListingQuery(
                            {
                              filter: searchValue,
                            },
                            true,
                          );
                        }}
                      >
                        <i className="bx bx-search-alt align-middle"></i>
                      </button>
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col xl="12">
                    <div className="table-responsive">
                      {/* defaultSorted={defaultSortedBy as [{ dataField: any; order: SortOrder }]} */}
                      <BootstrapTable
                        ref={tableRef}
                        {...toolkitProps.baseProps}
                        classes={'table align-middle table-nowrap table-hover'}
                        bordered={false}
                        striped={false}
                        rowEvents={rowEvents}
                        {...(getBulkActions
                          ? {
                              selectRow: {
                                mode: 'checkbox',
                                onSelect: (row: T, isSelect: boolean, rowIndex: number, ev: any) => {
                                  // console.log('onSelect', (tableRef.current as any).selectionContext.selected);
                                  setTimeout(updateBulkActions);
                                },
                                onSelectAll: (isSelect: boolean, rows: T[], e: any) => {
                                  // console.log('onSelectAll', tableRef.current as any);
                                  setTimeout(updateBulkActions);
                                },
                              },
                            }
                          : {})}
                      />
                    </div>
                  </Col>
                </Row>
                {paginated ? (
                  <Row className="align-items-md-center mt-30">
                    <Col className="inner-custom-pagination d-block justify-content-between d-sm-flex">
                      <div className="d-inline">
                        <ItemsPerPage currentPageSize={currentPageSize} onSelectPageSize={onSelectPageSize} />
                      </div>
                      <Div2Col className="text-md-right ms-5">{showPagination()}</Div2Col>
                    </Col>
                  </Row>
                ) : null}
              </React.Fragment>
            )}
          </ToolkitProvider>
        </Col>
      </Row>
    </ResponseHandler>,
  );
};

export default FilteredTable;

const Div2Col = styled.div`
  max-width: 40%;
  flex-grow: 1;

  @media (max-width: 1200px) {
    max-width: 70%;
  }

  @media (max-width: 575.98px) {
    max-width: 100%;
    margin-left: 0 !important;
    margin-top: 10px;
  }
`;
