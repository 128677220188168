import React from 'react';
import MetaTags from 'react-meta-tags';

//import Breadcrumbs
import Breadcrumbs from 'src/ux/components/Breadcrumb';
import { Container, Row } from 'reactstrap';

/** import Mini Widget data */
import WalletBalance from './WalletBalance';
import Widgets from './Widgets';
import Timeline from 'src/components/Timeline';
import PriceAreaTable from './PriceAreaTable';

const options: Object = {
  chart: {
    height: 50,
    type: 'line',
    toolbar: { show: false },
  },
  colors: ['#5400CA'],
  stroke: {
    curve: 'smooth',
    width: 2,
  },
  xaxis: {
    labels: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
    axisBorder: {
      show: false,
    },
  },
  yaxis: {
    labels: {
      show: false,
    },
  },
  tooltip: {
    fixed: {
      enabled: false,
    },
    x: {
      show: false,
    },
    y: {
      title: {
        formatter: function (seriesName: any) {
          return '';
        },
      },
    },
    marker: {
      show: false,
    },
  },
};

const Dashboard = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Dashboard | Add + Space Admin</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Dashboard" breadcrumbItem="Dashboard" />

          <Row>
            <Widgets options={options} />
          </Row>
          <Row>
            <WalletBalance />
            <PriceAreaTable />
          </Row>
          <Timeline title="Latest activity" />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
