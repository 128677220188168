import MetaTags from 'react-meta-tags';
import React from 'react';
import { Row, Col, Alert, Container } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { userForgotPassword } from 'src/store/actions';
import logo from 'src/assets/images/logo-sm.svg';
import CarouselPage from 'src/ux/pages/AuthenticationInner/CarouselPage';

interface ForgotPasswordProps {
  history: object;
}

const ForgotPasswordPage = ({ history }: ForgotPasswordProps) => {
  const dispatch = useDispatch();

  const { loading, forgotError, forgotSuccess } = useSelector((state: any) => ({
    loading: state.forgotPassword?.loading,
    forgotError: state.forgotPassword?.forgotError,
    forgotSuccess: state.forgotPassword?.forgotSuccess,
  }));

  function handleValidSubmit(event: any, values: any) {
    dispatch(userForgotPassword(values, history));
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>Forgot Password | Add + Space Admin</title>
      </MetaTags>
      <div className="auth-page">
        <Container fluid className="p-0">
          <Row className="g-0">
            <Col lg={4} md={5} className="col-xxl-3">
              <div className="auth-full-page-content d-flex p-sm-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-5">
                      <Link to="/" className="d-block auth-logo">
                        <img src={logo} alt="" height="32" /> <span className="logo-txt">Admin</span>
                      </Link>
                    </div>
                    <div className="auth-content my-auto">
                      <div>
                        <h1 className="mb-0">Reset Password</h1>
                        <p className="text-muted mt-2">Enter your email and instructions will be sent to you</p>
                      </div>

                      {forgotError && forgotError ? (
                        <Alert color="danger" style={{ marginTop: '13px' }}>
                          {forgotError}
                        </Alert>
                      ) : null}
                      {forgotSuccess ? (
                        <Alert color="success" style={{ marginTop: '13px' }}>
                          {forgotSuccess}
                        </Alert>
                      ) : null}

                      <AvForm className="custom-form mt-4" onValidSubmit={(e: any, v: any) => handleValidSubmit(e, v)}>
                        <div className="mb-3">
                          <AvField
                            name="email"
                            label="Email"
                            className="form-control"
                            placeholder="Your email"
                            type="email"
                            required
                          />
                        </div>
                        <div className="mb-3 mt-4">
                          <button
                            className="btn btn-primary w-100 waves-effect waves-light"
                            type="submit"
                            disabled={loading}
                          >
                            Reset
                          </button>
                        </div>
                      </AvForm>

                      <div className="mt-5 text-center">
                        <p className="text-muted mb-0">
                          Remember It?{' '}
                          <Link to="/login" className="text-primary fw-semibold">
                            {' '}
                            Log In{' '}
                          </Link>{' '}
                        </p>
                      </div>
                    </div>
                    <div className="mt-4 mt-md-5">
                      <p className="mb-0">© {new Date().getFullYear()} Add + Space</p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <CarouselPage />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ForgotPasswordPage);
