import React, { useState } from 'react';
import MetaTags from 'react-meta-tags';
import { useParams, useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { VIRTUAL_STORAGES_WITH_STORAGE } from 'src/helpers/graphql';
import { formatAddress, formatToLocaleDate, getDisplayName } from 'src/helpers/ui';
import ResponseHandler from 'src/components/ResponseHandler';
import Breadcrumbs from 'src/components/Breadcrumbs';
import StorageStatusBadge from 'src/components/Storage/StorageStatusBadge';
import EditVirtualStorageDialog from './EditVirtualStorageDialog';
import PackageList from './PackageList';

export interface StorageDetailsProps {}

const StorageDetails: React.FC<StorageDetailsProps> = () => {
  const { storageId } = useParams<{ storageId: string }>();

  const getVirtualStorageQuery = useQuery(VIRTUAL_STORAGES_WITH_STORAGE, { variables: { id: storageId } });
  const history = useHistory();
  const { t } = useTranslation();
  const [show, setShow] = useState(false);

  const renderPageInternals = (virtualStorage: VirtualStorageDetails) => {
    if (!virtualStorage) {
      if (!getVirtualStorageQuery.loading) {
        history.push('/storages');
      }
      return null;
    }

    return (
      <>
        <Row>
          <h1 className="mb-3">{virtualStorage.storage.name || t('StorageDetails.unnamed')}</h1>
          <Col lg="3">
            <Card>
              <CardHeader className="align-items-center d-flex">
                <h4 className="card-title mb-0 flex-grow-1">
                  <i className="mdi mdi-grid me-3"></i>
                  {t('Common.details')}
                </h4>
                <button type="button" className="btn btn-soft-light btn-sm me-1" onClick={() => setShow(true)}>
                  <i className="mdi mdi-pencil label-icon"></i>
                </button>
                <UncontrolledDropdown>
                  <DropdownToggle className="btn btn-soft-light btn-sm" tag="a">
                    <i className="mdi mdi-dots-horizontal label-icon" />
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-end">
                    <li>
                      <DropdownItem to="#">{t('Common.approve')}</DropdownItem>
                    </li>
                    <li>
                      <DropdownItem to="#">{t('Common.disapprove')}</DropdownItem>
                    </li>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </CardHeader>
              <CardBody>
                <h2>
                  <StorageStatusBadge status={virtualStorage.storage.type} size="lg" />
                </h2>
                <h5>
                  {virtualStorage.user ? (
                    <>
                      <a href={`/users/${virtualStorage.user.id}`}>{getDisplayName(virtualStorage.user)}</a> <br />
                    </>
                  ) : null}
                  {virtualStorage.address ? formatAddress(virtualStorage.address, { format: '2lines' }) : null}
                </h5>
                <p>+1 555 123456789 !FIXME</p>
                <h5 className="text-muted mb-1 mt-4">{t('Common.details')}</h5>
                <p>
                  {t('StorageDetails.usage')}: 2000 !FIXME
                  <br />
                  {t('StorageDetails.volume')}: {virtualStorage.storage.volume}
                  <br />
                  {t('StorageDetails.dimensions')}: {t('StorageDetails.widthAbbr')}
                  {virtualStorage.storage.width}
                  {t('StorageDetails.heightAbbr')}H{virtualStorage.storage.height} {t('StorageDetails.depthAbbr')}L
                  {virtualStorage.storage.depth}.
                  <br />
                  {t('Common.lastChanged')}: {formatToLocaleDate(virtualStorage.updatedAt, '5.23.22')}
                  {/*
                  <br />
                    {t('Common.approved_by')}: Employee Name !FIXME
                  */}
                </p>
              </CardBody>
            </Card>
          </Col>
          <Col lg="9">
            <PackageList virtualStorage={virtualStorage} />
          </Col>
        </Row>
        <EditVirtualStorageDialog show={show} setShow={setShow} virtualStorage={virtualStorage} />
      </>
    );
  };

  return (
    <ResponseHandler loading={getVirtualStorageQuery.loading}>
      <div className="page-content">
        <MetaTags>
          <title>
            {t('StorageDetails.title')} | {t('Common.title.staticPart')}
          </title>
        </MetaTags>

        <Container fluid>
          <Breadcrumbs
            items={[
              {
                label: t('Storages.title'),
                path: '#',
              },
              {
                label: t('StorageDetails.title'),
                path: `/packages/${storageId}`,
              },
            ]}
          />

          {renderPageInternals(getVirtualStorageQuery.data?.virtualStoragesWithStorage as VirtualStorageDetails)}
        </Container>
      </div>
    </ResponseHandler>
  );
};
export default StorageDetails;
