import React, { useState, useEffect } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useMutation, ApolloError } from '@apollo/client';

import { EDIT_DELIVERY_REQUEST } from 'src/helpers/graphql';
import Select, { toOption } from 'src/components/Forms/Select';
import ModalForm, { setFieldErrors } from 'src/components/Forms/ModalForm';

type Inputs = {
  scheduleType: string;
  storage: string;
  transporter: string;
};

type SelectStorageProps = {
  show: boolean;
  setShow: Function;
  handleSingleOrBulkSubmit: Function;
  tasks: (DeliveryRequestListItem | DeliveryRequestDetails)[];
  storages: VirtualStorageListItem[];
};

const SelectStorage: React.FC<SelectStorageProps> = ({ show, setShow, handleSingleOrBulkSubmit, tasks, storages }) => {
  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors },
  } = useForm<Inputs>();

  const [globalError, setGlobalError] = useState<string | ApolloError | undefined>('');

  const [editDeliveryRequest, { data, loading, error }] = useMutation(EDIT_DELIVERY_REQUEST);

  const onSubmit: SubmitHandler<Inputs> = async (data) =>
    handleSingleOrBulkSubmit({
      data: tasks.map((task) => ({
        id: task.id,
        toVirtualStorageId: data.storage,
      })),
      submitFn: editDeliveryRequest,
      setFieldErrors: (err: any) => setFieldErrors(err, setError, setGlobalError),
    });

  useEffect(() => {
    setFieldErrors(error, setError, setGlobalError);
  }, [error]);

  useEffect(() => {
    reset();
  }, [tasks?.[0]?.id]);

  const storageOptions = storages.map((storage) => toOption(storage.id, storage.storage.name));

  return (
    <ModalForm
      title="Select storage"
      show={show}
      setShow={setShow}
      onSubmit={handleSubmit(onSubmit)}
      loading={loading}
      globalError={globalError}
    >
      <Select
        label="Storage"
        selectOptions={storageOptions}
        defaultValue={tasks.length === 1 ? tasks[0].toVirtualStorageId : undefined}
        {...register('storage', { required: true })}
        disabled={loading}
        errors={errors.storage}
      />
    </ModalForm>
  );
};

export default SelectStorage;
