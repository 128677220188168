import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Card, CardHeader, CardBody, Spinner } from 'reactstrap';

import { getAddressTexts, getDisplayName } from 'src/helpers/ui';
import UserAvatar from 'src/components/UserAvatar';
import DeliveryRequestStatusBadge from 'src/components/DeliveryRequest/DeliveryRequestStatusBadge';

interface TransporterCardProps {
  packageData: Package;
  packageInfo: PackageInfoDetails;
  task: DeliveryRequestDetails;
}

const TransporterCard: React.FC<TransporterCardProps> = ({ packageData, packageInfo, task }) => {
  const { t } = useTranslation();
  const history = useHistory();

  if (!packageInfo.lastDeliveryInfo || !task) {
    return null;
  }

  const transporter = task?.transporterUser;
  const toAddress = getAddressTexts(task.toAddress, { format: '2lines' });

  const navigateToTaskDetails = () => {
    history.push(`/tasks/${packageInfo.lastDeliveryInfo?.id}`);
  };

  return (
    <Card>
      <CardHeader className="align-items-center d-flex">
        <h4 className="card-title mb-0 flex-grow-1">
          <i className="mdi mdi-grid me-3"></i>
          {t('Common.details')}
        </h4>

        <button type="button" className="btn btn-soft-light btn-sm me-1" onClick={navigateToTaskDetails}>
          <i className="mdi mdi-pencil label-icon"></i>
        </button>
      </CardHeader>
      <CardBody>
        <h2>
          <DeliveryRequestStatusBadge status={task.status} direction={task.direction} />
        </h2>

        {transporter ? (
          <>
            <h5 className="text-muted mb-0 mt-4">{t('Common.transporter')}</h5>
            <div className="avatar-xl mb-2 mt-2">
              <UserAvatar className="img-fluid rounded-circle d-block" />
            </div>
            <h5>
              <a href={`/users/${transporter.id}`}>{getDisplayName(transporter)}</a> <br />
              !FIXME +1 555 12345678
            </h5>
          </>
        ) : null}
        <h5 className="text-muted mb-0 mt-4">Destination</h5>
        <p>
          <a href={`/users/${task.userId}`}>{getDisplayName(task.user)}</a>
          <br />
          {toAddress[0]}
          <br />
          {toAddress[1]}
        </p>
      </CardBody>
    </Card>
  );
};

export default TransporterCard;
