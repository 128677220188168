import React from 'react';
import classnames from 'classnames';
import { Badge } from 'reactstrap';
import styled from 'styled-components';
import { t } from 'i18next';

import { StorageStatus } from 'src/types/types';

type StorageStatusBadgeProps = {
  status?: StorageStatus;
  size?: 'md' | 'lg';
};

const StorageStatusBadge: React.FC<StorageStatusBadgeProps> = ({ status, size = 'md' }) => {
  if (!status) {
    return null;
  }

  const classList = classnames(status, {
    rounded: size === 'md',
    'mb-2': size === 'lg',
    'bg-success text-dark': status === StorageStatus.ACTIVE,
    'bg-dark': status === StorageStatus.CREATED || status === StorageStatus.UNDER_REVIEW,
    'bg-danger': status === StorageStatus.DECLINED,
  });

  const text = t(`Storage.status.${status}`);

  if (size === 'md') {
    return <StyledBadge className={classList}>{text}</StyledBadge>;
  }

  return <Badge className={classList}>{text}</Badge>;
};

export default StorageStatusBadge;

const StyledBadge = styled(Badge)`
  margin-right: 5px;

  &:last-child {
    margin-right: 0;
  }
`;
