import React from 'react';
import { Col, NavItem } from 'reactstrap';

import { DeliveryRequestStatus } from 'src/types/types';
import { getAddressTexts, formatToLocaleDate, getDisplayName } from 'src/helpers/ui';

import classnames from 'classnames';
import { t } from 'i18next';

interface TaskProgressProps {
  task: DeliveryRequestDetails;
}

const TaskProgress: React.FC<TaskProgressProps> = ({ task }) => {
  const stateCancelled = task.status === DeliveryRequestStatus.CANCELLED;
  const stateMissed = task.status === DeliveryRequestStatus.MISSED;
  const stateDelivered = task.status === DeliveryRequestStatus.DELIVERED;
  const stateAtTransporter = task.status === DeliveryRequestStatus.AT_TRANSPORTER;
  const stateScheduled = task.status === DeliveryRequestStatus.SCHEDULED;
  const stateCreated = task.status === DeliveryRequestStatus.CREATED;

  const renderTimeline = () => {
    return (
      <ul className="twitter-bs-wizard-nav nav-justified nav mb-5">
        <NavItem>
          <span
            className={classnames('nav-link ', {
              active: stateCreated,
            })}
          >
            <div className="step-icon" data-bs-toggle="tooltip" data-bs-placement="top" title="Created">
              <i className="mdi mdi-star"></i>
            </div>
          </span>
        </NavItem>
        <NavItem>
          <span
            className={classnames('nav-link', {
              active: stateScheduled,
            })}
          >
            <div className="step-icon" data-bs-toggle="tooltip" data-bs-placement="top" title="Scheduled">
              <i className="mdi mdi-account-clock"></i>
            </div>
          </span>
        </NavItem>
        <NavItem>
          <span
            className={classnames('nav-link', {
              active: stateAtTransporter,
            })}
          >
            <div className="step-icon" data-bs-toggle="tooltip" data-bs-placement="top" title="At driver">
              <i className="mdi mdi-truck"></i>
            </div>
          </span>
        </NavItem>
        <NavItem>
          <span
            className={classnames('nav-link', {
              active: stateDelivered,
            })}
          >
            <div className="step-icon" data-bs-toggle="tooltip" data-bs-placement="top" title="Delivered">
              <i className="mdi mdi-check-circle"></i>
            </div>
          </span>
        </NavItem>
      </ul>
    );
  };

  if (stateCancelled) {
    return (
      <div className="twitter-bs-wizard pt-5">
        <div className="twitter-bs-wizard-tab-content">
          <div className="row justify-content-center">
            <Col lg="6">
              <div className="text-center">
                <div className="mb-3">
                  <i className="mdi mdi-cancel display-4"></i>
                </div>
                <div>
                  <h2>{t('DeliveryRequest.status.CANCELLED')}</h2>
                  <p className="text-muted">{t('DeliveryRequest.details.cancelled')}</p>
                </div>
              </div>
            </Col>
          </div>
        </div>
        {renderTimeline()}
      </div>
    );
  }

  if (stateMissed) {
    return (
      <div className="twitter-bs-wizard pt-5">
        <div className="twitter-bs-wizard-tab-content">
          <div className="row justify-content-center">
            <Col lg="6">
              <div className="text-center">
                <div className="mb-3">
                  <i className="mdi mdi-call-missed display-4"></i>
                </div>
                <div>
                  <h2>{t('DeliveryRequest.status.MISSED')}</h2>
                  <p className="text-muted">{t('DeliveryRequest.details.missed')}</p>
                </div>
              </div>
            </Col>
          </div>
        </div>
        {renderTimeline()}
      </div>
    );
  }

  if (stateDelivered) {
    return (
      <div className="twitter-bs-wizard pt-5">
        <div className="twitter-bs-wizard-tab-content">
          <div className="row justify-content-center">
            <Col lg="6">
              <div className="text-center">
                <div className="mb-3">
                  <i className="mdi mdi-check-circle text-success display-4"></i>
                </div>
                <div>
                  <h2>{t('Common.done')}</h2>
                  <p className="text-muted">
                    {t('DeliveryRequest.details.completed_at')}{' '}
                    {formatToLocaleDate(task.dropOffAt!, '5.23.2021 - 11:06 AM')} {t('Common.by')}{' '}
                    <strong>
                      <a href={`/users/${task.transporterContextId}`}>{getDisplayName(task.transporterUser)}</a>.
                    </strong>{' '}
                    {t('DeliveryRequest.details.final_destionation')}{' '}
                    <strong>{getAddressTexts(task.toAddress, { format: 'short' })}</strong>.
                  </p>
                </div>
              </div>
            </Col>
          </div>
        </div>
        {renderTimeline()}
      </div>
    );
  }

  if (stateAtTransporter) {
    return (
      <div className="twitter-bs-wizard pt-5">
        <div className="twitter-bs-wizard-tab-content">
          <div className="row justify-content-center">
            <Col lg="6">
              <div className="text-center">
                <div className="mb-3">
                  <i className="mdi mdi-truck text-muted display-4"></i>
                </div>
                <div>
                  <h2>{t('DeliveryRequest.details.at_driver')}</h2>
                  <p className="text-muted">
                    {t('DeliveryRequest.details.picked_up_at')}{' '}
                    {formatToLocaleDate(task.pickupAt, '5.23.2021 - 11:06 AM')} {t('Common.by')}{' '}
                    <strong>
                      <a href={`/users/${task.transporterContextId}`}>{getDisplayName(task.transporterUser)}</a>
                    </strong>{' '}
                    {t('DeliveryRequest.details.in_transit')}{' '}
                    <strong>{getAddressTexts(task.toAddress, { format: 'short' })}</strong>.
                  </p>
                </div>
              </div>
            </Col>
          </div>
        </div>
        {renderTimeline()}
      </div>
    );
  }

  if (stateScheduled) {
    return (
      <div className="twitter-bs-wizard pt-5">
        <div className="twitter-bs-wizard-tab-content">
          <div className="row justify-content-center">
            <Col lg="6">
              <div className="text-center">
                <div className="mb-3">
                  <i className="mdi mdi-account-clock text-muted display-4"></i>
                </div>
                <div>
                  <h2>{t('DeliveryRequest.status.SCHEDULED')}</h2>
                  <p className="text-muted">
                    {t('Common.assigned_to')}{' '}
                    <strong>
                      <a href={`/users/${task.transporterContextId}`}>{getDisplayName(task.transporterUser)}</a>
                    </strong>{' '}
                    {t(`DeliveryRequest.scheduleTypeFor.${task.scheduleType}`)}.
                  </p>
                </div>
              </div>
            </Col>
          </div>
        </div>
        {renderTimeline()}
      </div>
    );
  }

  return (
    <div className="twitter-bs-wizard pt-5">
      <div className="twitter-bs-wizard-tab-content">
        <div className="row justify-content-center">
          <Col lg="6">
            <div className="text-center">
              <div className="mb-3">
                <i className="mdi mdi-star text-muted display-4"></i>
              </div>
              <div>
                <h2>{t('DeliveryRequest.status.CREATED')}</h2>
                <p className="text-muted">
                  {t('DeliveryRequest.details.created_by')}{' '}
                  <strong>
                    <a href={`/users/${task.userId}`}>{getDisplayName(task.user)}</a>
                  </strong>{' '}
                  @ {formatToLocaleDate(task.pickupAt, '5.23.2021 - 11:06 AM')}
                </p>
              </div>
            </div>
          </Col>
        </div>
      </div>
      {renderTimeline()}
    </div>
  );
};

export default TaskProgress;
