import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router';

import { AuthContext } from 'src/components/AuthContextSetter';
import Auth from 'src/helpers/auth/auth';

const onAuthFail = (onFailRedirectTo: string | null, routeProps: any, user?: Auth) => {
  if (onFailRedirectTo) {
    return (
      <Redirect
        to={{
          pathname: onFailRedirectTo,
          state: { from: routeProps.location },
        }}
      />
    );
  }

  return (
    <Redirect
      to={{
        pathname: user?.getHomeUrl() || '/login',
        state: { from: routeProps.location },
      }}
    />
  );
};

interface AuthorizedRouteProps {
  path: string;
  exact?: boolean;
  Component: any;
  Layout: any;
  authRule: [action: any, subject: any, field?: string | undefined];
  onFailRedirectTo?: string;
}

const AuthorizedRoute: React.FC<AuthorizedRouteProps> = ({
  Component,
  Layout,
  onFailRedirectTo,
  authRule,
  ...rest
}) => {
  const authContext = useContext(AuthContext);

  const [authRule1, authRule2, ...restAuthRules] = authRule;

  // console.log('///', rest.path, authRule, authContext.ability.can(authRule1, authRule2, ...restAuthRules));

  const renderComponent = () => {
    return (
      <Layout>
        <Component />
      </Layout>
    );
  };

  return (
    <Route
      {...rest}
      render={(routeProps) =>
        authContext.ability.can(authRule1, authRule2, ...restAuthRules)
          ? renderComponent()
          : onAuthFail(onFailRedirectTo || null, routeProps, authContext.auth)
      }
    />
  );
};

export default AuthorizedRoute;
