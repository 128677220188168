import React, { useEffect, useState, useContext } from 'react';
import MetaTags from 'react-meta-tags';
import { Container } from 'reactstrap';
import { t } from 'i18next';

import { GET_CURRENT_USER } from 'src/helpers/graphql';
import { useLazyQuery } from '@apollo/client';

import { GET_PACKAGE_REQUESTS } from 'src/helpers/graphql';
import { PackageRequestStatus } from 'src/types/types';
import { getAddressTexts, getDisplayName } from 'src/helpers/ui';
import { AuthContext } from 'src/components/AuthContextSetter';
import { NO_FILTER_VALUE } from 'src/helpers/pagination';
import Breadcrumbs from 'src/components/Breadcrumbs';
import AssignBoxesDialog, { DialogModes } from './AssignBoxesDialog';
import FilteredTable, { DefaultSortedByType } from 'src/components/FilteredTable/FilteredTable';

const defaultSortedBy: DefaultSortedByType[] = [
  {
    dataField: 'id',
    order: 'asc',
  },
];

type DialogProps = {
  show: boolean;
  mode?: DialogModes;
  packageRequest?: PackageRequestType;
};

const PackageRequests: React.FC<{}> = () => {
  const authContext = useContext(AuthContext);
  const [packageRequests, setPackageRequests] = useState<PackageRequestType[] | undefined>(undefined);
  const [editDialog, setEditDialog] = useState<DialogProps>({
    show: false,
    mode: undefined,
    packageRequest: undefined,
  });
  const [userRole, setUserRole] = useState<string>('');
  const [getMe] = useLazyQuery(GET_CURRENT_USER);

  useEffect(() => {
    getMe()
      .then((userData) => setUserRole(userData?.data?.me?.userContexts[0]?.role))
      .catch((err) => {
        setUserRole('unavailable');
      });
  }, []);

  const columns = [
    {
      dataField: 'status',
      text: 'Status',
      sort: true,
    },

    {
      dataField: 'customer',
      text: 'Customer',
      sort: true,
      formatter: (cell: any, row: PackageRequestType, rowIndex: number) => getDisplayName(row.user!),
    },
    {
      dataField: 'address',
      text: 'Deliver to',
      formatter: (cell: any, row: PackageRequestType, rowIndex: number) =>
        row.virtualStorage?.address ? getAddressTexts(row.virtualStorage?.address, { format: 'short' }) : '',
      sort: true,
    },
    {
      dataField: 'amount',
      text: '# boxes',
      sort: true,
    },
    {
      dataField: 'actions',
      text: 'Assign',
      sort: true,
      formatter: (cell: any, row: PackageRequestType, rowIndex: number) => {
        const actions = [
          <button
            type="button"
            className="btn btn-soft-light me-2"
            key="Edit"
            title="Edit"
            onClick={() => {
              setEditDialog({
                show: true,
                packageRequest: row,
                mode: 'edit',
              });
            }}
          >
            <i className="mdi mdi-lead-pencil"></i>
          </button>,
        ];

        if (row.status !== PackageRequestStatus.REQUESTED) {
          return actions;
        }

        actions.push(
          <button
            type="button"
            className="btn btn-soft-light me-2"
            key="Assign"
            title="Assign"
            onClick={() => {
              setEditDialog({
                show: true,
                packageRequest: row,
                mode: 'assign',
              });
            }}
          >
            <i className="mdi mdi-qrcode"></i>
          </button>,
        );
        if (row.status === PackageRequestStatus.REQUESTED && authContext.auth.isAdmin()) {
          actions.push(
            <button
              type="button"
              className="btn btn-soft-light me-2 border border-danger text-danger border-1"
              key="Delete"
              title="Delete"
              onClick={() => {
                setEditDialog({
                  show: true,
                  packageRequest: row,
                  mode: 'delete',
                });
              }}
            >
              <i className="mdi mdi-delete"></i>
            </button>,
          );
        }
        return actions;
      },
    },
  ];

  const wrapper = (children: React.ReactNode) => (
    <div className="page-content">
      <MetaTags>
        <title>Boxes | Add + Space Admin</title>
      </MetaTags>
      <Container fluid>
        <Breadcrumbs
          items={[
            { label: 'Boxes', path: '#' },
            { label: 'Box requests', path: '#' },
          ]}
        />

        {children}
      </Container>
    </div>
  );

  const setItems = (packageRequests: PackageRequestType[]) => {
    setPackageRequests(packageRequests);
  };

  const hideEditDialog = () => {
    setEditDialog({
      show: false,
      packageRequest: undefined,
      mode: undefined,
    });
  };

  const filterProcessor = (filters: any) => {
    if (!filters.filters) {
      return {};
    }

    if (Array.isArray(filters.filters)) {
      return { filters: [{ op: 'IN', field: 'PackageRequest.status', values: filters.filters }] };
    }

    return { filters: [{ op: 'EQ', field: 'PackageRequest.status', values: filters.filters }] };
  };

  return (
    <>
      <FilteredTable
        items={packageRequests}
        setItems={setItems}
        getTableTitle={(itemsLength?: number) => `${itemsLength} box requests`}
        query={GET_PACKAGE_REQUESTS}
        keyInResponse="allPackageRequests"
        savedFiltersKey="packageRequests"
        wrapper={wrapper}
        columns={columns}
        defaultSortedBy={defaultSortedBy}
        filterOptions={[
          {
            value: NO_FILTER_VALUE,
            label: 'All',
          },
          { value: '', label: '---' },
          {
            value: PackageRequestStatus.REQUESTED,
            label: t(`PackageRequest.status.${PackageRequestStatus.REQUESTED}`),
          },
          {
            value: PackageRequestStatus.IN_PROGRESS,
            label: t(`PackageRequest.status.${PackageRequestStatus.IN_PROGRESS}`),
          },
          { value: PackageRequestStatus.SHIPPED, label: t(`PackageRequest.status.${PackageRequestStatus.SHIPPED}`) },
        ]}
        defaultFilterValue={NO_FILTER_VALUE}
        filterProcessor={filterProcessor}
        /*
        titleActions={
          <Link to="#" className="btn btn-primary" onClick={addUser} data-toggle="modal" data-target="#myModal">
            <i className="mdi mdi-plus-thick me-1"></i> New user
          </Link>
        }
        */
      />

      {editDialog.show && editDialog.packageRequest ? (
        <AssignBoxesDialog packageRequest={editDialog.packageRequest} onHide={hideEditDialog} mode={editDialog.mode} />
      ) : null}
    </>
  );
};

export default PackageRequests;
