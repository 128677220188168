import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import Modal from 'src/components/Modal/Modal';
import ModalError from 'src/components/Forms/ModalError';
import { ConfirmContext } from 'src/components/ConfirmContextSetter';

interface ConfirmModalProps {}

const ConfirmModal: React.FC<ConfirmModalProps> = (props) => {
  const confirmModal = useContext(ConfirmContext);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const { t } = useTranslation();

  if (!confirmModal.show) {
    return null;
  }

  const onConfirm = async () => {
    confirmModal.disable();

    try {
      confirmModal.disable();
      await confirmModal.options.onConfirm();

      if (confirmModal.options.successMessage) {
        setSuccess(true);
      } else {
        confirmModal.hide();
      }
      confirmModal.enable();
    } catch (err: any) {
      setError(err);
      confirmModal.enable();
    }
  };

  const onCancel = async () => {
    confirmModal.options.onCancel && (await confirmModal.options.onCancel());
    confirmModal.hide();
  };

  const setShow = (visible: boolean) => {
    if (visible) {
      confirmModal.show(confirmModal.options);
    } else {
      confirmModal.hide();
    }
  };

  const onCloseSuccessDialog = () => {
    setSuccess(false);
    confirmModal.hide();
  };

  const actionText = confirmModal.options.actionText ? confirmModal.options.actionText : t('Common.yes');

  return (
    <Modal show={confirmModal.visible} setShow={setShow} title={confirmModal.options.modalTitle} size="me">
      {!success ? (
        <>
          <div className="modal-body">
            <p
              dangerouslySetInnerHTML={{
                __html: confirmModal.options.modalText,
              }}
            />

            <ModalError error={error} />
          </div>

          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-soft-light"
              data-dismiss="modal"
              onClick={() => onCancel()}
              disabled={confirmModal.disabled}
            >
              {t('Common.no')}
            </button>
            <button type="submit" className="btn btn-primary" onClick={onConfirm} disabled={confirmModal.disabled}>
              {confirmModal.disabled ? confirmModal.options.pendingActionText : actionText}
            </button>
          </div>
        </>
      ) : (
        <>
          <div className="modal-body">
            <p>{confirmModal.options.successMessage}</p>
          </div>

          <div className="modal-footer">
            <button type="submit" className="btn btn-primary" onClick={onCloseSuccessDialog}>
              {t('Common.close')}
            </button>
          </div>
        </>
      )}
    </Modal>
  );
};

export default ConfirmModal;
