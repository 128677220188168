import React from 'react';
import { Card, CardBody, CardHeader, CardTitle, Col } from 'reactstrap';

//SimpleBar
import SimpleBar from 'simplebar-react';

export interface HistoryProps {
  title?: string;
}

const Timeline: React.FC<HistoryProps> = ({ title }) => {
  return (
    <React.Fragment>
      <Col>
        <Card>
          <CardHeader className="align-items-center d-flex">
            <CardTitle className="mb-0 flex-grow-1">{title}</CardTitle>
          </CardHeader>

          <CardBody className="px-0">
            <SimpleBar className="px-3" style={{ maxHeight: '500px' }}>
              <ul className="list-unstyled activity-wid mb-0">
                <li className="activity-list activity-border">
                  <div className="activity-icon avatar-md">
                    <span className="avatar-title text-white rounded-circle">
                      <i className="mdi mdi-star font-size-24"></i>
                    </span>
                  </div>
                  <div className="timeline-list-item">
                    <div className="d-flex">
                      <div className="flex-grow-1 overflow-hidden me-4">
                        <h5 className="font-size-14 mb-1">
                          X created by <a href="/users/user">Name</a> (role)
                        </h5>
                        <p className="text-truncate text-muted font-size-13">24.05.2021 @ 06:24:56 AM</p>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="activity-list activity-border">
                  <div className="activity-icon avatar-md">
                    <span className="avatar-title text-white rounded-circle">
                      <i className="mdi mdi-account-clock font-size-24"></i>
                    </span>
                  </div>
                  <div className="timeline-list-item">
                    <div className="d-flex">
                      <div className="flex-grow-1 overflow-hidden me-4">
                        <h5 className="font-size-14 mb-1">
                          X scheduled by <a href="/users/user">Name</a> (role)
                        </h5>
                        <p className="text-truncate text-muted font-size-13">24.05.2021 @ 06:24:56 AM</p>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="activity-list activity-border">
                  <div className="activity-icon avatar-md">
                    <span className="avatar-title bg-warning text-white rounded-circle">
                      <i className="mdi mdi-cancel font-size-24"></i>
                    </span>
                  </div>
                  <div className="timeline-list-item">
                    <div className="d-flex">
                      <div className="flex-grow-1 overflow-hidden me-4">
                        <h5 className="font-size-14 mb-1">
                          X cancelled by <a href="/users/user">Name</a> (role)
                        </h5>
                        <p className="text-truncate text-muted font-size-13">24.05.2021 @ 06:24:56 AM</p>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="activity-list activity-border">
                  <div className="activity-icon avatar-md">
                    <span className="avatar-title bg-danger text-white rounded-circle">
                      <i className="mdi mdi-exclamation font-size-24"></i>
                    </span>
                  </div>
                  <div className="timeline-list-item">
                    <div className="d-flex">
                      <div className="flex-grow-1 overflow-hidden me-4">
                        <h5 className="font-size-14 mb-1">
                          X missed by <a href="/users/user">Name</a> (role)
                        </h5>
                        <p className="text-truncate text-muted font-size-13">24.05.2021 @ 06:24:56 AM</p>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="activity-list">
                  <div className="activity-icon avatar-md">
                    <span className="avatar-title bg-success text-white rounded-circle">
                      <i className="mdi mdi-check-circle font-size-24"></i>
                    </span>
                  </div>
                  <div className="timeline-list-item">
                    <div className="d-flex">
                      <div className="flex-grow-1 overflow-hidden me-4">
                        <h5 className="font-size-14 mb-1">
                          X changed to done by <a href="/users/user">Name</a> (role)
                        </h5>
                        <p className="text-truncate text-muted font-size-13">24.05.2021 @ 06:24:56 AM</p>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </SimpleBar>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default Timeline;
