import { DeliveryRequestStatus, UserContextType, UserContextRoleType, DeliveryRequestDirection } from 'src/types/types';

const AvailableStateTransitions: {
  [key in DeliveryRequestStatus]: DeliveryRequestStatus[];
} = {
  [DeliveryRequestStatus.CREATED]: [DeliveryRequestStatus.SCHEDULED, DeliveryRequestStatus.CANCELLED],
  [DeliveryRequestStatus.SCHEDULED]: [
    DeliveryRequestStatus.CREATED,
    DeliveryRequestStatus.AT_TRANSPORTER,
    DeliveryRequestStatus.MISSED,
    DeliveryRequestStatus.CANCELLED,
  ],
  [DeliveryRequestStatus.AT_TRANSPORTER]: [
    DeliveryRequestStatus.SCHEDULED,
    DeliveryRequestStatus.DELIVERED,
    DeliveryRequestStatus.CANCELLED,
  ],
  [DeliveryRequestStatus.DELIVERED]: [],
  [DeliveryRequestStatus.MISSED]: [],
  [DeliveryRequestStatus.CANCELLED]: [],
};

const BatchableStateChanges = [
  DeliveryRequestStatus.SCHEDULED,
  DeliveryRequestStatus.MISSED,
  DeliveryRequestStatus.CANCELLED,
  DeliveryRequestStatus.DELIVERED,
];

const ActionAllowedForContext: {
  [key in DeliveryRequestStatus]: string[];
} = {
  [DeliveryRequestStatus.CREATED]: [`${UserContextRoleType.ADMIN}-${UserContextType.BACKOFFICE}`],
  [DeliveryRequestStatus.SCHEDULED]: [`${UserContextRoleType.ADMIN}-${UserContextType.BACKOFFICE}`],
  [DeliveryRequestStatus.AT_TRANSPORTER]: [
    `${UserContextRoleType.ADMIN}-${UserContextType.BACKOFFICE}`,
    `${UserContextRoleType.USER}-${UserContextType.TRANSPORTER}`,
  ],
  [DeliveryRequestStatus.MISSED]: [
    `${UserContextRoleType.ADMIN}-${UserContextType.BACKOFFICE}`,
    `${UserContextRoleType.USER}-${UserContextType.TRANSPORTER}`,
  ],
  [DeliveryRequestStatus.DELIVERED]: [
    `${UserContextRoleType.ADMIN}-${UserContextType.BACKOFFICE}`,
    `${UserContextRoleType.USER}-${UserContextType.TRANSPORTER}`,
  ],
  [DeliveryRequestStatus.CANCELLED]: [`${UserContextRoleType.ADMIN}-${UserContextType.BACKOFFICE}`],
};

const isAllowedForContext = (action: DeliveryRequestStatus, userContext: UserContext) =>
  ActionAllowedForContext[action].indexOf(`${userContext.role}-${userContext.context}`) > -1;

export const getActionsForDeliveryRequest = (task: DeliveryRequestListItem | DeliveryRequestDetails) => {
  let actions = AvailableStateTransitions[task.status as DeliveryRequestStatus];

  if (task.direction === DeliveryRequestDirection.STORAGE_TO_USER && task.status === DeliveryRequestStatus.SCHEDULED) {
    actions = actions.filter((status) => status !== DeliveryRequestStatus.MISSED);
  }

  return actions;
};

export const canBeApplied = (
  action: DeliveryRequestStatus,
  availableActions: DeliveryRequestStatus[],
  userContext: UserContext,
  options: {
    forBulkActions?: boolean;
    task?: DeliveryRequestDetails;
  } = {},
) => {
  const actionAvailable = availableActions.indexOf(action) > -1;
  if (!actionAvailable) {
    return false;
  }

  if (!isAllowedForContext(action, userContext)) {
    return false;
  }

  if (!options.forBulkActions) {
    return actionAvailable;
  }

  return BatchableStateChanges.indexOf(action) > -1;
};
