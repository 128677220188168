import React from 'react';
import { useTranslation } from 'react-i18next';
import { Badge, Card, CardHeader, CardBody, Col, Row } from 'reactstrap';

interface ItemsProps {
  packageData: Package;
  packageInfo: PackageInfoDetails;
}
const Items: React.FC<ItemsProps> = ({ packageData, packageInfo }) => {
  const { t } = useTranslation();

  return (
    <Card>
      <CardHeader className="align-items-center d-flex">
        <h4 className="card-title mb-0 flex-grow-1">{t('Common.content')}</h4>
      </CardHeader>
      <CardBody>
        <Row>
          {packageInfo.items?.map((item, ix) => (
            <Col md={6} xl={3} key={ix}>
              <Card>
                {item.files.map((file, ix) => (
                  <div className="d-flex justify-content-center bg-light pt-5 pb-5" key={ix}>
                    <i className="mdi mdi-image-outline h1" />
                    {/* <img key={ix} className="card-img-top img-fluid" src={getImagePath(file.path)} alt="" /> */}
                  </div>
                ))}

                <CardBody>
                  <h4 className="card-title">{item.name}</h4>
                  {item.tags.map((tag, ix) => (
                    <Badge key={ix} className="me-1">
                      {tag.name}
                    </Badge>
                  ))}
                </CardBody>
              </Card>
            </Col>
          ))}
        </Row>
      </CardBody>
    </Card>
  );
};

export default Items;
