import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';

import { GET_CURRENT_USER } from 'src/helpers/graphql';
import ResponseHandler from './ResponseHandler';
import { tabStorage } from 'src/helpers/storage/settings';
import ability, { setCurrentAuth } from 'src/helpers/auth/ability';
import Auth from 'src/helpers/auth/auth';
import { findPreferredContext, isContextValidForAdminUI } from 'src/helpers/auth/authUtils';
import { client } from 'src/index';

export const STORAGE_KEY_CURRENTUSER_CURRENTCONTEXT_ID = 'current-context-id-v1';
export const STORAGE_KEY_SERIALIZED_CURRENTUSER = 'authUser';

enum PageLoadStates {
  WaitingForFirstAuthFetch = 0,
  FirstAuthFetchTriggered = 1,
  ProfileLoaded = 2,
}

interface AuthContextSetterProps {
  children: React.ReactNode;
}

type AuthContextType = {
  currentUser?: User;
  currentContext?: UserContext;
  setCurrentContext: (newContext: UserContext) => void;
  login: (email: string) => void;
  logout: () => void;
  ability: any;
  auth: Auth;
};

const authContext: AuthContextType = {
  currentUser: undefined,
  currentContext: undefined,
  setCurrentContext: (newContext: UserContext) => {},
  login: (email: string) => {},
  logout: () => {},
  ability: {},
  auth: new Auth(null, null),
};

export const AuthContext = React.createContext(authContext);

const AuthContextSetter: React.FC<AuthContextSetterProps> = ({ children }) => {
  const [getMe, { loading, error, data }] = useLazyQuery(GET_CURRENT_USER);
  const history = useHistory();

  const [currentLocalContext, setCurrentLocalContext] = useState<UserContext | undefined>();
  const [currentLocalAuth, setCurrentLocalAuth] = useState<Auth>(new Auth(null, null));
  const [pageLoadState, setPageLoadState] = useState(PageLoadStates.WaitingForFirstAuthFetch);

  const meUsersFirstContext = data?.me?.userContexts?.[0]?.id;

  const syncWithNewContext = (newContext: UserContext) => {
    setCurrentLocalContext(newContext);
    const currentAuth = setCurrentAuth(data.me, newContext);
    setCurrentLocalAuth(currentAuth.cachedAuth);
  };

  const logout = () => {
    localStorage.removeItem(STORAGE_KEY_SERIALIZED_CURRENTUSER);
    localStorage.removeItem(STORAGE_KEY_CURRENTUSER_CURRENTCONTEXT_ID);
    client.resetStore();
    const guestAuth = new Auth(null, null);
    setCurrentLocalAuth(guestAuth);
    history.push(guestAuth.getHomeUrl());
    // total reset
    document.location.reload();
  };

  useEffect(() => {
    if (localStorage.getItem(STORAGE_KEY_SERIALIZED_CURRENTUSER)) {
      // user in localStorage, fetching ME data
      getMe();
    } else {
      // set current user as a guest
      const currentAuth = setCurrentAuth(null, null);
      setCurrentLocalAuth(currentAuth.cachedAuth);
      setPageLoadState(PageLoadStates.ProfileLoaded);
    }
  }, [getMe]);

  useEffect(() => {
    if (!data) {
      return;
    }

    const contexts: UserContext[] = data.me.userContexts;
    const previouslySelectedContextId = tabStorage.getItem(STORAGE_KEY_CURRENTUSER_CURRENTCONTEXT_ID);

    const isValidPreviousContextIdForAdmin = isContextValidForAdminUI(contexts, previouslySelectedContextId);
    let validContextId = previouslySelectedContextId;

    if (!previouslySelectedContextId || !isValidPreviousContextIdForAdmin) {
      validContextId = findPreferredContext(contexts).id;
      tabStorage.setItem(STORAGE_KEY_CURRENTUSER_CURRENTCONTEXT_ID, validContextId);
    }

    const validSelectedContext = contexts.find((context) => context.id === validContextId)!;
    syncWithNewContext(validSelectedContext);
  }, [loading, data, data?.me, meUsersFirstContext]);

  useEffect(() => {
    if (loading && pageLoadState === PageLoadStates.WaitingForFirstAuthFetch) {
      setPageLoadState(PageLoadStates.FirstAuthFetchTriggered);
    } else if (!loading && pageLoadState === PageLoadStates.FirstAuthFetchTriggered) {
      setPageLoadState(PageLoadStates.ProfileLoaded);
    }
  }, [loading, pageLoadState]);

  useEffect(() => {
    if (pageLoadState === PageLoadStates.ProfileLoaded && error) {
      logout();
    }
  }, [error, pageLoadState]);

  return (
    <ResponseHandler loading={loading} error={error}>
      <AuthContext.Provider
        value={{
          currentUser: data?.me,
          currentContext: currentLocalContext!,
          setCurrentContext: (newContext: UserContext) => {
            tabStorage.setItem(STORAGE_KEY_CURRENTUSER_CURRENTCONTEXT_ID, newContext.id);
            syncWithNewContext(newContext);
            client.stop();
            client.resetStore();
            history.push(currentLocalAuth.getHomeUrl());
          },
          ability,
          auth: currentLocalAuth,
          login: (email: string) => {
            localStorage.setItem(STORAGE_KEY_SERIALIZED_CURRENTUSER, JSON.stringify({ email }));
            getMe();
          },
          logout,
        }}
      >
        {pageLoadState === PageLoadStates.ProfileLoaded ? children : null}
      </AuthContext.Provider>
    </ResponseHandler>
  );
};

export default AuthContextSetter;
