import { Search, Pathname, History } from 'history';
import _isObjectLike from 'lodash/isObjectLike';

export const AVAILABLE_PAGE_SIZES = [10, 25, 50, 100];
export const DEFAULT_PAGE_SIZE = 25;
export const MULTI_FILTER_VALUE_SEPARATOR = ',';
export const NO_FILTER_VALUE = '---none---';

export interface PaginatedResult {
  cursors: string[];
  edges: {
    cursor: string;
    node: any;
  }[];
  totalCount: number;
}

export const getFilterSettingsFromUrl = (search: Search, defaultFilterValue?: string) => {
  const urlParams = getNormalizedParamsFromUrl(search);

  let value = defaultFilterValue;
  const valueFromUrl = urlParams.filter;
  if (valueFromUrl) {
    value = decodeQueryParam(valueFromUrl);
  }

  return value;
};

export const getNormalizedParamsFromUrl = (search: Search) => {
  const query = new URLSearchParams(search);
  const all: any = {};

  for (let [key, value] of query.entries()) {
    all[key] = value;
  }

  all.pageSize = parseInt(all.pageSize, 10) || DEFAULT_PAGE_SIZE;
  all.first = all.first !== undefined ? parseInt(all.first, 10) : undefined;

  return all;
};

export interface FilterOptionType {
  label: string;
  value: string;
}

export const encodeQueryParam = (value?: any) => {
  let encoded: string = value;

  if (_isObjectLike(value) || Array.isArray(value)) {
    encoded = JSON.stringify(value);
  }

  return encodeURI(encoded);
};

export const decodeQueryParam = (value?: any) => {
  let decoded: string = decodeURI(value);

  if (decoded) {
    try {
      const parsed = JSON.parse(decoded);
      decoded = parsed;
    } catch (e) {}
  }

  return decoded;
};

export const replaceUrlWithSearchParams = (submitted: any, history: History, pathname: Pathname) => {
  // console.log('filter changed', submitted);

  const newParams = new URLSearchParams();

  Object.keys(submitted).forEach((key) => {
    const value = submitted[key];

    if (value === undefined || value === NO_FILTER_VALUE) {
      return;
    }

    newParams.set(key, encodeQueryParam(submitted[key]));
  });
  const newPath = `${pathname}?${newParams.toString()}`;
  history.push(newPath);
};

// apollo resends all the variables from the previous request, when they're not set, so we're zeroing them out here
export const clearOldValues = (data: any) => ({
  first: undefined,
  after: undefined,
  filters: undefined,
  ...data,
});

export const prepareFilters = (filterValue: string) => {
  if (filterValue === NO_FILTER_VALUE) {
    return { filters: undefined };
  }

  if (filterValue.indexOf(MULTI_FILTER_VALUE_SEPARATOR) > -1) {
    return { filters: filterValue.split(MULTI_FILTER_VALUE_SEPARATOR) };
  }

  return { filters: filterValue };
};
