import axios, { AxiosRequestConfig } from 'axios';
import config from 'src/config';

const axiosApi = axios.create({
  baseURL: config.backendUrl,
});

axiosApi.defaults.headers.common['x-app-platform-name'] = 'web';
axiosApi.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error),
);

export async function get(url: string, config: AxiosRequestConfig = {}) {
  return await axiosApi.get(url, { ...config }).then((response) => response.data);
}

export async function post(url: string, data: any, config: AxiosRequestConfig = {}) {
  return axiosApi.post(url, { ...data }, { ...config }).then((response) => response.data);
}

export async function put(url: string, data: any, config: AxiosRequestConfig = {}) {
  return axiosApi.put(url, { ...data }, { ...config }).then((response) => response.data);
}

export async function del(url: string, config: AxiosRequestConfig = {}) {
  return await axiosApi.delete(url, { ...config }).then((response) => response.data);
}
