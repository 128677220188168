export enum UserContextType {
  BACKOFFICE = 'BACKOFFICE',
  SEARCHER = 'SEARCHER',
  PROVIDER = 'PROVIDER',
  TRANSPORTER = 'TRANSPORTER',
}

export enum UserContextRoleType {
  USER = 'USER',
  OPERATOR = 'OPERATOR',
  ADMIN = 'ADMIN',
}

export enum DeliveryRequestStatus {
  CREATED = 'CREATED',
  SCHEDULED = 'SCHEDULED',
  AT_TRANSPORTER = 'AT_TRANSPORTER',
  MISSED = 'MISSED',
  CANCELLED = 'CANCELLED',
  DELIVERED = 'DELIVERED',
}

export enum DeliveryRequestDirection {
  USER_TO_STORAGE = 'USER_TO_STORAGE',
  STORAGE_TO_USER = 'STORAGE_TO_USER',
  STORAGE_TO_STORAGE = 'STORAGE_TO_STORAGE',
}

export enum DeliveryRequestScheduleType {
  ASAP = 'ASAP',
  SCHEDULED = 'SCHEDULED',
}

export enum PackageInfoStatus {
  CREATED = 'CREATED',
  WAITING_FOR_PICKUP = 'WAITING_FOR_PICKUP',
  STORED = 'STORED',
  WAITING_FOR_RETURN = 'WAITING_FOR_RETURN',
  RETURNED = 'RETURNED',
  PICKUP_MISSED = 'PICKUP_MISSED',
  RETURN_MISSED = 'RETURN_MISSED',
  PICKUP_CANCELLED = 'PICKUP_CANCELLED',
  RETURN_CANCELLED = 'RETURN_CANCELLED',
}

export enum PackageRequestStatus {
  REQUESTED = 'REQUESTED',
  IN_PROGRESS = 'IN_PROGRESS',
  SHIPPED = 'SHIPPED',
}

export enum SubscriptionPaymentStatus {
  ACTIVE = 'ACTIVE',
  WAITING_FOR_COMPLETION = 'WAITING_FOR_COMPLETION',
  REQUIRES_ACTION = 'REQUIRES_ACTION',
  REQUIRES_PAYMENT_METHOD = 'REQUIRES_PAYMENT_METHOD',
  CANCELED = 'CANCELED',
  FAILED = 'FAILED',
}

export enum PaymentProductType {
  SUBSCRIPTION = 'SUBSCRIPTION',
  ONE_TIME_SERVICE = 'ONE_TIME_SERVICE',
  ADDON = 'ADDON',
}

export enum PriceRecurrence {
  MONTHLY = 'MONTHLY',
  YEARLY = 'YEARLY',
  ONE_TIME = 'ONE_TIME',
}

export enum PaymentIntentStatus {
  CANCELLED = 'CANCELLED',
  PROCESSING = 'PROCESSING',
  REQUIRES_ACTION = 'REQUIRES_ACTION',
  REQUIRES_PAYMENT_METHOD = 'REQUIRES_PAYMENT_METHOD',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
}

export enum BatchType {
  BAG = 'BAG',
  PACKAGE = 'PACKAGE',
}

export enum PackageTagSource {
  AI = 'AI',
  SYSTEM = 'SYSTEM',
  USER = 'USER',
}

export enum PackageTypes {
  STANDARD_BOX = 'STANDARD_BOX',
  CUSTOM = 'CUSTOM',
  BAG = 'BAG',
}

export enum PackageStatus {
  ACTIVE = 'ACTIVE',
  DAMAGED = 'DAMAGED',
  LOST = 'LOST',
}

export enum PackageItemStatus {
  CREATED = 'CREATED',
  PROCESSING = 'PROCESSING',
  DONE = 'DONE',
  FAILED = 'FAILED',
}

export enum VirtualStorageType {
  STORAGE = 'STORAGE',
  TRANSPORT = 'TRANSPORT',
}

export enum StorageStatus {
  CREATED = 'CREATED',
  UNDER_REVIEW = 'UNDER_REVIEW',
  ACTIVE = 'ACTIVE',
  DECLINED = 'DECLINED',
}
