import React from 'react';

interface AddressProps {
  address: FullAddress;
}

const withFallbackDash = (text?: string) => (text ? text : ' - ');

const Address: React.FC<AddressProps> = ({ address }) => {
  if (!address) {
    return null;
  }

  const { city, country, door, floor, info, postalCode, route, state, streetNumber } = address;

  return (
    <>
      <h5>
        {streetNumber} {route}
        <br />
        {postalCode} {city}, {state}, {country}
      </h5>
      <p>
        Door bell: {withFallbackDash(door)} <br />
        Floor: {withFallbackDash(floor)} <br />
        Other: {withFallbackDash(info)}
      </p>
    </>
  );
};

export default Address;
