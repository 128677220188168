import React, { useState, useContext, useEffect } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useMutation, ApolloError } from '@apollo/client';

import {
  PICKUP_PACKAGE_AT_USER_ON_BEHALF_OF_TRANSPORTER,
  PICKUP_PACKAGE_AT_USER,
  PICKUP_PACKAGE_AT_STORAGE_ON_BEHALF_OF_TRANSPORTER,
  PICKUP_PACKAGE_AT_STORAGE,
} from 'src/helpers/graphql';
import { AuthContext } from 'src/components/AuthContextSetter';
import { getDisplayName } from 'src/helpers/ui';
import TextInput from 'src/components/Forms/TextInput';
import ModalForm, { setFieldErrors } from 'src/components/Forms/ModalForm';
import { DeliveryRequestDirection } from 'src/types/types';

type Inputs = {
  transporterContextId: string;
  bagUniqueId: string;
  packageUniqueId: string;
};

type StatusToPickupProps = {
  show: boolean;
  setShow: Function;
  handleSingleOrBulkSubmit: Function;
  tasks: (DeliveryRequestListItem | DeliveryRequestDetails)[];
};

const StatusToPickup: React.FC<StatusToPickupProps> = ({ show, setShow, handleSingleOrBulkSubmit, tasks }) => {
  const authContext = useContext(AuthContext);

  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors },
  } = useForm<Inputs>();

  const [globalError, setGlobalError] = useState<string | ApolloError | undefined>('');

  const [
    pickupPackageAtStorage,
    { data: pickupPackageAtStorageData, loading: pickupPackageAtStorageLoading, error: pickupPackageAtStorageError },
  ] = useMutation(
    authContext.auth.isTransporter() ? PICKUP_PACKAGE_AT_STORAGE : PICKUP_PACKAGE_AT_STORAGE_ON_BEHALF_OF_TRANSPORTER,
  );

  const [
    pickupPackageAtUser,
    { data: pickupPackageAtUserData, loading: pickupPackageAtUserLoading, error: pickupPackageAtUserError },
  ] = useMutation(
    authContext.auth.isTransporter() ? PICKUP_PACKAGE_AT_USER : PICKUP_PACKAGE_AT_USER_ON_BEHALF_OF_TRANSPORTER,
  );

  const direction = tasks[0].direction;

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    if (direction === DeliveryRequestDirection.USER_TO_STORAGE) {
      return await handleSingleOrBulkSubmit({
        data: tasks.map((task) => ({
          ...(authContext.auth.isTransporter() ? {} : { transporterContextId: task.transporterContextId }),
          bagUniqueId: task.bag?.uniqueId,
          packageUniqueId: data.packageUniqueId,
        })),
        submitFn: pickupPackageAtUser,
        setFieldErrors: (err: any) => setFieldErrors(err, setError, setGlobalError),
      });
    }

    return await handleSingleOrBulkSubmit({
      data: tasks.map((task) => ({
        ...(authContext.auth.isTransporter() ? {} : { transporterContextId: task.transporterContextId }),
        packageUniqueId: data.packageUniqueId,
      })),
      submitFn: pickupPackageAtStorage,
      setFieldErrors: (err: any) => setFieldErrors(err, setError, setGlobalError),
    });
  };

  useEffect(() => {
    setFieldErrors(pickupPackageAtUserError, setError, setGlobalError);
  }, [pickupPackageAtUserError]);

  useEffect(() => {
    setFieldErrors(pickupPackageAtStorageError, setError, setGlobalError);
  }, [pickupPackageAtStorageError]);

  useEffect(() => {
    reset();
  }, [tasks?.[0]?.id]);

  return (
    <ModalForm
      title="At transporter"
      show={show}
      setShow={setShow}
      onSubmit={handleSubmit(onSubmit)}
      loading={pickupPackageAtUserLoading}
      globalError={globalError}
    >
      <TextInput
        label="Transporter"
        defaultValue={tasks.length === 1 ? getDisplayName(tasks[0].transporterUser) : undefined}
        {...register('transporterContextId')}
        disabled={true}
        errors={errors.transporterContextId}
      />
      {direction === DeliveryRequestDirection.USER_TO_STORAGE ? (
        <>
          <TextInput
            label="Bag unique Id"
            defaultValue={tasks.length === 1 ? tasks[0].bag?.uniqueId : undefined}
            {...register('bagUniqueId')}
            disabled={true}
            errors={errors.bagUniqueId}
          />
          <TextInput
            label="Box unique Id (only if box is used)"
            defaultValue={tasks[0].package?.uniqueId || ''}
            placeholder="XXX-XXX-XXX (optional)"
            {...register('packageUniqueId')}
            disabled={pickupPackageAtUserLoading}
            errors={errors.packageUniqueId}
          />
        </>
      ) : (
        <TextInput
          label="Box unique Id"
          defaultValue={tasks[0].package?.uniqueId || ''}
          placeholder="XXX-XXX-XXX"
          {...register('packageUniqueId', { required: true })}
          disabled={pickupPackageAtUserLoading}
          errors={errors.packageUniqueId}
        />
      )}
    </ModalForm>
  );
};

export default StatusToPickup;
