import React, { useState, useEffect } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useMutation, ApolloError } from '@apollo/client';

import { EDIT_USER, ADD_TRANSPORTER_CONTEXT, DELETE_TRANSPORTER_CONTEXT } from 'src/helpers/graphql';
import { getUsersTransporterContext } from 'src/helpers/auth/authUtils';
import Label from 'src/components/Forms/Label';
import TextInput from 'src/components/Forms/TextInput';
import Toggle from 'src/components/Forms/Toggle';
import ModalForm, { setFieldErrors } from 'src/components/Forms/ModalForm';

type Inputs = {
  firstName: string;
  lastName: string;
  isTransporter: boolean;
};

type EditUserDialogProps = {
  user?: User;
  show: boolean;
  setShow: Function;
};

const EditUserDialog: React.FC<EditUserDialogProps> = ({ user, show, setShow }) => {
  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors },
  } = useForm<Inputs>();

  const [globalError, setGlobalError] = useState<string | ApolloError | undefined>('');
  const [editUser, editUserMutation] = useMutation(EDIT_USER);
  const [addTransporterContext, addTransporterMutation] = useMutation(ADD_TRANSPORTER_CONTEXT);
  const [deleteTransporterContext, deleteTransporterMutation] = useMutation(DELETE_TRANSPORTER_CONTEXT);

  const addTransporterRole = async () => {
    try {
      await addTransporterContext({
        variables: {
          userId: user?.id,
        },
      });
    } catch (err) {
      setFieldErrors(err, setError, setGlobalError);
      throw err;
    }
  };

  const deleteTransporterRole = async () => {
    const role = getUsersTransporterContext(user!);
    if (!role) {
      return;
    }

    try {
      await deleteTransporterContext({
        variables: {
          id: role.id,
        },
      });
    } catch (err) {
      setFieldErrors(err, setError, setGlobalError);
      throw err;
    }
  };

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    if (user) {
      if (data.isTransporter && !getUsersTransporterContext(user)) {
        try {
          await addTransporterRole();
        } catch (err) {
          return;
        }
      } else if (!data.isTransporter && getUsersTransporterContext(user)) {
        try {
          await deleteTransporterRole();
        } catch (err) {
          return;
        }
      }
    }

    try {
      await editUser({
        variables: {
          id: user?.id,
          ...data,
        },
      });
      setShow(false);
    } catch (err) {
      setFieldErrors(err, setError, setGlobalError);
    }
  };

  useEffect(() => {
    const errors = {
      ...editUserMutation.error,
      ...addTransporterMutation.error,
      ...deleteTransporterMutation.error,
    };
    setFieldErrors(errors, setError, setGlobalError);
  }, [editUserMutation.error, addTransporterMutation.error, deleteTransporterMutation.error, setError]);

  useEffect(() => {
    reset();
  }, [user?.id, reset]);

  const loading = addTransporterMutation.loading || addTransporterMutation.loading || editUserMutation.loading;

  return (
    <ModalForm
      title="New user"
      show={show}
      setShow={setShow}
      onSubmit={handleSubmit(onSubmit)}
      loading={loading}
      globalError={globalError}
    >
      <TextInput
        label="First name"
        defaultValue={user?.firstName}
        {...register('firstName', { required: true })}
        disabled={loading}
        errors={errors.firstName}
      />

      <TextInput
        label="Last name"
        defaultValue={user?.lastName}
        {...register('lastName', { required: true })}
        disabled={loading}
        errors={errors.lastName}
      />

      <Label label="Is user transporter?" />
      <Toggle
        defaultChecked={user ? !!getUsersTransporterContext(user) : false}
        {...register('isTransporter')}
        disabled={loading}
        errors={errors.lastName}
      />
    </ModalForm>
  );
};

export default EditUserDialog;
