import React, { useContext } from 'react';
import { Table, Card, CardHeader } from 'reactstrap';

import { UserPageContext } from './UserDetails';

export interface StoragesProps {}

const Storages: React.FC<StoragesProps> = () => {
  const { user } = useContext(UserPageContext);

  return (
    <React.Fragment>
      <Card>
        <CardHeader>
          <h4 className="card-title mb-0 flex-grow-1">Storages</h4>
        </CardHeader>
        <div className="card-body">
          <div className="table-responsive">
            <Table className="table mb-0 align-middle">
              <thead>
                <tr>
                  <th>Storage name</th>
                  <th>Address</th>
                  <th>Zip</th>
                  <th>City</th>
                  <th>Area</th>
                  <th>Capacity</th>
                  <th># of boxes</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {user.userContexts.map((context) =>
                  context.addresses.map((address) => (
                    <tr key={address.id}>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  )),
                )}
              </tbody>
            </Table>
          </div>
        </div>
      </Card>
    </React.Fragment>
  );
};
export default Storages;
