import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, Row, Badge, CardHeader } from 'reactstrap';
import styled from 'styled-components';

export interface SuperCategoryProps {
  categoryId?: string;
  name: string;
  tags: PackageTag[];
  onEditCategory?: () => void;
  onAddTagToCategory?: () => void;
  onAddTagToBlacklist?: () => void;
  onDeleteTag: (tag: PackageTag) => () => void;
  tagColor?: string;
  showButtons?: boolean;
}

const Tag = styled(Badge)`
  font-size: 1rem;
  display: block;
  flex-shrink: initial;
  width: initial;
  margin-right: 0.25em;
  margin-bottom: 0.25em;
  ${(props) => (props['tag-color'] ? `background-color: ${props['tag-color']} !important` : '')};
`;

const SuperCategory: React.FC<SuperCategoryProps> = ({
  name,
  tags,
  onEditCategory,
  onDeleteTag,
  onAddTagToCategory,
  onAddTagToBlacklist,
  tagColor,
  showButtons = true,
}) => {
  return (
    <React.Fragment>
      <Card>
        <CardHeader className="align-items-center d-flex">
          <h4 className="card-title mb-0 flex-grow-1">{name}</h4>
          <div className="d-flex justify-content-end">
            {showButtons && onAddTagToBlacklist ? (
              <Link to="#" className="btn btn-sm btn-soft-light me-2" onClick={onAddTagToBlacklist}>
                <i className="mdi mdi-plus-thick me-1"></i> Add
              </Link>
            ) : null}
            {showButtons && onAddTagToCategory ? (
              <Link to="#" className="btn btn-sm btn-soft-light me-2" onClick={onAddTagToCategory}>
                <i className="mdi mdi-plus-thick me-1"></i> Add tag
              </Link>
            ) : null}
            {showButtons && onEditCategory ? (
              <Link to="#" className="btn btn-sm btn-soft-light" onClick={onEditCategory}>
                <i className="mdi mdi-pencil me-1"></i> Edit
              </Link>
            ) : null}
          </div>
        </CardHeader>
        <CardBody>
          <Row className="align-items-center">
            {tags.map((tag) => (
              <Tag key={tag.id} tag-color={tagColor}>
                {tag.name}
                {showButtons && onDeleteTag ? (
                  <Link to="#" className="text-white" onClick={onDeleteTag(tag)}>
                    <i className="mdi mdi-close"></i>
                  </Link>
                ) : null}
              </Tag>
            ))}
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default SuperCategory;
