import React, { useState, useEffect } from 'react';
import MetaTags from 'react-meta-tags';
import { useLazyQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import {
  Col,
  Container,
  Row,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Spinner,
} from 'reactstrap';

import { ALL_BATCHES } from 'src/helpers/graphql';
import Breadcrumbs from 'src/components/Breadcrumbs';
import CreateLabelsDialog from './CreateLabelsDialog';
import LabelCard from './LabelCard';
import { BatchType } from 'src/types/types';

type FilterOptions = 'all' | 'bags' | 'packages';

const Labels: React.FC<{}> = () => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [filter, setFilter] = useState<FilterOptions>('all');

  const [getAllBatches, { loading, error, data }] = useLazyQuery(ALL_BATCHES);

  const generateContents = () => {
    const items: Batch[] = data?.allBatches;

    if (loading || (!items && !error)) {
      return <Spinner className="spinner--with-space" color="secondary" />;
    }

    const filteredItems =
      filter === 'all'
        ? items
        : items?.filter((item: Batch) => {
            if (filter === 'bags') {
              return item.type === BatchType.BAG;
            }
            return item.type === BatchType.PACKAGE;
          });

    return (
      <>
        {[...filteredItems]?.reverse()?.map((item: Batch) => (
          <LabelCard key={item.id} batch={item} />
        ))}
      </>
    );
  };

  const setFilterTo = (newFilter: FilterOptions) => {
    setFilter(newFilter);
  };

  useEffect(() => {
    getAllBatches();
  }, [getAllBatches]);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{t('BatchList.title')}</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs
            items={[
              {
                label: t('Menu.tools'),
                path: '#',
              },
              {
                label: t('Menu.labels'),
                path: `/labels`,
              },
            ]}
          />

          <Row className="align-items-start" style={{ minHeight: '3.5rem' }}>
            <Col lg="12">
              <div className="d-flex justify-content-between">
                <div>
                  <h1>{t('BatchList.manage_labels')}</h1>
                </div>

                <div>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => {
                      setShow(true);
                    }}
                  >
                    <i className="mdi mdi-plus-thick me-1"></i> {t('BatchList.new_labels')}
                  </button>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="mb-2">
            <div className="row">
              <div className="d-flex flex-wrap gap-2">
                <div>
                  <UncontrolledDropdown>
                    <DropdownToggle className="btn btn-soft-light" tag="a">
                      <i className="mdi mdi-filter" /> {t(filter === 'all' ? 'Common.all' : `BatchList.${filter}`)}
                      <i className="mdi mdi-chevron-down" />
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-start">
                      <li>
                        <DropdownItem onClick={() => setFilterTo('all')}>{t('Common.all')}</DropdownItem>
                      </li>
                      <li>
                        <DropdownItem onClick={() => setFilterTo('bags')}>{t('BatchList.bags')}</DropdownItem>
                      </li>
                      <li>
                        <DropdownItem onClick={() => setFilterTo('packages')}>{t('BatchList.packages')}</DropdownItem>
                      </li>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
              </div>
            </div>
          </Row>
          <Row>
            <Col lg="12">
              <div className="mt-3">
                <h4>{t('BatchList.generated_labels')}</h4>
              </div>
              {generateContents()}
            </Col>
          </Row>
        </Container>
      </div>

      <CreateLabelsDialog show={show} setShow={setShow} />
    </React.Fragment>
  );
};

export default Labels;
