const config = {
  backendUrl: process.env.REACT_APP_BACKEND_URL,
  backendGraphQlUrl: `${process.env.REACT_APP_BACKEND_URL}/graphql`,
  intercomLinkToUser: process.env.REACT_APP_INTERCOM_LINK_TO_USER,
  stripeDashboard: process.env.REACT_APP_STRIPE_DASHBOARD,
  app: {
    packageTypeDefaultId: process.env.REACT_APP_CONFIG_PACKAGE_TYPE_DEFAULT_ID,
  },
  mapSessionId: crypto.randomUUID(),
};

export default config;
