import React from 'react';
import { Alert } from 'reactstrap';
import { ApolloError } from '@apollo/client';
import _get from 'lodash/get';

export interface ModalErrorProps {
  error?: string | ApolloError;
}

const renderModalErrors = (errors: string[]) => {
  return (
    <>
      {errors.map((error, ix) => (
        <Alert color="danger" key={ix}>
          {error}
        </Alert>
      ))}
    </>
  );
};

const ModalError: React.FC<ModalErrorProps> = ({ error }) => {
  if (!error) {
    return null;
  }

  if (error instanceof ApolloError) {
    if (!error.networkError && error.message) {
      return renderModalErrors([error.message]);
    }

    const errors = _get(error, 'networkError.result.errors', []);

    if (errors.length) {
      return renderModalErrors(errors.map((error: Error) => error.message));
    }

    return renderModalErrors([error.message]);
  }

  return renderModalErrors([error]);
};

export default ModalError;
