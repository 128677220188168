import React, { useState, useEffect } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useMutation, ApolloError } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { ALL_CATEGORIES, CREATE_CATEGORY } from 'src/helpers/graphql';
import TextInput from 'src/components/Forms/TextInput';
import ModalForm, { setFieldErrors } from 'src/components/Forms/ModalForm';

type Inputs = {
  name: string;
};

type CreateCategoryDialogProps = {
  show: boolean;
  setShow: (arg0: boolean) => void;
};

const CreateCategoryDialog: React.FC<CreateCategoryDialogProps> = ({ show, setShow }) => {
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors },
  } = useForm<Inputs>();

  const [globalError, setGlobalError] = useState<string | ApolloError | undefined>('');
  const [createCategory, { loading, error }] = useMutation(CREATE_CATEGORY, {
    refetchQueries: [{ query: ALL_CATEGORIES }],
  });

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      await createCategory({ variables: { ...data } });
      setShow(false);
    } catch (err) {
      setFieldErrors(err, setError, setGlobalError);
    }
  };

  useEffect(() => {
    setFieldErrors(error, setError, setGlobalError);
  }, [error, setError]);

  useEffect(() => {
    reset();
  }, [reset]);

  return (
    <ModalForm
      title={t('Categories.create.title')}
      show={show}
      setShow={setShow}
      onSubmit={handleSubmit(onSubmit)}
      loading={loading}
      globalError={globalError}
      submitText="Create"
      cancelText="Cancel"
      size="me"
    >
      <p>{t('Categories.create.description')}</p>

      <TextInput
        type="text"
        label={t('Categories.create.name')}
        defaultValue=""
        placeholder={t('Categories.create.name.placeholder')}
        {...register('name', { required: true })}
        disabled={loading}
        errors={errors.name}
      />
    </ModalForm>
  );
};

export default CreateCategoryDialog;
