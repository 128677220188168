import React, { useState, useEffect } from 'react';
import { useForm, useFieldArray, SubmitHandler } from 'react-hook-form';
import { useMutation, ApolloError } from '@apollo/client';
import { Label } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { UPDATE_PACKAGE_REQUEST, DELETE_PACKAGE_REQUEST, GET_PACKAGE_REQUESTS } from 'src/helpers/graphql';
import { PackageRequestStatus } from 'src/types/types';
import TextInput from 'src/components/Forms/TextInput';
import Select, { toOption } from 'src/components/Forms/Select';
import ModalForm, { setFieldErrors } from 'src/components/Forms/ModalForm';

type Inputs = {
  status: string;
  package1: string;
  packageUniqueIds: string[];
};

export type DialogModes = 'assign' | 'edit' | 'delete';

type AssignBoxesDialogProps = {
  packageRequest: PackageRequestType;
  onHide: Function;
  mode?: DialogModes;
};

const AssignBoxesDialog: React.FC<AssignBoxesDialogProps> = ({ packageRequest, onHide, mode }) => {
  const {
    register,
    handleSubmit,
    setError,
    control,
    watch,
    reset,
    formState: { errors },
  } = useForm<Inputs>({});

  const { t } = useTranslation();

  const { fields, replace } = useFieldArray({
    control,
    name: 'packageUniqueIds',
  } as never);

  const [globalError, setGlobalError] = useState<string | ApolloError | undefined>('');
  const [updatePackageRequest, { data, loading, error }] = useMutation(UPDATE_PACKAGE_REQUEST);
  const [deletePackageRequest] = useMutation(DELETE_PACKAGE_REQUEST);

  const currentStatusValue = watch('status') || packageRequest?.status;

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    const packageUniqueIds = data?.packageUniqueIds?.filter((uniqueId) => uniqueId !== '');

    if (mode === 'delete') {
      try {
        await deletePackageRequest({
          variables: {
            id: packageRequest?.id,
          },
        });
        onHide();
        window.location.reload();
      } catch (err) {
        setFieldErrors(err, setError, setGlobalError);
      }
    } else {
      try {
        await updatePackageRequest({
          variables: {
            id: packageRequest?.id,
            status: mode === 'assign' ? PackageRequestStatus.SHIPPED : data.status,
            ...(packageUniqueIds.length > 0 ? { packageUniqueIds } : {}),
          },
        });
        onHide();
      } catch (err) {
        setFieldErrors(err, setError, setGlobalError);
      }
    }
  };

  const statusOptions = Object.keys(PackageRequestStatus).map((status) =>
    toOption(status, t(`PackageRequest.status.${status}`)),
  );

  useEffect(() => {
    setFieldErrors(error, setError, setGlobalError);
  }, [error, setError]);

  useEffect(() => {
    replace(new Array(packageRequest?.amount).fill(''));
  }, [reset, packageRequest?.amount, replace]);

  useEffect(() => {
    replace(new Array(packageRequest?.amount).fill('').map((_, ix) => packageRequest.packages[ix]?.uniqueId || ''));
  }, [reset]);

  const visibleUniqueIds =
    mode === 'assign' ||
    currentStatusValue === PackageRequestStatus.IN_PROGRESS ||
    currentStatusValue === PackageRequestStatus.SHIPPED;

  let title = '';

  if (mode === 'assign') {
    title = 'Assign boxes';
  } else if (mode === 'edit') {
    title = 'Edit status';
  } else if (mode === 'delete') {
    title = 'Delete boxes';
  }

  return (
    <ModalForm
      title={title}
      show={true}
      setShow={onHide}
      onSubmit={handleSubmit(onSubmit)}
      loading={loading}
      globalError={globalError}
    >
      {mode === 'edit' ? (
        <Select
          label="Status"
          selectOptions={statusOptions}
          defaultValue={packageRequest?.status}
          {...register('status', { required: true })}
          disabled={loading}
          errors={errors.status}
        />
      ) : null}

      {mode === 'delete' ? <p className="text-center">Are you sure you want to delete "Customers" boxes?</p> : null}

      <div hidden={!visibleUniqueIds}>
        <p>Please enter selected box id's in the fields below to assign boxes to the customer.</p>

        <Label htmlFor="example-number-input" className="form-Label mt-1">
          Assign package(s)
        </Label>

        {fields.map((item, ix) => (
          <div key={item.id}>
            <TextInput
              {...register(`packageUniqueIds.${ix}`)}
              disabled={loading}
              placeholder="XXX-XXX-XXX"
              errors={errors.packageUniqueIds?.[ix]}
            />
          </div>
        ))}
      </div>
    </ModalForm>
  );
};

export default AssignBoxesDialog;
