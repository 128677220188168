import { useLazyQuery, useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MetaTags from 'react-meta-tags';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Spinner } from 'reactstrap';

import SuperCategory from 'src/components/SuperCategory';
import Breadcrumbs from 'src/components/Breadcrumbs';
import { ALL_CATEGORIES, SEARCH_TAGS, UPDATE_TAG } from 'src/helpers/graphql';
import AddTagToCategoryDialog from './AddTagToCategoryDialog';
import CreateCategoryDialog from './CreateCategoryDialog';
import UpdateCategoryDialog from './UpdateCategoryDialog';

const AiTools = () => {
  const { t } = useTranslation();
  const [updateTag, updateTagMutation] = useMutation(UPDATE_TAG, {
    refetchQueries: [{ query: ALL_CATEGORIES }, { query: SEARCH_TAGS, variables: { withoutCategory: true } }],
  });
  const [getAllCategories, { loading: categoryLoading, error: categoryError, data: categoryData }] =
    useLazyQuery(ALL_CATEGORIES);
  const [getAllTags, { loading: tagLoading, error: tagError, data: tagData }] = useLazyQuery(SEARCH_TAGS, {
    variables: { withoutCategory: true },
  });

  const [showNewCategoryModal, setShowNewCategoryModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showAddTagModal, setShowAddTagModal] = useState(false);
  const [activeCategory, setActiveCategory] = useState({} as Category);

  const toggleUpdateModal = (category: Category) => () => {
    setActiveCategory(category);
    setShowUpdateModal(Object.keys(category).length > 0);
  };

  const toggleTagModal = (category: Category) => () => {
    setActiveCategory(category);
    setShowAddTagModal(Object.keys(category).length > 0);
  };

  const onDeleteTag = (categoryId: string) => (tag: PackageTag) => async () => {
    try {
      await updateTag({
        variables: { id: tag.id, categoryIds: [...tag.categoryIds].filter((ci) => ci !== categoryId) },
      });
    } catch (error) {
      console.error(error);
    }
  };

  const generateContents = () => {
    const items: Category[] = categoryData
      ? [
          ...categoryData?.allCategories,
          {
            name: 'Uncategorized',
            tags: [
              ...([
                ...(tagData?.searchTags?.nodes || []),
                ...(tagData?.searchTags?.totalCount > tagData?.searchTags?.nodes.length
                  ? [
                      {
                        id: 'totalCount',
                        name: `${tagData?.searchTags?.totalCount - tagData?.searchTags?.nodes.length} more ...`,
                        isBlackListed: false,
                        categoryIds: [],
                      },
                    ]
                  : []),
              ] || []),
            ],
          },
        ]
      : [{ name: 'Uncategorized', tags: [] }];

    if (
      categoryLoading ||
      tagLoading ||
      updateTagMutation.loading ||
      (!items && !categoryError && !tagError && !updateTagMutation.error)
    ) {
      return <Spinner className="spinner--with-space" color="secondary" />;
    }

    return items?.map((item: Category) => (
      <SuperCategory
        key={item.id || 'Uncategorized'}
        categoryId={item.id}
        name={item.name}
        tags={item.tags}
        onAddTagToCategory={toggleTagModal(item)}
        onEditCategory={toggleUpdateModal(item)}
        onDeleteTag={onDeleteTag(item.id)}
        showButtons={!!item.id}
      />
    ));
  };

  useEffect(() => {
    getAllCategories();
    getAllTags();
  }, [getAllCategories, getAllTags]);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>AI Taxonomy | Add + Space Admin</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs
            items={[
              {
                label: t('Menu.tools'),
                path: '#',
              },
              {
                label: t('Menu.taxonomy'),
                path: `/ai/taxonomy`,
              },
            ]}
          />
          <Row className="align-items-start mb-3" style={{ minHeight: '3.5rem' }}>
            <Col lg="12">
              <div className="d-flex justify-content-between">
                <div>
                  <h1>{t('Categories.title')}</h1>
                  <p>{t('Categories.description')}</p>
                </div>

                <div>
                  <Link
                    to="#"
                    className="btn btn-primary"
                    onClick={() => setShowNewCategoryModal(!showNewCategoryModal)}
                    data-toggle="modal"
                    data-target="#myModal"
                  >
                    <i className="mdi mdi-plus-thick me-1"></i> New
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="12">{generateContents()}</Col>
          </Row>
        </Container>
      </div>
      <CreateCategoryDialog show={showNewCategoryModal} setShow={setShowNewCategoryModal} />
      {Object.keys(activeCategory).length > 0 ? (
        <AddTagToCategoryDialog
          show={showAddTagModal}
          setShow={toggleTagModal({} as Category)}
          category={activeCategory}
        />
      ) : null}
      {Object.keys(activeCategory).length > 0 ? (
        <UpdateCategoryDialog
          show={showUpdateModal}
          setShow={toggleUpdateModal({} as Category)}
          category={activeCategory}
        />
      ) : null}
    </React.Fragment>
  );
};

export default AiTools;
