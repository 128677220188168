import React from 'react';
import ReactApexChart from 'react-apexcharts';

const PieChart = () => {
  const piechartColors = ['#7633D5', '#5400CA', '#9866DF'];
  const series = [35, 70, 15, 12, 3];
  const options: Object = {
    chart: {
      width: 300,
      height: 300,
      type: 'pie',
    },
    labels: ['Mini', 'Basic', 'Medium', 'Extended', 'Most Value'],
    colors: piechartColors,
    stroke: {
      width: 0,
    },
    legend: {
      show: false,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
        },
      },
    ],
  };

  return <ReactApexChart options={options} series={series} type="pie" height="227" />;
};

export default PieChart;
