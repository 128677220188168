import React from 'react';
import { Card, CardBody, CardHeader, CardTitle, Col, Table } from 'reactstrap';
const PriceAreaTable = (props: any) => {
  return (
    <React.Fragment>
      <Col xl={6}>
        <Card>
          <CardHeader>
            <CardTitle>Details per price area</CardTitle>
          </CardHeader>
          <CardBody>
            <Table>
              <thead>
                <tr>
                  <th>Price area</th>
                  <th>Registered users</th>
                  <th>Paying users</th>
                  <th>Stored boxes</th>
                  <th>Estimated MRR</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>NY - Manhattan</td>
                  <td>123</td>
                  <td>123</td>
                  <td>123</td>
                  <td>$123</td>
                </tr>
                <tr>
                  <td>NY - Brooklyn & Queens</td>
                  <td>123</td>
                  <td>123</td>
                  <td>123</td>
                  <td>$123</td>
                </tr>
                <tr>
                  <td>NY - Bronx & Harlem</td>
                  <td>123</td>
                  <td>123</td>
                  <td>123</td>
                  <td>$123</td>
                </tr>
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};
export default PriceAreaTable;
