import React from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import TextInput from 'src/components/Forms/TextInput';

type Inputs = {
  input: string;
};

type SearchByIdProps = {};

const SearchById: React.FC<SearchByIdProps> = (props) => {
  const { t } = useTranslation();
  const history = useHistory();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>();

  const onSubmit = (data: any) => {
    if (data.input) {
      history.push(`/packages/${data.input}`);
    }
  };

  return (
    <form className="app-search d-none d-lg-block" onSubmit={handleSubmit(onSubmit)}>
      <div className="position-relative">
        <TextInput
          placeholder={t('Common.uniqueId.format')}
          errors={errors.input}
          pattern="[A-Za-z0-9]{3}-[A-Za-z0-9]{3}-[A-Za-z0-9]{3}"
          maxLength={11}
          wrapperClassName=""
          {...register('input')}
        />

        <button className="btn btn-primary" type="submit">
          <i className="bx bx-search-alt align-middle"></i>
        </button>
      </div>
    </form>
  );
};

export default SearchById;
