import { takeEvery, put, call } from 'redux-saga/effects';
import { ForgotPasswordTypes } from './actionTypes';
import { userForgotPasswordSuccess, userForgotPasswordError } from './actions';
import { forgotPassword } from 'src/helpers/backend_helper';

function* userForgotPassword({ payload: { user } }: any) {
  try {
    yield call(forgotPassword, {
      email: user.email,
    });
    yield put(userForgotPasswordSuccess('Email has been sent, please check your inbox'));
  } catch (error) {
    yield put(userForgotPasswordError(error));
  }
}

function* forgotPasswordSaga() {
  yield takeEvery(ForgotPasswordTypes.FORGOT_PASSWORD, userForgotPassword);
}

export default forgotPasswordSaga;
