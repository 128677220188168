import React, { useContext } from 'react';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import { t } from 'i18next';

import { AuthContext } from 'src/components/AuthContextSetter';
import { getValidContextsForAdminUI } from 'src/helpers/auth/authUtils';
import UserAvatar from 'src/components/UserAvatar';

const ProfileMenu: React.FC<{}> = () => {
  const authContext = useContext(AuthContext);

  authContext.currentUser = authContext.currentUser!;

  const username = `${authContext.currentUser.firstName} ${authContext.currentUser.lastName}`;

  const validContexts = getValidContextsForAdminUI(authContext.currentUser.userContexts);

  return (
    <UncontrolledDropdown className="d-inline-block">
      <DropdownToggle
        className="btn header-item bg-soft-light border-start border-end"
        id="page-header-user-dropdown"
        tag="button"
      >
        <UserAvatar user={authContext.currentUser} className="rounded-circle header-profile-user" />
        <span className="d-none d-xl-inline-block ms-2 me-1">{username}</span>
        <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-end">
        <DropdownItem tag="a" href={`/users/${authContext.currentUser.id}`}>
          <i className="bx bx-user font-size-16 align-middle me-1" />
          {t('Profile')}
        </DropdownItem>
        <DropdownItem tag="a" href={`/user/${authContext.currentUser.id}`}>
          <span className="badge bg-success float-end">11</span>
          <i className="bx bx-wrench font-size-16 align-middle me-1" />
          {t('Settings')}
        </DropdownItem>

        {validContexts.length > 1 ? (
          <>
            <div className="dropdown-divider" />
            <li>
              <h6 className="dropdown-header">Act as...</h6>
            </li>
            {validContexts.map((context) => {
              if (context.id === authContext.currentContext?.id) {
                return (
                  <DropdownItem tag="div" key={context.id}>
                    <i className="bx bx-user font-size-16 align-middle me-1" />
                    <b key={context.id}>
                      {context.context} - {context.role}
                    </b>
                  </DropdownItem>
                );
              }

              return (
                <DropdownItem tag="button" key={context.id} onClick={(ev) => authContext.setCurrentContext(context)}>
                  <i className="bx bx-user font-size-16 align-middle me-1" />
                  {context.context} - {context.role}
                </DropdownItem>
              );
            })}
          </>
        ) : null}
        <div className="dropdown-divider" />

        <Link to="/logout" className="dropdown-item">
          <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
          <span>{t('Logout')}</span>
        </Link>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default ProfileMenu;
