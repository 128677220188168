import React from 'react';
import MetaTags from 'react-meta-tags';

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Badge,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import Breadcrumbs from 'src/ux/components/Breadcrumb';

//import images
import img1 from 'src/assets/images/small/img-5.jpg';
import Timeline from 'src/components/Timeline';
import UserAvatar from 'src/components/UserAvatar';

export interface PackageDetailsProps {}

const PackageDetails: React.FC<PackageDetailsProps> = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Package Details | Add + Space Admin</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Packages" breadcrumbItem="Package details" />

          <Row>
            <h1 className="mb-3">XXX-XXX-XXX</h1>
            <Col lg="3">
              <Card>
                <CardHeader className="align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">
                    <i className="mdi mdi-account-circle me-3"></i>Owner
                  </h4>
                  <UncontrolledDropdown>
                    <DropdownToggle className="btn btn-soft-light btn-sm" tag="a">
                      <i className="mdi mdi-dots-horizontal label-icon" />
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-start">
                      <li>
                        <DropdownItem to="#">Remove</DropdownItem>
                      </li>
                      <li>
                        <DropdownItem to="#">Option 2</DropdownItem>
                      </li>
                      <li>
                        <DropdownItem to="#">Option 3</DropdownItem>
                      </li>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </CardHeader>
                <CardBody>
                  <div className="avatar-xl mb-2">
                    <UserAvatar className="img-fluid rounded-circle d-block" />
                  </div>
                  <h5>
                    <a href="/users/user">Name Nameson</a> <br />
                    123 Customer Street
                    <br /> 10001 New York, NY
                  </h5>
                </CardBody>
              </Card>
              <Card>
                <CardHeader className="align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">
                    <i className="mdi mdi-grid me-3"></i>Details
                  </h4>
                  <button type="button" className="btn btn-soft-light btn-sm me-1">
                    <i className="mdi mdi-pencil label-icon"></i>
                  </button>
                  <UncontrolledDropdown>
                    <DropdownToggle className="btn btn-soft-light btn-sm" tag="a">
                      <i className="mdi mdi-dots-horizontal label-icon" />
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-start">
                      <li>
                        <DropdownItem to="#">Option 1</DropdownItem>
                      </li>
                      <li>
                        <DropdownItem to="#">Option 2</DropdownItem>
                      </li>
                      <li>
                        <DropdownItem to="#">Option 3</DropdownItem>
                      </li>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </CardHeader>
                <CardBody>
                  <h2>
                    <Badge className="mb-2 bg-success text-black">Return: At driver</Badge>
                  </h2>
                  <h5 className="text-muted mb-0 mt-4">Transporter</h5>
                  <div className="avatar-xl mb-2 mt-2">
                    <UserAvatar className="img-fluid rounded-circle d-block" />
                  </div>
                  <h5>
                    <a href="/users/user">Transporter Name</a> <br />
                    +1 555 12345678
                  </h5>
                  <h5 className="text-muted mb-0 mt-4">Destination</h5>
                  <p>
                    Customer Name
                    <br />
                    123 Street name
                    <br />
                    10001 NY
                  </p>
                </CardBody>
              </Card>

              <Timeline title="Package log" />
            </Col>
            <Col lg="9">
              <Card>
                <CardHeader className="align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">Content</h4>
                  <UncontrolledDropdown>
                    <DropdownToggle className="btn btn-soft-light btn-sm" tag="a">
                      <i className="mdi mdi-dots-horizontal label-icon" />
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-end">
                      <li>
                        <DropdownItem to="#">Option 1</DropdownItem>
                      </li>
                      <li>
                        <DropdownItem to="#">Option 2</DropdownItem>
                      </li>
                      <li>
                        <DropdownItem to="#">Option 3</DropdownItem>
                      </li>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md={6} xl={3}>
                      <Card>
                        <img className="card-img-top img-fluid" src={img1} alt="" />
                        <CardBody>
                          <h4 className="card-title">Item title</h4>
                          <Badge className="me-1">Tag 1</Badge> <Badge className="me-1">Tag 2</Badge>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md={6} xl={3}>
                      <Card>
                        <img className="card-img-top img-fluid" src={img1} alt="" />
                        <CardBody>
                          <h4 className="card-title">Item title</h4>
                          <Badge className="me-1">Tag 1</Badge> <Badge className="me-1">Tag 2</Badge>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md={6} xl={3}>
                      <Card>
                        <img className="card-img-top img-fluid" src={img1} alt="" />
                        <CardBody>
                          <h4 className="card-title">Item title</h4>
                          <Badge className="me-1">Tag 1</Badge> <Badge className="me-1">Tag 2</Badge>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md={6} xl={3}>
                      <Card>
                        <img className="card-img-top img-fluid" src={img1} alt="" />
                        <CardBody>
                          <h4 className="card-title">Item title</h4>
                          <Badge className="me-1">Tag 1</Badge> <Badge className="me-1">Tag 2</Badge>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6} xl={3}>
                      <Card>
                        <img className="card-img-top img-fluid" src={img1} alt="" />
                        <CardBody>
                          <h4 className="card-title">Item title</h4>
                          <Badge className="me-1">Tag 1</Badge> <Badge className="me-1">Tag 2</Badge>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md={6} xl={3}>
                      <Card>
                        <img className="card-img-top img-fluid" src={img1} alt="" />
                        <CardBody>
                          <h4 className="card-title">Item title</h4>
                          <Badge className="me-1">Tag 1</Badge> <Badge className="me-1">Tag 2</Badge>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md={6} xl={3}>
                      <Card>
                        <img className="card-img-top img-fluid" src={img1} alt="" />
                        <CardBody>
                          <h4 className="card-title">Item title</h4>
                          <Badge className="me-1">Tag 1</Badge> <Badge className="me-1">Tag 2</Badge>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md={6} xl={3}>
                      <Card>
                        <img className="card-img-top img-fluid" src={img1} alt="" />
                        <CardBody>
                          <h4 className="card-title">Item title</h4>
                          <Badge className="me-1">Tag 1</Badge> <Badge className="me-1">Tag 2</Badge>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default PackageDetails;
