import { defineAbility } from '@casl/ability';

import Auth from './auth';

let cachedAuth: Auth;
const cachedAbilities = defineAbility(() => {});

const getBaseAbilities = (auth: Auth, can: Function, cannot: Function) => {
  can('visit', 'GenericPages');
};

const getAdminAbilities = (auth: Auth) =>
  defineAbility((can, cannot) => {
    getBaseAbilities(auth, can, cannot);

    can('visit', 'AdminDashboard', 'Dashboard');
    can('see', 'AdminMenu', 'Menubar');

    can('visit', 'AdminPages');
    can('visit', 'RegisteredUserPages');

    can('visit', 'Tasks', 'Tasks');
    can('visit', 'Tasks', 'TaskDetails');

    can('visit', 'Storages', 'Storages');
    can('visit', 'Storages', 'StorageDetails');

    can('visit', 'Boxes', 'Boxes');

    can('visit', 'Packages', 'PackageDetails');
  });

const geTransporterAbilities = (auth: Auth) =>
  defineAbility((can, cannot) => {
    getBaseAbilities(auth, can, cannot);

    can('visit', 'RegisteredUserPages');

    can('visit', 'AdminMyTasks', 'MyTasks');
    can('visit', 'Tasks', 'TaskDetails');
  });

const getGuestAbilities = (auth: Auth) =>
  defineAbility((can, cannot) => {
    getBaseAbilities(auth, can, cannot);

    can('visit', 'GuestPages');
  });

const getAbilities = (user: User | null, currentUserContext: UserContext | null) => {
  cachedAuth = new Auth(user, currentUserContext);

  if (!user || !user.id) {
    return getGuestAbilities(cachedAuth);
  }

  if (cachedAuth.isAdmin()) {
    return getAdminAbilities(cachedAuth);
  }

  if (cachedAuth.isTransporter()) {
    return geTransporterAbilities(cachedAuth);
  }

  return getGuestAbilities(cachedAuth);
};

export function setCurrentAuth(user: MeUser | null, userContext: UserContext | null) {
  cachedAuth = new Auth(user, userContext);

  const { rules } = getAbilities(user || null, userContext || null);
  cachedAbilities.update(rules);
  return { cachedAbilities, cachedAuth };
}

export function getCurrentUser() {
  return cachedAuth;
}

export default cachedAbilities;
