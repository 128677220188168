import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Table, Card, CardHeader, Spinner } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';

import { PACKAGE_INFOS_BY_USER_ID } from 'src/helpers/graphql';
import PackageInfoStatusBadge from 'src/components/PackageInfoStatusBadge';
import DeliveryRequestStatusBadge from 'src/components/DeliveryRequest/DeliveryRequestStatusBadge';
import { formatToLocaleDate } from 'src/helpers/ui';
import { UserPageContext } from './UserDetails';

export interface PackageListProps {}

const PackageList: React.FC<PackageListProps> = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const { user } = useContext(UserPageContext);
  const packageInfosByUserId = useQuery(PACKAGE_INFOS_BY_USER_ID, { variables: { userId: user.id } });

  const navigateToPackageDetails = (uniqueId: string) => {
    history.push(`/packages/${uniqueId}`);
  };

  if (packageInfosByUserId.loading) {
    return <Spinner className="spinner--with-space" color="secondary" />;
  }

  const packageInfos = packageInfosByUserId.data.packageInfosByUserId as UsersPackageInfoListItem[];

  if (packageInfos.length === 0) {
    return null;
  }

  return (
    <React.Fragment>
      <Card>
        <CardHeader>
          <h4 className="card-title mb-0 flex-grow-1">{t('Common.packages')}</h4>
        </CardHeader>

        <div className="card-body">
          <div className="table-responsive">
            <Table className="table mb-0 align-middle">
              <thead>
                <tr>
                  <th>{t('Common.status')}</th>
                  <th>{t('Common.direction')}</th>
                  <th>{t('Common.package_id')}</th>
                  <th>{t('Common.location')}</th>
                  <th>{t('Common.name')}</th>
                  <th>{t('Common.updated')}</th>
                </tr>
              </thead>
              <tbody>
                {packageInfos.map((packageInfo) => (
                  <tr
                    key={packageInfo.id}
                    onClick={() =>
                      packageInfo.packageUniqueId ? navigateToPackageDetails(packageInfo.packageUniqueId) : null
                    }
                  >
                    <td>
                      <PackageInfoStatusBadge status={packageInfo.status} />
                    </td>
                    <td>
                      {packageInfo.lastDeliveryInfo?.direction
                        ? t(`DeliveryRequest.direction.${packageInfo.lastDeliveryInfo?.direction}`)
                        : null}
                    </td>
                    <td>{packageInfo.packageUniqueId}</td>
                    <td>
                      <DeliveryRequestStatusBadge status={packageInfo.lastDeliveryInfo?.status} />
                    </td>
                    <td>{packageInfo.name}</td>
                    <td>{formatToLocaleDate(packageInfo.updatedAt, '5.23.2021 - 11:06 AM')}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </Card>
    </React.Fragment>
  );
};
export default PackageList;
