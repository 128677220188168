import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownItem, DropdownMenu } from 'reactstrap';

import { AVAILABLE_PAGE_SIZES } from 'src/helpers/pagination';

export interface ItemsPerPageProps {
  currentPageSize: number;
  onSelectPageSize: Function;
}

const ItemsPerPage: React.FC<ItemsPerPageProps> = ({ currentPageSize, onSelectPageSize }) => {
  const [menu, setMenu] = useState<boolean>(false);
  //KeyboardEventHandler<any> | MouseEventHandler<any> | undefined

  return (
    <Dropdown isOpen={menu} toggle={() => setMenu(!menu)}>
      <DropdownToggle caret>
        {currentPageSize}
        <span>
          <span className="caret"></span>
        </span>
      </DropdownToggle>
      <DropdownMenu>
        {AVAILABLE_PAGE_SIZES.map((size) => (
          <DropdownItem key={size} onClick={() => onSelectPageSize(size)}>
            {size}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

export default ItemsPerPage;
