import { post, del } from './api_helper';
import * as urls from './url_helper';

const getLoggedInUser = () => {
  const user = localStorage.getItem('user');
  if (user) return JSON.parse(user);
  return null;
};

const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

const login = (data: { email: string; password: string }) =>
  post(urls.LOGIN_URL, data, {
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true,
  });

const logout = () => del(urls.LOGOUT_URL);

const forgotPassword = (data: { email: string }) =>
  post(urls.FORGOT_PASSWORD_URL, data, {
    headers: { 'Content-Type': 'application/json' },
  });

export { getLoggedInUser, isUserAuthenticated, login, logout, forgotPassword };
