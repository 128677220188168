import { ForgotPasswordTypes } from './actionTypes';

export const userForgotPassword = (user: any, history: any) => {
  return {
    type: ForgotPasswordTypes.FORGOT_PASSWORD,
    payload: { user, history },
  };
};

export const userForgotPasswordSuccess = (message: string) => {
  return {
    type: ForgotPasswordTypes.FORGOT_PASSWORD_SUCCESS,
    payload: message,
  };
};

export const userForgotPasswordError = (message: any) => {
  return {
    type: ForgotPasswordTypes.FORGOT_PASSWORD_ERROR,
    payload: message,
  };
};
