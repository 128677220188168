import React, { useContext } from 'react';
import { UncontrolledDropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { useMutation, useLazyQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import config from 'src/config';
import {
  CANCEL_SUBSCRIPTION_ON_BEHALF_OF_USER,
  DELETE_USER,
  GET_COMPANY_USERS,
  USER_SUBSCRIPTIONS,
} from 'src/helpers/graphql';
import { getDisplayName } from 'src/helpers/ui';
import { getActiveSubscription } from 'src/helpers/payment/paymentUtils';
import { UserPageContext } from './UserDetails';
import { ConfirmContext } from 'src/components/ConfirmContextSetter';

export interface HeaderProps {
  onEditUser: (ev: React.MouseEvent) => void;
  subscriptions: SubscriptionInfo[];
}

const Header: React.FC<HeaderProps> = ({ onEditUser, subscriptions }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const confirmModal = useContext(ConfirmContext);

  const { user } = useContext(UserPageContext);

  const [deleteUser] = useMutation(DELETE_USER, {
    // the delete doesn't remove the deleted entity from the userlist
    refetchQueries: [{ query: GET_COMPANY_USERS }],
  });

  const [cancelSubscription, cancelSubscriptionQuery] = useMutation(CANCEL_SUBSCRIPTION_ON_BEHALF_OF_USER, {
    refetchQueries: [{ query: USER_SUBSCRIPTIONS, variables: { userId: user.id } }],
  });

  const [queryList, queryListQuery] = useLazyQuery(USER_SUBSCRIPTIONS, { variables: { userId: user.id } });
  const activeSubscription = getActiveSubscription(subscriptions)!;

  return (
    <React.Fragment>
      <div className="d-flex align-items-start justify-content-end gap-1">
        <div className="d-flex flex-wrap gap-2">
          <a href={`tel:${user.phoneNumber}`} className="btn btn-soft-light">
            <i className="me-1 mdi mdi-phone"></i>
            {user.phoneNumber}
          </a>
          <a href={`mailto:${user.email}`} className="btn btn-soft-light">
            <i className="me-1 mdi mdi-email"></i>
            {user.email}
          </a>
          <a href={`${config.intercomLinkToUser}${user.id}`} className="btn btn-soft-light">
            <i className="me-1 mdi mdi-chat"></i>Intercom
          </a>
        </div>

        <div>
          <UncontrolledDropdown>
            <DropdownToggle className="btn btn-soft-light" tag="a">
              <i className="mdi mdi-dots-horizontal"></i>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end">
              <li>
                <DropdownItem onClick={(ev) => onEditUser(ev)}>Edit user</DropdownItem>
              </li>
              {subscriptions?.length > 0 ? (
                <li>
                  <DropdownItem
                    onClick={(ev) => {
                      confirmModal.show({
                        modalTitle: t('UserDetails.cancelsubscription.confirm.title'),
                        modalText: t('UserDetails.cancelsubscription.confirm.text', { name: getDisplayName(user) }),
                        successMessage: t('UserDetails.cancelsubscription.confirm.success'),
                        onConfirm: async () => {
                          await cancelSubscription({
                            variables: {
                              subscriptionId: activeSubscription.id,
                              userId: user.id,
                            },
                          });

                          // it's not in the refetchQueries, because we need to wait for it's results
                          // before we can hide this modal
                          await queryList();
                        },
                      });
                    }}
                  >
                    {t('UserDetails.cancelsubscription.menu')}
                  </DropdownItem>
                </li>
              ) : null}
              <li>
                <DropdownItem
                  onClick={(ev) => {
                    confirmModal.show({
                      modalTitle: t('UserDetails.delete.confirm.title'),
                      modalText: t('UserDetails.delete.confirm.text', { name: getDisplayName(user) }),
                      successMessage: t('UserDetails.delete.confirm.success'),
                      onConfirm: async () => {
                        await deleteUser({
                          variables: {
                            id: user.id,
                          },
                        });
                        history.push('/users');
                      },
                    });
                  }}
                  className="text-danger"
                >
                  {t('UserDetails.delete.menu')}
                </DropdownItem>
              </li>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      </div>
    </React.Fragment>
  );
};
export default Header;
