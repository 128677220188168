import React, { useState } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';

import { FilterOptionType } from 'src/helpers/pagination';

export interface FilterDropdownProps {
  filterOptions?: FilterOptionType[];
  currentValue?: string;
  onFilterChange: (data: string) => void;
  loading: boolean;
}

const FilterDropdown: React.FC<FilterDropdownProps> = ({ filterOptions, currentValue, onFilterChange }) => {
  const [opened, setOpened] = useState(false);

  const onChangeFilter = (value: string) => {
    onFilterChange(value);
  };

  const selected = filterOptions?.find((option) => option.value === currentValue);

  return (
    <Dropdown toggle={(ev: any) => setOpened(!opened)} isOpen={opened}>
      <DropdownToggle tag="button" className="btn btn-soft-light">
        <i className="mdi mdi-filter" /> {selected?.label}
        {opened ? <i className="mdi mdi-chevron-up" /> : <i className="mdi mdi-chevron-down" />}
      </DropdownToggle>
      <DropdownMenu>
        {filterOptions?.map((filterOption, ix) => {
          if (filterOption.label === '---') {
            return <DropdownItem divider key={ix} />;
          }
          return (
            <DropdownItem
              onClick={(ev) => onChangeFilter(filterOption.value)}
              key={ix}
              active={currentValue === filterOption.value}
            >
              {filterOption.label}
            </DropdownItem>
          );
        })}
      </DropdownMenu>
    </Dropdown>
  );
};

export default FilterDropdown;
