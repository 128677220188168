import React, { useState, useContext, useEffect } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useMutation, ApolloError } from '@apollo/client';

import { MISS_DELIVERY_AT_USER, MISS_DELIVERY_AT_USER_ON_BEHALF_OF_TRANSPORTER } from 'src/helpers/graphql';
import { AuthContext } from 'src/components/AuthContextSetter';
import { DeliveryRequestStatus } from 'src/types/types';
import ModalForm, { setFieldErrors } from 'src/components/Forms/ModalForm';

type Inputs = {};

type StatusToMissed = {
  show: boolean;
  setShow: Function;
  handleSingleOrBulkSubmit: Function;
  tasks: (DeliveryRequestListItem | DeliveryRequestDetails)[];
};

const StatusToMissed: React.FC<StatusToMissed> = ({ show, setShow, handleSingleOrBulkSubmit, tasks }) => {
  const authContext = useContext(AuthContext);

  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors },
  } = useForm<Inputs>();

  const [globalError, setGlobalError] = useState<string | ApolloError | undefined>('');

  const [missDeliveryAtUser, { data, loading, error }] = useMutation(
    authContext.auth.isTransporter() ? MISS_DELIVERY_AT_USER : MISS_DELIVERY_AT_USER_ON_BEHALF_OF_TRANSPORTER,
  );

  const onSubmit: SubmitHandler<Inputs> = async (data) =>
    handleSingleOrBulkSubmit({
      data: tasks.map((task) => ({
        ...(authContext.auth.isTransporter() ? {} : { transporterContextId: task.transporterContextId }),
        deliveryRequestId: task.id,
      })),
      submitFn: missDeliveryAtUser,
      setFieldErrors: (err: any) => setFieldErrors(err, setError, setGlobalError),
    });

  useEffect(() => {
    setFieldErrors(error, setError, setGlobalError);
  }, [error]);

  useEffect(() => {
    reset();
  }, [tasks?.[0]?.id]);

  return (
    <ModalForm
      title="Missed delivery"
      show={show}
      setShow={setShow}
      onSubmit={handleSubmit(onSubmit)}
      loading={loading}
      globalError={globalError}
    >
      <p>
        {tasks.length === 1
          ? 'Do you really want to mark this task as missed?'
          : 'Do you really want to mark these tasks as missed?'}
      </p>
    </ModalForm>
  );
};

export default StatusToMissed;
