import { gql } from '@apollo/client';

export const MAX_PAGINATED_ITEMS_COUNT = 99999999;

const FullAddressFragment = gql`
  fragment FullAddressFragment on Address {
    areaId
    city
    clientId
    country
    door
    floor
    id
    info
    name
    isActive
    postalCode
    route
    state
    streetNumber
    googlePlaceId
  }
`;

const MeUserFragment = gql`
  ${FullAddressFragment}

  fragment MeUserFragment on User {
    id
    createdAt
    firstName
    lastName
    email
    phoneNumber
    userContexts {
      id
      role
      context
      company {
        id
        name
      }
      addresses {
        ...FullAddressFragment
      }
    }
  }
`;

const FullUserFragment = gql`
  ${MeUserFragment}

  fragment FullUserFragment on User {
    ...MeUserFragment
    stripeCustomerId
  }
`;

const ListItemUserFragment = gql`
  fragment ListItemUserFragment on User {
    id
    lastName
    firstName
    email
    phoneNumber
    userContexts {
      id
      context
      role
    }
  }
`;

const BaseUserInfoFragment = gql`
  fragment BaseUserInfoFragment on BasicUserInfo {
    id
    lastName
    firstName
  }
`;

export const GET_CURRENT_USER = gql`
  ${MeUserFragment}

  query Me {
    me {
      ...MeUserFragment
    }
  }
`;

// !FIXME replace the query to use the proper endoint, when it's ready (and the proper ts class as well, when it's used)
export const GET_BASE_USER = gql`
  ${FullUserFragment}

  query getUser($id: String!) {
    user(id: $id) {
      ...FullUserFragment
    }
  }
`;

export const GET_USER = gql`
  ${FullUserFragment}

  query getUser($id: String!) {
    user(id: $id) {
      ...FullUserFragment
    }
  }
`;

export const GET_COMPANY_USERS = gql`
  ${ListItemUserFragment}

  query AllUsers(
    $first: Int
    $last: Int
    $operator: Operator
    $after: String
    $before: String
    $filters: [Filter!]
    $childExpressions: [FiltersExpression!]
  ) {
    allUsers(
      filters: { operator: $operator, filters: $filters, childExpressions: $childExpressions }
      pagination: { first: $first, last: $last, after: $after, before: $before }
    ) {
      edges {
        cursor
        node {
          id
        }
      }
      cursors
      totalCount
      edges {
        node {
          ...ListItemUserFragment
        }
      }
    }
  }
`;

export const EDIT_USER = gql`
  ${FullUserFragment}

  mutation editUser($id: String!, $firstName: String!, $lastName: String!) {
    updateUser(data: { id: $id, firstName: $firstName, lastName: $lastName }) {
      ...FullUserFragment
    }
  }
`;

export const DELETE_USER = gql`
  mutation deleteUser($id: String!) {
    deleteUser(id: $id) {
      id
    }
  }
`;

export const GET_TRANSPORTERS_REQUEST = gql`
  query allTransporterUsers($first: Int, $last: Int, $after: String, $before: String) {
    allTransporterUsers(first: $first, last: $last, after: $after, before: $before) {
      edges {
        cursor
        node {
          id
        }
      }
      cursors
      totalCount
      edges {
        node {
          firstName
          lastName
          userContexts {
            id
            role
            context
          }
        }
      }
    }
  }
`;

const SearchUserInfoFragment = gql`
  fragment SearchUserInfoFragment on BasicUserInfo {
    id
    lastName
    firstName
    userContexts {
      id
      context
    }
  }
`;

export const SEARCH_USERS = gql`
  ${SearchUserInfoFragment}

  query searchUsers($searchString: String!, $context: UserContextType!) {
    searchUsers(input: { searchString: $searchString, context: $context }) {
      ...SearchUserInfoFragment
    }
  }
`;

export const GET_VIRTUAL_STORAGES = gql`
  ${FullAddressFragment}
  ${BaseUserInfoFragment}

  query allVirtualStoragesWithStorage {
    allVirtualStoragesWithStorage {
      edges {
        cursor
        node {
          id
        }
      }
      cursors
      totalCount
      edges {
        node {
          user {
            ...BaseUserInfoFragment
          }
          address {
            ...FullAddressFragment
          }
          storage {
            name
          }
        }
      }
    }
  }
`;

export const VIRTUAL_STORAGES_WITH_STORAGE = gql`
  ${FullAddressFragment}
  ${BaseUserInfoFragment}

  query virtualStoragesWithStorage($id: String!) {
    virtualStoragesWithStorage(id: $id) {
      id
      updatedAt
      user {
        ...BaseUserInfoFragment
      }
      address {
        ...FullAddressFragment
      }
      storage {
        depth
        height
        width
        standardBoxCapacity
        volume
        name
        type
      }
    }
  }
`;

const ListItemDeliveryRequestFragment = gql`
  ${FullAddressFragment}
  ${BaseUserInfoFragment}

  fragment ListItemDeliveryRequestFragment on DeliveryRequest {
    id
    userId
    user {
      ...BaseUserInfoFragment
    }
    status
    fromAddress {
      ...FullAddressFragment
    }
    toAddress {
      ...FullAddressFragment
    }
    pickupRequestTo
    packageInfoId
    bagId
    bag {
      uniqueId
    }
    package {
      uniqueId
    }
    scheduleType
    direction
    transporterContextId
    toVirtualStorageId
    transporterUser {
      ...BaseUserInfoFragment
    }
    updatedAt
  }
`;

export const GET_ALL_DELIVERY_REQUESTS = gql`
  ${ListItemDeliveryRequestFragment}

  query allDeliveryRequests($first: Int, $last: Int, $after: String, $before: String, $filters: [Filter!]) {
    allDeliveryRequests(
      filters: { operator: OR, filters: $filters }
      pagination: { first: $first, last: $last, after: $after, before: $before }
    ) {
      edges {
        cursor
        node {
          id
        }
      }
      cursors
      totalCount
      edges {
        node {
          ...ListItemDeliveryRequestFragment
        }
      }
    }
  }
`;

export const GET_MY_DELIVERY_REQUESTS = gql`
  ${ListItemDeliveryRequestFragment}

  query myDeliveryRequests {
    myDeliveryRequests {
      ...ListItemDeliveryRequestFragment
    }
  }
`;

const PackageInfoFragment = gql`
  fragment PackageInfoFragment on PackageInfo {
    id
    name
    status
    packageId
    bagId
    userId
    updatedAt
  }
`;

const DetailedDeliveryRequestFragment = gql`
  ${BaseUserInfoFragment}
  ${FullAddressFragment}
  ${PackageInfoFragment}

  fragment DetailedDeliveryRequestFragment on DeliveryRequest {
    id
    status
    userId
    user {
      ...BaseUserInfoFragment
    }
    transporterContextId
    transporterUser {
      ...BaseUserInfoFragment
    }
    toVirtualStorageId
    pickupRequestFrom
    pickupRequestTo
    pickupAt
    dropOffAt
    scheduleType
    bagId
    bag {
      uniqueId
    }
    packageInfoId
    packageInfo {
      ...PackageInfoFragment
    }
    package {
      uniqueId
    }
    direction
    fromAddress {
      ...FullAddressFragment
    }
    toAddress {
      ...FullAddressFragment
    }
    updatedAt
  }
`;

export const GET_DELIVERY_REQUEST = gql`
  ${DetailedDeliveryRequestFragment}

  query deliveryRequest($id: String!) {
    deliveryRequest(id: $id) {
      ...DetailedDeliveryRequestFragment
    }
  }
`;

export const EDIT_DELIVERY_REQUEST = gql`
  ${DetailedDeliveryRequestFragment}

  mutation editDeliveryRequest(
    $id: String!
    $status: DeliveryRequestStatus
    $transporterContextId: String
    $toVirtualStorageId: String
  ) {
    updateDeliveryRequest(
      data: {
        id: $id
        status: $status
        transporterContextId: $transporterContextId
        toVirtualStorageId: $toVirtualStorageId
      }
    ) {
      ...DetailedDeliveryRequestFragment
    }
  }
`;

export const EDIT_DELIVERY_DATE = gql`
  ${DetailedDeliveryRequestFragment}

  mutation editDeliveryRequest($id: String!, $pickupRequestFrom: DateTime, $pickupRequestTo: DateTime) {
    updateDeliveryRequest(data: { id: $id, pickupRequestFrom: $pickupRequestFrom, pickupRequestTo: $pickupRequestTo }) {
      ...DetailedDeliveryRequestFragment
    }
  }
`;

export const DELETE_DELIVERY_REQUEST = gql`
  mutation deleteDeliveryRequest($id: String!) {
    deleteDeliveryRequest(id: $id) {
      id
    }
  }
`;

export const PICKUP_PACKAGE_AT_USER_ON_BEHALF_OF_TRANSPORTER = gql`
  ${DetailedDeliveryRequestFragment}

  mutation pickupPackageAtUserOnBehalfOfTransporter(
    $transporterContextId: String!
    $bagUniqueId: String
    $packageUniqueId: String
  ) {
    pickupPackageAtUserOnBehalfOfTransporter(
      data: {
        transporterContextId: $transporterContextId
        bagUniqueId: $bagUniqueId
        packageUniqueId: $packageUniqueId
      }
    ) {
      ...DetailedDeliveryRequestFragment
    }
  }
`;

export const PICKUP_PACKAGE_AT_USER = gql`
  ${DetailedDeliveryRequestFragment}

  mutation pickupPackageAtUser($bagUniqueId: String, $packageUniqueId: String) {
    pickupPackageAtUser(data: { bagUniqueId: $bagUniqueId, packageUniqueId: $packageUniqueId }) {
      ...DetailedDeliveryRequestFragment
    }
  }
`;

export const PICKUP_PACKAGE_AT_STORAGE_ON_BEHALF_OF_TRANSPORTER = gql`
  ${DetailedDeliveryRequestFragment}

  mutation pickupPackageAtStorageOnBehalfOfTransporter($transporterContextId: String!, $packageUniqueId: String) {
    pickupPackageAtStorageOnBehalfOfTransporter(
      data: { transporterContextId: $transporterContextId, packageUniqueId: $packageUniqueId }
    ) {
      ...DetailedDeliveryRequestFragment
    }
  }
`;

export const PICKUP_PACKAGE_AT_STORAGE = gql`
  ${DetailedDeliveryRequestFragment}

  mutation pickupPackageAtStorage($packageUniqueId: String) {
    pickupPackageAtStorage(data: { packageUniqueId: $packageUniqueId }) {
      ...DetailedDeliveryRequestFragment
    }
  }
`;

export const MISS_DELIVERY_AT_USER_ON_BEHALF_OF_TRANSPORTER = gql`
  ${DetailedDeliveryRequestFragment}

  mutation missDeliveryAtUserOnBehalfOfTransporter($transporterContextId: String!, $deliveryRequestId: String!) {
    missDeliveryAtUserOnBehalfOfTransporter(
      data: { transporterContextId: $transporterContextId, deliveryRequestId: $deliveryRequestId }
    ) {
      ...DetailedDeliveryRequestFragment
    }
  }
`;

export const MISS_DELIVERY_AT_USER = gql`
  ${DetailedDeliveryRequestFragment}

  mutation missDeliveryAtUser($deliveryRequestId: String!) {
    missDeliveryAtUser(data: { deliveryRequestId: $deliveryRequestId }) {
      ...DetailedDeliveryRequestFragment
    }
  }
`;

export const DROP_OFF_PACKAGE_AT_USER_ON_BEHALF_OF_TRANSPORTER = gql`
  ${DetailedDeliveryRequestFragment}

  mutation dropOffPackageOnBehalfOfTransporter($transporterContextId: String!, $packageUniqueId: String!) {
    dropOffPackageOnBehalfOfTransporter(
      data: { transporterContextId: $transporterContextId, packageUniqueId: $packageUniqueId }
    ) {
      ...DetailedDeliveryRequestFragment
    }
  }
`;

export const DROP_OFF_PACKAGE_AT_USER = gql`
  ${DetailedDeliveryRequestFragment}

  mutation dropOffPackage($packageUniqueId: String!) {
    dropOffPackage(data: { packageUniqueId: $packageUniqueId }) {
      ...DetailedDeliveryRequestFragment
    }
  }
`;

export const CANCEL_DELIVERY_REQUEST = gql`
  ${DetailedDeliveryRequestFragment}

  mutation cancelDeliveryRequest($id: String!) {
    cancelDeliveryRequest(id: $id) {
      ...DetailedDeliveryRequestFragment
    }
  }
`;

const VirtualStorageWithAddressFragment = gql`
  ${FullAddressFragment}

  fragment VirtualStorageWithAddressFragment on VirtualStorageWithAddress {
    addressId
    id
    name
    type
    address {
      ...FullAddressFragment
    }
  }
`;

const PackageRequestFragment = gql`
  ${BaseUserInfoFragment}
  ${VirtualStorageWithAddressFragment}

  fragment PackageRequestFragment on PackageRequest {
    id
    status
    amount
    packages {
      uniqueId
    }
    user {
      ...BaseUserInfoFragment
    }
    virtualStorageId
    virtualStorage {
      ...VirtualStorageWithAddressFragment
    }
  }
`;

export const GET_PACKAGE_REQUESTS = gql`
  ${PackageRequestFragment}

  query allPackageRequests($first: Int, $last: Int, $after: String, $before: String, $filters: [Filter!]) {
    allPackageRequests(
      filters: { operator: OR, filters: $filters }
      pagination: { first: $first, last: $last, after: $after, before: $before }
    ) {
      edges {
        cursor
        node {
          id
        }
      }
      cursors
      totalCount
      edges {
        node {
          ...PackageRequestFragment
        }
      }
    }
  }
`;

export const UPDATE_PACKAGE_REQUEST = gql`
  ${PackageRequestFragment}

  mutation updatePackageRequest($id: String!, $status: PackageRequestStatus, $packageUniqueIds: [String!]) {
    updatePackageRequest(data: { id: $id, status: $status, packageUniqueIds: $packageUniqueIds }) {
      ...PackageRequestFragment
    }
  }
`;

export const DELETE_PACKAGE_REQUEST = gql`
  mutation deletePackageRequest($id: String!) {
    deletePackageRequest(id: $id) {
      id
    }
  }
`;

export const ADD_TRANSPORTER_CONTEXT = gql`
  mutation createUserContext($userId: String!) {
    createUserContext(data: { context: TRANSPORTER, role: USER, userId: $userId }) {
      id
    }
  }
`;

export const DELETE_TRANSPORTER_CONTEXT = gql`
  mutation deleteUserContext($id: String!) {
    deleteUserContext(id: $id) {
      id
    }
  }
`;

export const ALL_PAYMENT_PRODUCTS = gql`
  query allPaymentProducts {
    allPaymentProducts {
      id
      name
      type
      prices {
        id
        amount
        currency
      }
    }
  }
`;

export const ALL_PAYMENT_PLANS = gql`
  query allPaymentPlans {
    allPaymentPlans {
      id
      name
    }
  }
`;

export const USER_PAYMENT_METHODS = gql`
  query userPaymentMethods($userId: String!) {
    userPaymentMethods(userId: $userId) {
      id
      brand
      country
      expMonth
      expYear
      last4
    }
  }
`;

export const USER_SUBSCRIPTIONS = gql`
  query userSubscriptions($userId: String!) {
    userSubscriptions(userId: $userId) {
      id
      paymentStatus
      plan {
        id
        name
        includedBags
        includedPackages
        pickups {
          included
        }
        returns {
          included
        }
      }
      price {
        id
      }
    }
  }
`;

export const CANCEL_SUBSCRIPTION_ON_BEHALF_OF_USER = gql`
  mutation cancelSubscriptionOnBehalfOfUser($subscriptionId: String!, $userId: String!) {
    cancelSubscriptionOnBehalfOfUser(data: { subscriptionId: $subscriptionId, userId: $userId }) {
      id
    }
  }
`;

export const USER_PAYMENT_INTENTS = gql`
  query userPaymentIntents($userId: String!) {
    userPaymentIntents(userId: $userId) {
      amount
      consumedBy
      id
      currency
      oneTimePriceId
      oneTimeProductId
      priceId
      recurrence
      status
      productId
      createdAt
      isConsumed
      redirectUrl
    }
  }
`;

export const ALL_BATCHES = gql`
  ${BaseUserInfoFragment}

  query allBatches {
    allBatches {
      id
      type
      createdAt
      user {
        ...BaseUserInfoFragment
      }
    }
  }
`;

export const ALL_BAGS_BY_BATCH_ID = gql`
  query allBagsByBatchId($batchId: String!) {
    allBagsByBatchId(batchId: $batchId) {
      uniqueId
    }
  }
`;

export const ALL_PACKAGES_BY_BATCH_ID = gql`
  query allPackagesByBatchId($batchId: String!) {
    allPackagesByBatchId(batchId: $batchId) {
      uniqueId
    }
  }
`;

export const CREATE_BAGS = gql`
  mutation createBags($amount: Int!, $uniqueIdPrefix: String) {
    createBags(amount: $amount, uniqueIdPrefix: $uniqueIdPrefix) {
      id
    }
  }
`;

export const CREATE_PACKAGES = gql`
  mutation createPackages($amount: Int!, $packageTypeId: String!, $uniqueIdPrefix: String) {
    createPackages(data: { amount: $amount, packageTypeId: $packageTypeId, uniqueIdPrefix: $uniqueIdPrefix }) {
      id
    }
  }
`;

export const ALL_CATEGORIES = gql`
  query allCategories {
    allCategories {
      createdAt
      id
      isHidden
      name
      tags {
        categoryIds
        createdAt
        id
        name
      }
    }
  }
`;

export const CREATE_CATEGORY = gql`
  mutation createCategory($name: String!) {
    createCategory(data: { name: $name }) {
      id
    }
  }
`;

export const UPDATE_CATEGORY = gql`
  mutation updateCategory($id: String!, $name: String!) {
    updateCategory(data: { id: $id, name: $name }) {
      id
    }
  }
`;

export const UPDATE_TAG = gql`
  mutation updateTag($id: String!, $categoryIds: [String!]!, $isBlacklisted: Boolean) {
    updateTag(data: { id: $id, categoryIds: $categoryIds, isBlacklisted: $isBlacklisted }) {
      id
    }
  }
`;

export const ALL_BLACKLISTED_PACKAGE_TAGS = gql`
  query allBlacklistedPackageTags {
    allBlacklistedPackageTags {
      clientId
      categoryIds
      updatedAt
      source
      score
      name
      isBlacklisted
      id
      deletedAt
      createdAt
    }
  }
`;

export const SEARCH_TAGS = gql`
  query searcTags(
    $categoryId: String
    $includeBlacklisted: Boolean
    $query: String
    $withoutCategory: Boolean
    $source: PackageTagSource
  ) {
    searchTags(
      data: {
        categoryId: $categoryId
        includeBlacklisted: $includeBlacklisted
        query: $query
        withoutCategory: $withoutCategory
        source: $source
      }
    ) {
      nodes {
        categoryIds
        isBlacklisted
        id
        name
      }
      totalCount
    }
  }
`;

export const ADD_PACKAGE_ITEM_TAG_TO_CATEGORY = gql`
  mutation addPackageItemTagToCategory($tagIds: [String!]!, $categoryId: String!) {
    addPackageItemTagsToCategory(data: { tagIds: $tagIds, categoryId: $categoryId }) {
      tags {
        id
        name
        isBlacklisted
      }
      isHidden
      id
      name
    }
  }
`;

export const PACKAGE_INFOS_BY_USER_ID = gql`
  query packageInfosByUserId($userId: String!) {
    packageInfosByUserId(userId: $userId) {
      id
      updatedAt
      packageUniqueId
      name
      status
      lastDeliveryInfo {
        id
        status
        direction
      }
    }
  }
`;

export const PLACE_AUTOCOMPLETE = gql`
  query placeAutoComplete($input: String!, $location: LocationInput, $sessionToken: String!) {
    placeAutoComplete(data: { input: $input, location: $location, sessionToken: $sessionToken }) {
      description
      distance
      googlePlaceId
    }
  }
`;

const PlaceDetailsFragment = gql`
  fragment PlaceDetailsFragment on PlaceDetails {
    city
    country
    distance
    formattedAddress
    googlePlaceId
    location {
      lat
      lng
    }
    postalCode
    route
    state
    streetNumber
  }
`;

export const PLACE_DETAILS = gql`
  ${PlaceDetailsFragment}

  query placeDetails($googlePlaceId: String!, $sessionToken: String!) {
    placeDetails(data: { googlePlaceId: $googlePlaceId, sessionToken: $sessionToken }) {
      ...PlaceDetailsFragment
    }
  }
`;

export const IS_ADDRESS_ELIGIBLE = gql`
  query isAddressEligible($country: String!, $location: LocationInput!) {
    isAddressEligible(data: { country: $country, location: $location }) {
      isEligible
      area {
        id
      }
    }
  }
`;

export const PACKAGE_BY_UNIQUE_ID = gql`
  query packageByUniqueId($uniqueId: String!) {
    packageByUniqueId(uniqueId: $uniqueId) {
      id
      uniqueId
      ownerId
      name
      note
    }
  }
`;

export const PACKAGE_INFO_BY_PACKAGE_UNIQUE_ID = gql`
  query packageInfoByPackageUniqueId($uniqueId: String!) {
    packageInfoByPackageUniqueId(uniqueId: $uniqueId) {
      id
      description
      items {
        description
        name
        status
        files {
          id
          path
        }
        tags {
          name
          isBlacklisted
        }
      }
      lastDeliveryInfo {
        id
      }
    }
  }
`;

export const CREATE_ADDRESS = gql`
  mutation createAddress(
    $apartmentNumber: String
    $areaId: String
    $city: String!
    $country: String!
    $door: String
    $floor: String
    $googlePlaceId: String
    $info: String
    $isSetupComplete: Boolean!
    $location: LocationInput!
    $name: String
    $postalCode: String!
    $route: String!
    $state: String
    $streetNumber: String!
  ) {
    createAddress(
      data: {
        apartmentNumber: $apartmentNumber
        areaId: $areaId
        city: $city
        country: $country
        door: $door
        floor: $floor
        googlePlaceId: $googlePlaceId
        info: $info
        isSetupComplete: $isSetupComplete
        location: $location
        name: $name
        postalCode: $postalCode
        route: $route
        state: $state
        streetNumber: $streetNumber
      }
    ) {
      id
    }
  }
`;

export const UNASSIGN_PACKAGE = gql`
  mutation unassignPackage($id: String, $uniqueId: String) {
    unassignPackage(data: { id: $id, uniqueId: $uniqueId }) {
      id
    }
  }
`;

export const TRANSPORTER_DELIVERY_REQUESTS = gql`
  ${FullAddressFragment}

  query transporterDeliveryRequests($userId: String!) {
    transporterDeliveryRequests(userId: $userId) {
      id
      status
      direction
      fromAddress {
        ...FullAddressFragment
      }
      toAddress {
        ...FullAddressFragment
      }
      updatedAt
    }
  }
`;

export const CREATE_STORAGE = gql`
  mutation createStorage(
    $addressId: String!
    $depth: Int
    $height: Int
    $name: String
    $standardBoxCapacity: Int
    $type: StorageStatus
    $volume: Int
    $width: Int
  ) {
    createStorage(
      data: {
        addressId: $addressId
        depth: $depth
        height: $height
        name: $name
        standardBoxCapacity: $standardBoxCapacity
        type: $type
        volume: $volume
        width: $width
      }
    ) {
      id
    }
  }
`;

export const UPDATE_STORAGE = gql`
  mutation updateStorage(
    $id: String!
    $depth: Int
    $height: Int
    $name: String
    $standardBoxCapacity: Int
    $type: StorageStatus
    $volume: Int
    $width: Int
  ) {
    updateStorage(
      data: {
        id: $id
        depth: $depth
        height: $height
        name: $name
        standardBoxCapacity: $standardBoxCapacity
        type: $type
        volume: $volume
        width: $width
      }
    ) {
      id
    }
  }
`;

export const PACKAGES_BY_VIRTUAL_STORAGE_ID = gql`
  query packagesByVirtualStorageId($id: String!, $first: Int, $last: Int, $after: String, $before: String) {
    packagesByVirtualStorageId(id: $id, pagination: { first: $first, last: $last, after: $after, before: $before }) {
      edges {
        cursor
        node {
          id
        }
      }
      cursors
      totalCount
      edges {
        node {
          name
          note
          ownerId
          packageType
          status
          uniqueId
          updatedAt
        }
      }
    }
  }
`;

export const ROLLBACK_DELIVERY_STATUS = gql`
  ${DetailedDeliveryRequestFragment}
  mutation rollbackDeliveryStatus($id: String!, $status: DeliveryRequestStatus!) {
    rollbackDeliveryStatus(data: { id: $id, status: $status }) {
      ...DetailedDeliveryRequestFragment
    }
  }
`;
