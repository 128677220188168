import { UserContextType, UserContextRoleType } from 'src/types/types';

class Auth {
  user: User | null;
  currentContext: UserContext | null;

  constructor(user: User | null, currentContext: UserContext | null) {
    this.user = user;
    this.currentContext = currentContext;
  }

  isGuest() {
    return !this.currentContext;
  }

  isContextSearcher(context: UserContext | null) {
    return context?.context === UserContextType.SEARCHER;
  }

  isSearcher() {
    return this.isContextSearcher(this.currentContext);
  }

  hasSearcherContext() {
    return this.user?.userContexts.find(this.isContextSearcher);
  }

  isContextAdmin(context: UserContext | null) {
    return context?.role === UserContextRoleType.ADMIN && context?.context === UserContextType.BACKOFFICE;
  }

  isAdmin() {
    return this.isContextAdmin(this.currentContext);
  }

  hasAdminContext() {
    return this.user?.userContexts.find(this.isContextAdmin);
  }

  isContextTransporter(context: UserContext | null) {
    return context?.context === UserContextType.TRANSPORTER;
  }

  isTransporter() {
    return this.isContextTransporter(this.currentContext);
  }

  hasTransporterContext() {
    return this.user?.userContexts.find(this.isContextTransporter);
  }

  ifContextIsProvider(context: UserContext | null) {
    return context?.context === UserContextType.PROVIDER;
  }

  isProvider() {
    return this.ifContextIsProvider(this.currentContext);
  }

  hasProviderContext() {
    return this.user?.userContexts.find(this.ifContextIsProvider);
  }

  getHomeUrl() {
    if (this.isAdmin()) {
      return '/tasks';
    }

    if (this.isTransporter()) {
      return '/my-tasks';
    }

    // isGuest, isSearcher
    return '/login';
  }
}

export default Auth;
