import ListAllUxPages from 'src/ux/pages/ListAllUxPages';

// Storages
import Storages from 'src/ux/pages/Storages';
import StorageDetails from 'src/ux/pages/Storages/StorageDetails';
import PackageDetails from 'src/ux/pages/Storages/PackageDetails';

// Reports
import Reports from 'src/ux/pages/Reports';
import TransporterReport from 'src/ux/pages/Reports/TransporterReport';

//Dashboard
import Dashboard from 'src/ux/pages/Dashboard';

//Calendar
import Calendar from 'src/ux/pages/Calendar';

//Chat
import Chat from 'src/ux/pages/Chat/Chat';

//Email Inbox
import Inbox from 'src/ux/pages/Email/Inbox';
import EmailRead from 'src/ux/pages/Email/email-read';

//Invoice
import InvoiceList from 'src/ux/pages/Invoices/invoice-list';
import InvoiceDetails from 'src/ux/pages/Invoices/invoice-details';

//Contacts
import ContactsGrid from 'src/ux/pages/Contacts/contactsGrid';
import ContactsList from 'src/ux/pages/Contacts/ContactList/contacts-list';
import ContactsProfile from 'src/ux/pages/Contacts/ContactsProfile/contacts-profile';

//Utility
import PageStarter from 'src/ux/pages/Utility/PageStarter';
import PageMaintenance from 'src/ux/pages/Utility/PageMaintenance';
import PageTimeline from 'src/ux/pages/Utility/PageTimeline';
import PageFaqs from 'src/ux/pages/Utility/PageFAQs';
import PagePricing from 'src/ux/pages/Utility/PagePricing';
import Error404 from 'src/ux/pages/Utility/Error404';
import Error500 from 'src/ux/pages/Utility/Error500';

//UI Components
import UiAlert from 'src/ux/pages/UiComponents/UiAlert';
import UiButton from 'src/ux/pages/UiComponents/UiButton';
import UiCard from 'src/ux/pages/UiComponents/UiCard';
import UiCarousel from 'src/ux/pages/UiComponents/UiCarousel';
import UiDropdowns from 'src/ux/pages/UiComponents/UiDropdowns';
import UiGrid from 'src/ux/pages/UiComponents/UiGird';
import UiModal from 'src/ux/pages/UiComponents/UiModals';
import UiImages from 'src/ux/pages/UiComponents/UiImages';
import UiDrawer from 'src/ux/pages/UiComponents/UiDrawer';
import UiProgressbar from 'src/ux/pages/UiComponents/UiProgressbar';
import UiTabsAccordions from 'src/ux/pages/Utility/UiTabsAccordions';
import UiTypography from 'src/ux/pages/Utility/UiTypography';
import UiVideo from 'src/ux/pages/Utility/UiVideo';
import UiGeneral from 'src/ux/pages/UiComponents/UiGeneral';
import UiColors from 'src/ux/pages/UiComponents/UiColors';

//Extended pages
import UiLightbox from 'src/ux/pages/Extended/Lightbox';
import RangeSlider from 'src/ux/pages/Extended/Rangeslider';
import UiSweetAlert from 'src/ux/pages/Extended/SweetAlert';
import SessionTimeout from 'src/ux/pages/Extended/SessionTimeout';
import UiRating from 'src/ux/pages/Extended/UiRating';
import Notifications from 'src/ux/pages/Extended/Notifications';

//Forms pages
import FormElements from 'src/ux/pages/Forms/FormElements';
import FormValidation from 'src/ux/pages/Forms/FormValidation';
import AdvancedPlugins from 'src/ux/pages/Forms/AdvancedPlugins';
import FormEditors from 'src/ux/pages/Forms/FormEditors';
import FormUpload from 'src/ux/pages/Forms/FormUpload';
import FormWizard from 'src/ux/pages/Forms/FormWizard';
import FormMask from 'src/ux/pages/Forms/FormMask';

//Tables
import BasicTable from 'src/ux/pages/Tables/BasicTables';
import DatatableTables from 'src/ux/pages/Tables/DatatableTables';
import ResponsiveTables from 'src/ux/pages/Tables/ResponsiveTables';
import EditableTables from 'src/ux/pages/Tables/EditableTables';

//Charts
import Apexchart from 'src/ux/pages/Charts/Apexcharts';
import EChart from 'src/ux/pages/Charts/EChart';
import ChartjsChart from 'src/ux/pages/Charts/ChartjsChart';
import SparklineChart from 'src/ux/pages/Charts/SparklineChart';

//Icons
import IconBoxicons from 'src/ux/pages/Icons/IconBoxicons';
import IconMaterialdesign from 'src/ux/pages/Icons/IconMaterialdesign';
import IconDripicons from 'src/ux/pages/Icons/IconDripicons';
import IconFontawesome from 'src/ux/pages/Icons/IconFontawesome';

//AuthenticationInner pages
import PageLogin from 'src/ux/pages/AuthenticationInner/PageLogin';
import PageRegister from 'src/ux/pages/AuthenticationInner/PageRegister';
import RecoverPassword from 'src/ux/pages/AuthenticationInner/RecoverPassword';
import ConfirmMail from 'src/ux/pages/AuthenticationInner/ConfirmMail';
import EmailVerification from 'src/ux/pages/AuthenticationInner/EmailVerification';
import TwoStepVerfication from 'src/ux/pages/AuthenticationInner/TwoStepVerfication';

//Authentication pages
import UserProfile from 'src/ux/pages/Authentication/UserProfile';

//Other pages
import PagesComingsoon from 'src/ux/pages/Utility/PageComingsoon';

//Maps
import MapsGoogle from 'src/ux/pages/Maps/MapsGoogle';
import MapsVector from 'src/ux/pages/Maps/MapsVector';
import MapsLeaflet from 'src/ux/pages/Maps/MapsLeaflet';

import UXLayout from 'src/ux/components/UXLayout';
import NonAuthLayout from 'src/components/NonAuthLayout';

interface RouteProps {
  path: string;
  component: any;
  notExact?: boolean;
  layout: React.FC<any>;
  ability: [action: any, subject: any, field?: string | undefined];
}

export const getUXRoutes = () => {
  const routes: RouteProps[] = [
    { path: '/ux', component: ListAllUxPages, layout: UXLayout, ability: ['visit', 'GenericPages'] },

    //AuthenticationInner pages
    { path: '/ux/page-login', component: PageLogin, layout: NonAuthLayout, ability: ['visit', 'GenericPages'] },
    {
      path: '/ux/page-register',
      component: PageRegister,
      layout: NonAuthLayout,
      ability: ['visit', 'GenericPages'],
    },
    {
      path: '/ux/page-recoverpw',
      component: RecoverPassword,
      layout: NonAuthLayout,
      ability: ['visit', 'GenericPages'],
    },
    {
      path: '/ux/page-confirm-mail',
      component: ConfirmMail,
      layout: NonAuthLayout,
      ability: ['visit', 'GenericPages'],
    },
    {
      path: '/ux/page-email-verification',
      component: EmailVerification,
      layout: NonAuthLayout,
      ability: ['visit', 'GenericPages'],
    },
    {
      path: '/ux/page-two-step-verification',
      component: TwoStepVerfication,
      layout: NonAuthLayout,
      ability: ['visit', 'GenericPages'],
    },

    //utility page
    {
      path: '/ux/pages-comingsoon',
      component: PagesComingsoon,
      layout: NonAuthLayout,
      ability: ['visit', 'GenericPages'],
    },
    {
      path: '/ux/pages-maintenance',
      component: PageMaintenance,
      layout: NonAuthLayout,
      ability: ['visit', 'GenericPages'],
    },
    { path: '/pages-404', component: Error404, layout: NonAuthLayout, ability: ['visit', 'GenericPages'] },
    { path: '/pages-500', component: Error500, layout: NonAuthLayout, ability: ['visit', 'GenericPages'] },

    //-----------------------------
    // User Pages
    //-----------------------------
    //User Profile
    { path: '/ux/profile', component: UserProfile, layout: UXLayout, ability: ['visit', 'RegisteredUserPages'] },

    //-----------------------------
    // Admin Pages
    //-----------------------------

    //Storages
    { path: '/ux/storages', component: Storages, layout: UXLayout, ability: ['visit', 'AdminPages'] },
    {
      path: '/ux/storages/storage',
      component: StorageDetails,
      layout: UXLayout,
      ability: ['visit', 'AdminPages'],
    },
    //Packages
    {
      path: '/ux/storages/package',
      component: PackageDetails,
      layout: UXLayout,
      ability: ['visit', 'AdminPages'],
    },

    {
      path: '/ux/packages/:packageId',
      component: PackageDetails,
      layout: UXLayout,
      ability: ['visit', 'Packages', 'PackageDetails'],
    },

    //Reports
    { path: '/ux/reports', component: Reports, layout: UXLayout, ability: ['visit', 'AdminPages'] },
    {
      path: '/ux/reports/transporter',
      component: TransporterReport,
      layout: UXLayout,
      ability: ['visit', 'AdminPages'],
    },

    //Dashboard
    { path: '/ux/dashboard', component: Dashboard, layout: UXLayout, ability: ['visit', 'AdminPages'] },

    //Calendar
    { path: '/ux/apps-calendar', component: Calendar, layout: UXLayout, ability: ['visit', 'AdminPages'] },

    //Chat
    { path: '/ux/apps-chat', component: Chat, layout: UXLayout, ability: ['visit', 'AdminPages'] },

    //Email Inbox
    { path: '/ux/email-inbox', component: Inbox, layout: UXLayout, ability: ['visit', 'AdminPages'] },
    { path: '/ux/email-read', component: EmailRead, layout: UXLayout, ability: ['visit', 'AdminPages'] },

    //Invoice
    { path: '/ux/invoices-list', component: InvoiceList, layout: UXLayout, ability: ['visit', 'AdminPages'] },
    { path: '/ux/invoices-detail', component: InvoiceDetails, layout: UXLayout, ability: ['visit', 'AdminPages'] },

    //Contact
    { path: '/ux/contacts-grid', component: ContactsGrid, layout: UXLayout, ability: ['visit', 'AdminPages'] },
    { path: '/ux/contacts-list', component: ContactsList, layout: UXLayout, ability: ['visit', 'AdminPages'] },
    { path: '/ux/contacts-profile', component: ContactsProfile, layout: UXLayout, ability: ['visit', 'AdminPages'] },

    //Utility
    { path: '/ux/pages-starter', component: PageStarter, layout: UXLayout, ability: ['visit', 'AdminPages'] },
    { path: '/ux/pages-timeline', component: PageTimeline, layout: UXLayout, ability: ['visit', 'AdminPages'] },
    { path: '/ux/pages-faqs', component: PageFaqs, layout: UXLayout, ability: ['visit', 'AdminPages'] },
    { path: '/ux/pages-pricing', component: PagePricing, layout: UXLayout, ability: ['visit', 'AdminPages'] },

    //UI Components
    { path: '/ux/ui-alerts', component: UiAlert, layout: UXLayout, ability: ['visit', 'AdminPages'] },
    { path: '/ux/ui-buttons', component: UiButton, layout: UXLayout, ability: ['visit', 'AdminPages'] },
    { path: '/ux/ui-cards', component: UiCard, layout: UXLayout, ability: ['visit', 'AdminPages'] },
    { path: '/ux/ui-carousel', component: UiCarousel, layout: UXLayout, ability: ['visit', 'AdminPages'] },
    { path: '/ux/ui-dropdowns', component: UiDropdowns, layout: UXLayout, ability: ['visit', 'AdminPages'] },
    { path: '/ux/ui-grid', component: UiGrid, layout: UXLayout, ability: ['visit', 'AdminPages'] },
    { path: '/ux/ui-modals', component: UiModal, layout: UXLayout, ability: ['visit', 'AdminPages'] },
    { path: '/ux/ui-images', component: UiImages, layout: UXLayout, ability: ['visit', 'AdminPages'] },
    { path: '/ux/ui-drawer', component: UiDrawer, layout: UXLayout, ability: ['visit', 'AdminPages'] },
    { path: '/ux/ui-progressbars', component: UiProgressbar, layout: UXLayout, ability: ['visit', 'AdminPages'] },
    { path: '/ux/ui-tabs-accordions', component: UiTabsAccordions, layout: UXLayout, ability: ['visit', 'AdminPages'] },
    { path: '/ux/ui-typography', component: UiTypography, layout: UXLayout, ability: ['visit', 'AdminPages'] },
    { path: '/ux/ui-video', component: UiVideo, layout: UXLayout, ability: ['visit', 'AdminPages'] },
    { path: '/ux/ui-general', component: UiGeneral, layout: UXLayout, ability: ['visit', 'AdminPages'] },
    { path: '/ux/ui-colors', component: UiColors, layout: UXLayout, ability: ['visit', 'AdminPages'] },

    //Extended pages
    { path: '/ux/extended-lightbox', component: UiLightbox, layout: UXLayout, ability: ['visit', 'AdminPages'] },
    { path: '/ux/extended-rangeslider', component: RangeSlider, layout: UXLayout, ability: ['visit', 'AdminPages'] },
    { path: '/ux/extended-sweet-alert', component: UiSweetAlert, layout: UXLayout, ability: ['visit', 'AdminPages'] },
    {
      path: '/ux/extended-session-timeout',
      component: SessionTimeout,
      layout: UXLayout,
      ability: ['visit', 'AdminPages'],
    },
    { path: '/ux/extended-rating', component: UiRating, layout: UXLayout, ability: ['visit', 'AdminPages'] },
    {
      path: '/ux/extended-notifications',
      component: Notifications,
      layout: UXLayout,
      ability: ['visit', 'AdminPages'],
    },

    // Forms pages
    { path: '/ux/form-elements', component: FormElements, layout: UXLayout, ability: ['visit', 'AdminPages'] },
    { path: '/ux/form-validation', component: FormValidation, layout: UXLayout, ability: ['visit', 'AdminPages'] },
    { path: '/ux/form-advanced', component: AdvancedPlugins, layout: UXLayout, ability: ['visit', 'AdminPages'] },
    { path: '/ux/form-editors', component: FormEditors, layout: UXLayout, ability: ['visit', 'AdminPages'] },
    { path: '/ux/form-uploads', component: FormUpload, layout: UXLayout, ability: ['visit', 'AdminPages'] },
    { path: '/ux/form-wizard', component: FormWizard, layout: UXLayout, ability: ['visit', 'AdminPages'] },
    { path: '/ux/form-mask', component: FormMask, layout: UXLayout, ability: ['visit', 'AdminPages'] },

    //tables
    { path: '/ux/tables-basic', component: BasicTable, layout: UXLayout, ability: ['visit', 'AdminPages'] },
    { path: '/ux/tables-datatable', component: DatatableTables, layout: UXLayout, ability: ['visit', 'AdminPages'] },
    { path: '/ux/tables-responsive', component: ResponsiveTables, layout: UXLayout, ability: ['visit', 'AdminPages'] },
    { path: '/ux/tables-editable', component: EditableTables, layout: UXLayout, ability: ['visit', 'AdminPages'] },

    //Charts
    { path: '/ux/charts-apex', component: Apexchart, layout: UXLayout, ability: ['visit', 'AdminPages'] },
    { path: '/ux/charts-echart', component: EChart, layout: UXLayout, ability: ['visit', 'AdminPages'] },
    { path: '/ux/charts-chartjs', component: ChartjsChart, layout: UXLayout, ability: ['visit', 'AdminPages'] },
    { path: '/ux/charts-sparkline', component: SparklineChart, layout: UXLayout, ability: ['visit', 'AdminPages'] },

    //Icons
    { path: '/ux/icons-boxicons', component: IconBoxicons, layout: UXLayout, ability: ['visit', 'AdminPages'] },
    {
      path: '/ux/icons-materialdesign',
      component: IconMaterialdesign,
      layout: UXLayout,
      ability: ['visit', 'AdminPages'],
    },
    { path: '/ux/icons-dripicons', component: IconDripicons, layout: UXLayout, ability: ['visit', 'AdminPages'] },
    { path: '/ux/icons-fontawesome', component: IconFontawesome, layout: UXLayout, ability: ['visit', 'AdminPages'] },

    //Maps
    { path: '/ux/maps-google', component: MapsGoogle, layout: UXLayout, ability: ['visit', 'AdminPages'] },
    { path: '/ux/maps-vector', component: MapsVector, layout: UXLayout, ability: ['visit', 'AdminPages'] },
    { path: '/ux/maps-leaflet', component: MapsLeaflet, layout: UXLayout, ability: ['visit', 'AdminPages'] },
  ];

  return routes;
};
