import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ApolloClient, createHttpLink, InMemoryCache, ApolloProvider } from '@apollo/client';

import config from 'src/config';
import App from './App';
import { configureStore } from 'src/store/index';
import { setContext } from '@apollo/client/link/context';
import { tabStorage } from 'src/helpers/storage/settings';
import { STORAGE_KEY_CURRENTUSER_CURRENTCONTEXT_ID } from 'src/components/AuthContextSetter';
import './i18n';
import './index.css';

const httpLink = createHttpLink({
  uri: config.backendGraphQlUrl,
  credentials: 'include',
});

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      'x-app-user-context-id': tabStorage.getItem(STORAGE_KEY_CURRENTUSER_CURRENTCONTEXT_ID),
    },
  };
});

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(httpLink),
});

render(
  <ApolloProvider client={client}>
    <Provider store={configureStore({})}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </ApolloProvider>,
  document.getElementById('root'),
);
