import { all, fork } from 'redux-saga/effects';

//Layout
import LayoutSaga from './layout/saga';

//Calendar
import calendarSaga from './calendar/saga';

//Chat
import chatSaga from './chat/saga';

//Invoices
import invoiceSaga from './invoices/saga';

//Contact
import contactsSaga from './contacts/saga';

//Register
import registerSaga from './auth/register/saga';

//User Profile
import ProfileSaga from './auth/profile/saga';

// Forget Password
import forgotPasswordSaga from './auth/forgot-password/saga';

export default function* rootSaga() {
  yield all([
    //public
    fork(LayoutSaga),
    fork(calendarSaga),
    fork(chatSaga),
    fork(invoiceSaga),
    fork(contactsSaga),
    fork(registerSaga),
    fork(ProfileSaga),
    fork(forgotPasswordSaga),
  ]);
}
