import React from 'react';

import { Switch, BrowserRouter as Router } from 'react-router-dom';

// Import all routes
import { geAllRoutes } from './routes/AllRoutes';
import { getUXRoutes } from 'src/ux/routes/UXRoutes';

import Home from 'src/pages/Home';
import AuthLayout from 'src/components/AuthLayout';
import AppErrorCatcher from 'src/pages/Error/AppErrorCatcher';
import AuthContextSetter from './components/AuthContextSetter';
import ConfirmContextSetter from './components/ConfirmContextSetter';
import AuthorizedRoute from './routes/AuthorizedRoute';
import ConfirmModal from 'src/components/Modal/ConfirmModal';

// Import scss
import './assets/scss/theme.scss';
import './assets/scss/preloader.scss';

import fakeBackend from 'src/ux/helpers/AuthType/fakeBackend';

// Activating fake backend
fakeBackend();

const App = () => {
  const allRoutes = geAllRoutes();
  const uxRoutes = getUXRoutes();

  return (
    <React.Fragment>
      <AppErrorCatcher>
        <ConfirmContextSetter>
          <AuthContextSetter>
            <Router>
              <Switch>
                {[...allRoutes, ...uxRoutes].map((route, idx) => (
                  <AuthorizedRoute
                    path={route.path}
                    Component={route.component}
                    authRule={route.ability}
                    Layout={route.layout}
                    key={idx}
                    exact={!route.notExact}
                  />
                ))}

                <AuthorizedRoute
                  path={'/'}
                  Component={Home}
                  authRule={['visit', 'AdminPages']}
                  Layout={AuthLayout}
                  exact={false}
                />
              </Switch>
            </Router>

            <ConfirmModal />
          </AuthContextSetter>
        </ConfirmContextSetter>
      </AppErrorCatcher>
    </React.Fragment>
  );
};

export default App;
